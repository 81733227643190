<div class="container" [ngClass]="{'border-radius' : indexStep<3}">
  <h6>PROGRESO</h6>
  <div class="progreso-linia" >
    <div class="parent" *ngFor="let step of steps; let i=index">
      <div class="dots" [ngClass]="getClass(i+1)">
        <span *ngIf="!getTick(i+1)" class="white-dot"></span>
        <span *ngIf="getTick(i+1)">
          <svg class="icon-tick">
            <use xlink:href="#hs-tick2"></use>
          </svg>
        </span>
      </div>
      <div *ngIf="i<10" class="step" [ngClass]="getStepClassLine(i+1)">
      </div>
    </div>
  </div>
</div>
