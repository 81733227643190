import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResumenFirmasSqp } from '../../models/resumen-firmas-sqp';


@Component({
  selector: 'app-resumen-estados-sqp',
  templateUrl: './resumen-estados-sqp.component.html',
  styleUrls: ['./resumen-estados-sqp.component.css']
})
export class ResumenEstadosSqpComponent implements OnInit {

  @Input() resumenFirmas: ResumenFirmasSqp[] = [];
  @Input() verNuevos: boolean = false;
  @Output() clickEstado: EventEmitter<string> = new EventEmitter<string>();
  @Input() estadoSeleccionado:string;
  @Input() verTooltip: boolean = false;
  @Input() colorSeleccion: string;

  constructor() { }

  ngOnInit(): void {
  }

  clickTipoFirma(tipoEstado: ResumenFirmasSqp){
    this.clickEstado.emit(tipoEstado.estado);
  }

}
