<div>
  <!-- <app-barra-superior class="layout-menu"></app-barra-superior> -->
  <app-barra-superior-sqp class="layout-menu" [logo]="'assets/images/logo-hire-sign-color.png'"></app-barra-superior-sqp>
  <!-- <app-bread-crumbs class="breadcrumb"></app-bread-crumbs> -->
  <!-- <app-menu-lateral class="aside"(mostrarMenu)="plegarMenu($event)"></app-menu-lateral> -->
  <app-menu-lateral-sqp class="aside" [itemsMenu]="itemsMenu"></app-menu-lateral-sqp>
  <div class="main" [ngClass]="menuVisible ? '':'mainMenuPlegado'">
      <router-outlet></router-outlet>
  </div>
</div>
