import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { LoginService } from "./login.service";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationMessageComponent } from "src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component";
import { TipoMensajeConfirmacion } from "src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data";

@Injectable({
    providedIn: 'root'
  })
  export class AuthGuardAdministrador implements CanActivate {

    constructor(private authService: LoginService, private router: Router, private dialog: MatDialog) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.authService.getUserLocalStorage().User.Rol.toUpperCase() != 'ADMINISTRADOR') {
        this.router.navigate(["/panel/main"]);
        const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
          data: {
            tipo: TipoMensajeConfirmacion.Informacion,
            soloMensaje: 'No tiene los permisos adecuados para acceder a la opción seleccionada',
            textoConfirmacion: 'Aceptar'
          },
        });
        return false;
      } else return true;
    }

  }
