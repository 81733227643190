import { Inject, Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { FiltroFirma, Filtros } from '../../../shared/models/filtros';
import { Firmas } from '../models/firmas';
import { ResumenFirmas } from '../models/resumen-firmas';
import * as fileSaver from 'file-saver';
import { ColDef } from 'ag-grid-community';
import { DesplegableData } from '../../../shared/components/sqp-components/sqpDesplegable/models/desplegable-data';
import { of,  throwError } from 'rxjs';
import { GridColDefsData } from '../models/grid-col-defs-data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResumenEventos } from '../models/resumen-eventos';
import { EstadosFirmaService } from './estados-firma.service';
import { IGestionarFirmasService } from './ports/i-gestionar-firmas-service';
import { Documento } from '../models/documento';
import { TipoDocumento } from '../../configuracion/Envios/documentos/models/tipo-documento';
import { RecuperaConfiguracionAplicacionApiService } from '../../configuracion/configuracion-rlt/services/recupera-configuracion-aplicacion-api.service';
import { Router } from '@angular/router';
import { ModificarArraysService } from 'src/app/shared/services/modificar-arrays.service';

@Injectable({
  providedIn: 'root'
})
export class DatosFirmaService {

  firmas: Firmas[] = [];
  firmasFiltradas: Firmas[] = [];
  filtros: Filtros[] = [];
  firmasNuevas: Firmas[] = [];
  filtrosNuevos: FiltroFirma[] = [];
  resumenFirmas: ResumenFirmas[] = [];

  public cargandoDatos:boolean = false;


  //Datos de la columna del grid
  columnDefs: ColDef[] = new GridColDefsData().columDefs;

  private recuperandoExtra:boolean = false;
  private abort:AbortController = new AbortController();

  refreshFirmas: boolean;

  constructor(private loginService: LoginService,
              private estadosFirma:EstadosFirmaService,
              private _snackBar: MatSnackBar,
              @Inject('GestionaFirmas') private datosFirmas: IGestionarFirmasService,
              public recuperaConfiguracionAplicacionService: RecuperaConfiguracionAplicacionApiService,
              private router: Router,
              private modificarArraysService: ModificarArraysService) { }

  // Recuperar todas las firmas registradas en base de datos
  GetFirmas(){
    if(this.recuperandoExtra) this.abort.abort();
    this.cargandoDatos=true;
    return this.datosFirmas.GetFirmas(this.refreshFirmas).pipe(
      map((response: any) =>{
        this.firmas = response;
        this.firmas.forEach(x => {
          if(x.fechaCreacion != null) x.fechaCreacion = new Date(x.fechaCreacion);
          if(x.fechaEnvio != null) x.fechaEnvio = new Date(x.fechaEnvio);
          if(x.fechaFirma != null) x.fechaFirma = new Date(x.fechaFirma);
          x.fechaUltimaActualizacion = new Date(x.fechaUltimaActualizacion);
          if(x.fechaContrato != null) x.fechaContrato = new Date(x.fechaContrato);
          x.esNueva = false;
          x.cargaCompleatada=false;
          //x.eventos.forEach(x => x.fecha = new Date(x.fecha));
        });
        this.firmasNuevas = this.GetNuevasFirmas();
        const firmas = this.firmasNuevas
        this.cargandoDatos=false;
        this.refreshFirmas = false;
         //this.GetDatosExtraFirmas(this.abort.signal);
         return this.firmas //para que el test unitario funcione correctamente
      }),
      catchError(error=>{
        this.cargandoDatos=false;
        //si el problema es que no esta autorizado, el interceptor nos llevará a
        //login, con lo que no es necesario enviar un error al componente
        localStorage.clear();
        this.router.navigate(['/login']);
        if(error.status==401){
          this.firmas = [];
          return of([]);
        } else{
          throw error;
        }
      })
    );
  }

  async GetDatosExtraFirmas(signal:AbortSignal){
    try{
      this.recuperandoExtra=true;
      const max = this.firmas.length;
       for(let i=this.firmas.length-1;i>=0;i--){
        const firma = this.firmas[i];
         if(signal.aborted) {
           this.abort = new AbortController();
           return;
          }
        // await this.GetDatosExtraFirmaCall(i);

        let documentosSubidosString: string = '';
        let documentosPendientesString: string = '';
        if(firma.documentos) {
          firma.documentos.forEach((doc:Documento, index: number) => {
            documentosSubidosString += (index != (firma.documentos.length-1)) ? `${doc.nombreDocumento}, ` : `${doc.nombreDocumento}`;
          });
          if (['Pendiente','Error'].includes(firma.estadoString) && (firma.docNecesarios != firma.documentos?.length) && firma.tiposDocumento) {
            // Con los documentos no fusionables no hay problema, pero con los que lo son solo obtenemos el tipo de documento del primero, del resto (los que se han unido al primero) no.
            let idTiposDocumentoDocumentosSubidos: number[] = firma.documentos?.map((x: Documento) => x.tipoDocumento.idTipoDocumento);
            let tiposDocumentoPendientes: TipoDocumento[] = firma.tiposDocumento.filter((tipoDoc: TipoDocumento) => !tipoDoc.generico && !idTiposDocumentoDocumentosSubidos.includes(tipoDoc.idTipoDocumento));
            let tiposDocumentoPendientesFusionables = tiposDocumentoPendientes.filter(y => y.fusionable).map(y => y.nombre);
            let tiposDocumentoPendientesNoFusionables = tiposDocumentoPendientes.filter(y => !y.fusionable).map(y => y.nombre);
            if(tiposDocumentoPendientesNoFusionables.length > 0) documentosPendientesString += `No fusionables: ${tiposDocumentoPendientesNoFusionables.join(',')}`;
            if(tiposDocumentoPendientesFusionables.length > 0) documentosPendientesString += ` Fusionables: ${tiposDocumentoPendientesFusionables.join(',')}`;

          }
        }
        firma.documentosSubidosString = documentosSubidosString;
        firma.documentosPendientesString = documentosPendientesString;
       }

       this.recuperandoExtra=false;
    } catch(e:any){
        if( e.name!=='AbortError') throw e;
    }
  }

  async GetDatosExtraFirmaCall(index:number){
    if(!this.firmas[index].cargaCompleatada){

      //CARGAMOS LOS DOCUMETNOS
      await this.datosFirmas.GetInfoDocs(index).pipe(
        map((response: any)=>{
        this.firmas[index].documentos = response
      })
      ).toPromise();

      //Cargamos los eventos
      this.firmas[index].eventos = [];
      await this.datosFirmas.GetInfoEventos(index).pipe(
        map((response: any)=>{
          const data:any[] = response
          data.forEach((element:any) => {
            this.firmas[index].eventos.push(new ResumenEventos(element)) ;
          });
        })).toPromise();
      //Cargamos los TIPOS DE DOCUMENTO
      await this.datosFirmas.GetInfoTipoDocs(index).pipe(
        map((response: any)=>{
        this.firmas[index].tiposDocumento = response
        })).toPromise();

      this.firmas[index].cargaCompleatada=true;
    }
  }

  // Obtenemos el listado de firmas agrupadas por estado
  async GetResumen(refrescar: boolean){

    let resumenFirmas: ResumenFirmas[] = [];
    if(this.firmas.length == 0 || refrescar){
        await this.GetFirmas().toPromise().then(
          ok =>{
            resumenFirmas = this.CalculaResumen(refrescar);
          },
          error =>{
            console.error(error);
            throw error;
          });
    }else{
      if(this.resumenFirmas.length==0) resumenFirmas = this.CalculaResumen(refrescar);
      else resumenFirmas = this.resumenFirmas
    }
    return resumenFirmas;
  }

 //Funcion que calcula las firmas por tipo.
  CalculaResumen(refrescar: boolean):ResumenFirmas[]{
    let resumenFirmas: ResumenFirmas[] = [];
    const ESTADOS_FIRMAS_NOVEDADES: string[] = ["FIRMADO","PARCIALMENTE FIRMADO","RECHAZADO","CADUCADO"];
    let _firmas = refrescar ? this.firmas : this.firmasFiltradas;
    //borramos el filtro de estado y volvemos a filtrar para que no se se modifique el panel lateral cuando clicamos en estados
    let indexFiltrosEstado = this.filtrosNuevos.findIndex(x=>x.text=='Estado');
    if(indexFiltrosEstado>-1) {
      this.filtrosNuevos.splice(indexFiltrosEstado,1)
      _firmas = this.filtrar(this.filtrosNuevos)
    }
    for (const estado of this.estadosFirma.ESTADOS_FIRMAS) {
      let firmasEstado = (estado.estado == 'TODOS') ? _firmas : _firmas.filter(x => x.estadoString?.toUpperCase() == estado.estado);
      let fechaUltimaConexion = this.loginService.getUserLocalStorage().FechaUltimaConexion;
      let hayNovedades: boolean = (ESTADOS_FIRMAS_NOVEDADES.find(x => x == estado.estado) && fechaUltimaConexion) ? true : false;
      if(hayNovedades) hayNovedades = firmasEstado.find(x => x.fechaUltimaActualizacion && fechaUltimaConexion && x.fechaUltimaActualizacion > fechaUltimaConexion) ? true : false;
      let resumenFirmasEstado: ResumenFirmas = {
        estado: estado.estado,
        firmas: firmasEstado,
        hayNovedades: hayNovedades,
        icono: estado.icono,
        etiqueta:estado.etiqueta
      };
      resumenFirmas.push(resumenFirmasEstado);
    }
    this.modificarArraysService.swapElements(resumenFirmas, 4, 5);
    this.modificarArraysService.swapElements(resumenFirmas, 7, 8);
    this.resumenFirmas = resumenFirmas;
    return resumenFirmas;
  }

  // Aislamos las firmas nuevas
  GetNuevasFirmas(): Firmas[]{
    let fechaUltimaConexion = new Date(this.loginService.getUserLocalStorage().FechaUltimaConexion ?? '') ?? new Date();
    fechaUltimaConexion.setHours(fechaUltimaConexion.getUTCHours());
    let firmasNuevas = this.firmas.filter(x => new Date(x.fechaUltimaActualizacion) > new Date(fechaUltimaConexion));
    firmasNuevas.forEach(x => x.esNueva = true);
    return firmasNuevas;
  }

  // Obtenemos el icono a mostrar en el grid de firmas dependiendo de su estado

  // Borramos la lista de firmas seleccionadas

  BorrarFirmas(ids: number[]){
    return this.datosFirmas.BorrarFirmas(ids).pipe(
      map((response: any) =>{
        if (response) return response;
        // else throw new Error("Error al eliminar las firmas");
      }),
      catchError(this.handleErrorFirma));
  }

    // Borramos la firma seleccionada

    BorrarFirma(idFirma: number){
      return this.datosFirmas.BorrarFirma(idFirma).pipe(
        map((response: any) =>{
          if (response) return response;
          // else throw new Error("Error al eliminar la firma");
        }),
        catchError(this.handleErrorFirma));
    }

    // Cancelamos la firmas seleccionada

    CancelarFirma(idFirma: number){
      return this.datosFirmas.CancelarFirma(idFirma).pipe(
        map((response: any) =>{
          if (response) return response;
          // else throw new Error(`No se ha podido cancelar la firma por un error interno de Signaturit: ${response.Message}`);
        }),
        catchError(this.handleErrorFirma));
    }

  // Genera la descarga de un envío firmado o parcialmente firmado
  descargandoFirmas: boolean = false;
  DescargaFirmaCompleta(id: any): void {
    this.descargandoFirmas = true;
    this.datosFirmas.DescargaFirmaCompleta(id).pipe(
      map((response: any) => {
        // Recuperamos el fichero en formato base64 string y obtenemos el array de bytes
        const byteCharacters = response
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return byteArray;
      })).subscribe(
        (ok: any) => {
          const blob = new Blob([ok], { type: "application/zip" });
          let fecha = new Date();
          let fileName = `Hire&Sign_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.zip`;
          fileSaver.saveAs(blob, fileName);
          this.descargandoFirmas = false;
        },
        (error: any) =>{
          console.error(error.message);
          if(error.message.endsWith('descargar')) {
            this._snackBar.open(error.message, 'Cerrar', {duration: 3000});
          } else{
            this._snackBar.open('Se ha producido un error '+error.message, 'Cerrar', {duration: 3000});
          }
          this.descargandoFirmas = false;
        }
      );
  }
  //#endregion

 // Se mostrarán los envíos con coste o los envíos al entorno de sandbox. Filtraremos siempre, no se mezclarán
 // Al trabajar con interfaces y con un array de filtros tenemos que sacarlo fuera del bucle
  //     if(this.filtros.find(x => x.isEntornoSandbox)) _firmasFiltradas = _firmasFiltradas.filter(x => x.isEnvioTest);
  //     else  _firmasFiltradas = _firmasFiltradas.filter(x => !x.isEnvioTest);
  //     _firmasFiltradas.forEach(x => { if(!this.firmasFiltradas.includes(x)) this.firmasFiltradas.push(x); });

  //   }
  //   return this.CalculaResumen(false);

  // }

  // deleteFilter(filter:Filtros){
  //   this.filtros = this.filtros.filter(x=>x.text!=filter.text && x.value!=filter.value);
  // }

  // Obtenemos el listado de firmantes de las firmas registradas
  //MR - comentado en 040724
  // GetFirmantesLista(): DesplegableData[]{
  //   let firmantes: DesplegableData[] = [];

  //   this.firmas.forEach(x => {
  //     if(!firmantes.find(y => y.id == x.nifEmpleado)) firmantes.push({ id: x.nifEmpleado, desc: (!x.empleado?.trim() || x.empleado == null) ? x.nifEmpleado : x.empleado });
  //   });
  //   console.log('firmantes: ', firmantes)
  //   return firmantes;
  // }

  GetIconoName(estado:string):string{
    const valor = this.estadosFirma.ESTADOS_FIRMAS.find(x=>x.estado==estado);
    if(valor){
      return valor.icono;
    }else{
      return '';
    }
  }

  // Obtenemos el listado de tipos de envio de las firmas registradas
  GetTipoDosierLista(): DesplegableData[]{
    let index: number = 0;
    let tiposDosier: DesplegableData[] = [];
    // tiposDosier.push({id:'TODOS',desc:'Todos'});
    this.firmas.forEach(x => {
      if(!tiposDosier.find(y => y.id == x.idTipoEnvio)) tiposDosier.push({ id: x.idTipoEnvio, desc: (!x.tipoEnvio?.trim() || x.tipoEnvio == null) ? x.idTipoEnvio : x.tipoEnvio});
    });
    return tiposDosier;
  }

  // Obtenemos el listado de centros de las firmas registradas
  GetCentrosLista(): DesplegableData[]{
    let centros: DesplegableData[] = [];
    // centros.push({id:'TODOS',desc:'Todos'});
    this.firmas.forEach(x => {
      if(x.codigoCentro?.length > 0 && !centros.find(y => y.id == x.codigoCentro)){
      // if(x.codigoCentro?.length > 0 ){
        centros.push({ id: x.codigoCentro, desc: (x.nombreCentro == null) ? x.codigoCentro : `${x.codigoCentro} - ${x.nombreCentro}` });
        // centros.push({ id: x.codigoCentro, desc: (x.nombreCentro == null) ? x.codigoCentro : `${x.codigoCentro} - ${x.nombreCentro}` });
      }
    });
    return centros;
  }

  // Obtenemos el listado de empresas de las firmas registradas
  GetEmpresaLista(): DesplegableData[]{
    let empresas: DesplegableData[] = [];
    // empresas.push({id:'TODOS',desc:'Todos'});
    this.firmas.forEach(x => {
      if(x.codigoEmpresa?.length > 0 && !empresas.find(y => y.id == x.codigoEmpresa))
      {
        empresas.push({ id: x.codigoEmpresa, desc: (x.nombreEmpresa == null) ? x.codigoEmpresa: `${x.codigoEmpresa} - ${x.nombreEmpresa}` });
        // empresas.push({ id: x.codigoEmpresa, desc: (x.nombreEmpresa == null) ? x.codigoEmpresa : (`${x.codigoEmpresa} - ${x.nombreEmpresa}`) });
      }
    });
    return empresas;
  }
  //#endregion

  GetUltimasFirmas():Firmas[]{

    const data = this.firmas.sort(
      (a,b)=>{
        if((a.fechaFirma ?? a.fechaEnvio ?? a.fechaUltimaActualizacion) > (b.fechaFirma ?? b.fechaEnvio ?? b.fechaUltimaActualizacion)){
          return -1;
        }else{
          return 1;
        }
      });
    return data.slice(0,10);
  }

 //Funcion que avisa cuando hayamos terminado de cargar los datos
  async FinCarga():Promise<boolean>{
    return new Promise(
      (resolve)=>{
        if(!this.cargandoDatos) {
          resolve(true);
        }else {
          setTimeout(()=>{
             this.FinCarga().then(resp =>resolve(true));
          },10)
        }
      }
    );
  }

  private handleErrorFirma(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage =`Error en el cliente : ${error.statusText}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      switch(error.status){
        case 401:
          errorMessage = "Código de Clinte W.k. no váido";
          break;
        default:
          errorMessage = `Error en el Serviodor (${error.status}): ${error.statusText}`;
      }
    }
    return throwError( errorMessage);
  }

  filtrosConEstado: FiltroFirma[]=[];

  filtrarNuevo(filtros: FiltroFirma[]){
    // let listaFiltrada = this.firmas;
    // filtros.forEach(filtro => {
    //   listaFiltrada = this.filtraInterno(filtro, listaFiltrada);
    // });
    this.filtrosNuevos = filtros;
    this.filtrosConEstado = [...filtros];
    let listaFiltrada = this.filtrar(filtros);
    this.firmasFiltradas = listaFiltrada;
    return this.CalculaResumen(false);
  }

  filtrar(filtros: FiltroFirma[]): Firmas[]{
    let listaFiltrada = this.firmas;
    filtros.forEach(filtro => {
      listaFiltrada = this.filtraInterno(filtro, listaFiltrada);
    });
    return listaFiltrada
  }

  filtraInterno(filtro: FiltroFirma, arrayFirmas: Firmas[]):Firmas[]{
    let listaFiltrada: Firmas[] = [];
    if(filtro.listaValores?.length==0) return arrayFirmas;
    arrayFirmas.forEach(x=>{
      filtro.listaValores?.forEach(y=>{
        switch(filtro.text){
          case 'Tipo dosier':
            if(x.idTipoEnvio == y) listaFiltrada.push(x);
          break;
          case 'Empresa':
            if(x.codigoEmpresa == y) listaFiltrada.push(x);
          break;
          case 'Centro':
            if(x.codigoCentro == y) listaFiltrada.push(x);
          break;
        }
      })
      // let fechaParaFiltrar = x.fechaFirma ?? x.fechaEnvio;
      if(filtro.text=='Fecha de envío'){
        if(filtro.fechaDesde != null &&
           filtro.fechaHasta != null &&
           filtro.fechaDesde <= x.fechaUltimaActualizacion &&
           x.fechaUltimaActualizacion < filtro.fechaHasta) listaFiltrada.push(x)
        else if(filtro.fechaDesde==null||filtro.fechaHasta==null) listaFiltrada = arrayFirmas;
      }
      if(filtro.text=='Fecha contrato'){
        if(filtro.fechaDesde != null &&
          filtro.fechaHasta != null &&
          filtro.fechaDesde <= x.fechaContrato &&
          x.fechaContrato < filtro.fechaHasta) listaFiltrada.push(x)
          else if(filtro.fechaDesde==null||filtro.fechaHasta==null) listaFiltrada = arrayFirmas;
      }
    })
    if(filtro.text == 'Nuevos' && filtro.nuevos){
      listaFiltrada = arrayFirmas.filter(x => x.esNueva);
    }
    if(filtro.text == 'Nuevos' && !filtro.nuevos){
      // listaFiltrada = arrayFirmas.filter(x => !x.esNueva);
      listaFiltrada = arrayFirmas;
    }
    if(filtro.text == 'Buscar'){
      if(filtro.buscar?.trim() != ""){
          listaFiltrada = arrayFirmas.filter(x =>
            x.empleado?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            x.nombreEmpresa?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            x.nombreCentro?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            x.estadoString?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            x.numeroDocumentos?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            x.tipoEnvio?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            // x.fechaUltimaActualizacion?.toLocaleString("en-GB").includes(filtro.buscar) ||
            x.fechaCreacion?.toLocaleString("en-GB").includes(filtro.buscar) ||
            x.fechaEnvio?.toLocaleString("en-GB").includes(filtro.buscar) ||
            x.fechaFirma?.toLocaleString("en-GB").includes(filtro.buscar) ||
            x.fechaContrato?.toLocaleString("en-GB").includes(filtro.buscar)
          );
        }else{
          listaFiltrada = arrayFirmas;
        }
    }
    if(filtro.text == 'Estado'){
      if(filtro.estado !== 'TODOS' && filtro.estado !==''){
        listaFiltrada = arrayFirmas.filter(x=> x.estadoString.toUpperCase()==filtro.estado.toUpperCase())
      }else{
        listaFiltrada = arrayFirmas;
      }
    }
    if(filtro.text == 'Sandbox'){
     if(filtro.isEntornoSandbox){
      listaFiltrada = arrayFirmas.filter(x=> x.isEnvioTest)
     }else{
      listaFiltrada = arrayFirmas.filter(x=> !x.isEnvioTest)
     }
    }

    // if(this.filtros.find(x => x.isEntornoSandbox)) _firmasFiltradas = _firmasFiltradas.filter(x => x.isEnvioTest);
    // else  _firmasFiltradas = _firmasFiltradas.filter(x => !x.isEnvioTest);
    // _firmasFiltradas.forEach(x => { if(!this.firmasFiltradas.includes(x)) this.firmasFiltradas.push(x); });
    return listaFiltrada
  }
}
