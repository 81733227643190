<div class="barra-flex-space-between">
  <h1>Documentos configurados</h1>
  <div class="acciones">
    <app-documentos-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-documentos-barra-superior>
    <app-boton-accion [id]="'nuevo documento configurado'" [icono]="'icon-plus'" [titulo]="'nuevo documento'" [outline]="false" (click)="configuraNuevoDocumento()"></app-boton-accion>
  </div>
</div>
<div class="info">Sube documentos, envíalos, consulta el estado del proceso, genera informes, cancela la firma de documentos no firmados o descarga los firmados.</div>
<div class="main">
  <div class="barra-flex-space-between">
    <div class="flex-center-items">
      <app-input-buscador (buscador)="buscarDocumentos($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
      <app-documentos-filtros-nuevos (filtrarDocumentos)="filtrarDocumentos($event)"></app-documentos-filtros-nuevos>
    </div>
    <!-- <app-selector [textoOpcion1]="'Todos'" [textoOpcion2]="'Incompletos'" [active]="verIncompletos" [control]="'estado'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector> -->

  </div>
  <app-sqp-grid-component [data]="tipoDocs"
                          (refresh)="refresh()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'single'"
                          (abrirModalDataEmitter)="abrirDetallesDocumento($event)"></app-sqp-grid-component>
</div>
