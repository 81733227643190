import { Inject, Injectable } from '@angular/core';
import { IDatosEmpleadosService } from './i-datos-empleados-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmpleadoCrud } from '../../models/empleado';
import { map } from 'rxjs/operators';
import { FiltroBasico, FiltroEmpleado } from 'src/app/shared/models/filtros';
import { DesplegableData } from 'src/app/shared/components/sqp-components/sqpDesplegable/models/desplegable-data';

@Injectable({
  providedIn: 'root'
})
export class DatosEmpleadosService{

  empleados: EmpleadoCrud[] = [];
  filtros: FiltroBasico[]=[];
  empleadosFiltrados: EmpleadoCrud[]=[];
  refreshEmpleados:boolean;
  consultaEmpleados: boolean = false;

  constructor(@Inject('RecuperaEmpleados') private datosEmpleadosService: IDatosEmpleadosService) { }

  getEmpleados():Observable<EmpleadoCrud[]>{
    return this.datosEmpleadosService.getEmpleados(this.refreshEmpleados)
    .pipe(
      map(resp => {
        this.empleados = resp;
        this.refreshEmpleados = false;
        return this.empleados
        }
      ))
  }

  addEmpleado(objetoEmpleado: EmpleadoCrud):Observable<void>{
    return this.datosEmpleadosService.addEmpleado(objetoEmpleado)
  }

  updateEmpleado(objetoEmpleado: EmpleadoCrud):Observable<void>{
    return this.datosEmpleadosService.updateEmpleado(objetoEmpleado)
  }

  deleteEmpleado(codigo: string):Observable<void>{
    return this.datosEmpleadosService.deleteEmpleado(codigo)
  }

  getConfiguracionAplicacion():Observable<any>{
    return this.datosEmpleadosService.getConfiguracionAplicacion();
  }

  //*******LISTADO EMPRESAS/CENTROS QUE APARECEN EN EMPLEADOS********

  getCentrosLista(): DesplegableData[]{
    let centros: DesplegableData[] = [];
    this.empleados.forEach(x => {
      if(x.codigoCentro?.length > 0 && !centros.find(y => y.id == x.codigoCentro)){
        centros.push({ id: x.codigoCentro, desc: (x.nombreCentro == null) ? x.codigoCentro : `${x.codigoCentro} - ${x.nombreCentro}` });
      }
    });
    return centros;
  }

  getEmpresaLista(): DesplegableData[]{
    let empresas: DesplegableData[] = [];
    this.empleados.forEach(x => {
      if(x.codigoEmpresa?.length > 0 && !empresas.find(y => y.id == x.codigoEmpresa))
      {
        empresas.push({ id: x.codigoEmpresa, desc: (x.nombreEmpresa == null) ? x.codigoEmpresa: `${x.codigoEmpresa} - ${x.nombreEmpresa}` });
      }
    });
    return empresas;
  }

  //******FILTROS*******

  filtrarNuevo(filtros: FiltroEmpleado[]){
    let listaFiltrada = this.filtrar(filtros);
    this.empleadosFiltrados = listaFiltrada;
    return
  }

  filtrar(filtros: FiltroEmpleado[]): EmpleadoCrud[]{
    let listaFiltrada = this.empleados;
    filtros.forEach(filtro => {
      listaFiltrada = this.filtraInterno(filtro, listaFiltrada);
    });
    return listaFiltrada
  }

  filtraInterno(filtro: FiltroEmpleado, arrayEmpleados: EmpleadoCrud[]):EmpleadoCrud[]{
    let listaFiltrada: EmpleadoCrud[] = [];
    if(filtro.listaValores?.length==0) return arrayEmpleados;
    arrayEmpleados.forEach(x=>{
      filtro.listaValores?.forEach(y=>{
        switch(filtro.text){
          case 'Empresa':
            if(x.codigoEmpresa == y) listaFiltrada.push(x);
          break;
          case 'Centro':
            if(x.codigoCentro == y) listaFiltrada.push(x);
          break;
        }
      })
    })
    if(filtro.text == 'Buscar'){
      if(filtro.buscar?.trim() != ""){
          listaFiltrada = arrayEmpleados.filter(x =>
            x.codigo?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            x.nombre?.toString().toUpperCase().includes(filtro.buscar.toLocaleUpperCase()) ||
            x.apellido1?.toString().toUpperCase().includes(filtro.buscar.toLocaleUpperCase()) ||
            x.apellido2?.toString().toUpperCase().includes(filtro.buscar.toLocaleUpperCase()) ||
            x.nif?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            // x.codigoEmpresa?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            // x.nombreEmpresa?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            // x.nombreCentro?.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            // x.codigoCentro?.toString().toUpperCase().includes(filtro.buscar.toUpperCase())
            `${x.codigoCentro} - ${x.nombreCentro}`.toString().toUpperCase().includes(filtro.buscar.toUpperCase()) ||
            `${x.codigoEmpresa} - ${x.nombreEmpresa}`.toString().toUpperCase().includes(filtro.buscar.toUpperCase())
          );
        }else{
          listaFiltrada = arrayEmpleados;
        }
    }

    return listaFiltrada
  }
}
