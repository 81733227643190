import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { DatosEnviosService } from 'src/app/pages/configuracion/Envios/envios/services/datos-envios.service';
import { CentrosModel } from 'src/app/pages/configuracion/maestros/centros/models/centros-model';
import { DatosCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/datos-centros.service';
import { EmpresasModel } from 'src/app/pages/configuracion/maestros/empresas/models/empresas-model';
import { DatosEmpresasService } from 'src/app/pages/configuracion/maestros/empresas/services/datos-empresas.service';
import { RecuperaEmpresasApiService } from 'src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-api.service';
import { Seguridad } from '../../models/usuario-model';

@Component({
  selector: 'app-usuarios-modal-seguridad',
  templateUrl: './usuarios-modal-seguridad.component.html',
  styleUrls: ['./usuarios-modal-seguridad.component.css']
})
export class UsuariosModalSeguridadComponent implements OnInit {

  iconoTitulo: string = '';
  titulo: string = '';
  enviosData: TipoEnvio[] = null; //null para que el ag-grid de envíos muestre overlayLoadingTemplate mientras se está cargando los datos. Si fuera [] salta overlayNoRowsTemplate.
  empresasData: EmpresasModel[] = null;
  centrosData: CentrosModel[] = null;
  numSeleccion: number;
  seguridad: Seguridad[];

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              public dialogRef: MatDialogRef<any>,
              private datosEnviosService: DatosEnviosService,
              private datosCentrosService: DatosCentrosService,
              private datosEmpresasApiService: RecuperaEmpresasApiService) { }

  ngOnInit(): void {
    this.seleccionarTitulo();
  }

  seleccionarTitulo(){
    switch (this.data.tipoSeguridad){
      case 'TipoEnvio':
        this.titulo = 'Selección tipo de dosier';
        this.iconoTitulo = '#icon-send';
        this.getEnviosData();
        break;
      case 'EmpresaEmpleado':
      case 'Empresa':
        this.titulo = 'Selección empresas';
        this.iconoTitulo = '#icon-building-fill';
        this.getEmpresas();
        break;
      case 'CentroEmpleado':
      case 'Centro':
        this.titulo = 'Selección centros de trabajo';
        this.iconoTitulo = '#icon-building-single';
        this.getCentros();
        break;
    }
  }

  getEnviosData(){
    this.datosEnviosService.getTipoEnvios().then(x => {
      this.enviosData = this.datosEnviosService.enviosFiltrados;
    });
  }

  getEmpresas(){
    this.datosEmpresasApiService.getEmpresas().subscribe(
      respuesta => {
      this.empresasData = respuesta;
    })
  }

  getCentros(){
    this.datosCentrosService.getCentros().subscribe(
      respuesta => {
        this.centrosData = respuesta;

      //   let centrosPermitidos: CentrosModel[] = [];
      //   // Obtenemos los arrays de codigos de centro agrupados por codigo de empresa
      //   let _centrosPermitidos = JSON.parse(this.data.filtroSeguridadFirmas);
      //   _centrosPermitidos.forEach(element => {
      //     element.codigosCentro.forEach(codigoCentro => {
      //       let _centro: any = { codigoEmpresa: element.codigoEmpresa, codigoCentro: codigoCentro }
      //       centrosPermitidos.push(_centro);
      //     });
      //   });
      // this.seleccionarCentros(centrosPermitidos);
    })
  }

  seleccionarCentros(event: CentrosModel[]){
    this.seguridad = [];
    let groups: any[] = [];
    // agrupamos los centros por empresa
    event.forEach(element => {
      let group = groups.find(x => x.codigoEmpresa == element.codigoEmpresa);
      if(group) group.codigosCentro.push(element.codigoCentro);
      else groups.push({codigoEmpresa: element.codigoEmpresa, codigosCentro: [element.codigoCentro]});
    });
    // devolvemos un array con una longitud de 1 y en el id pasamos a JSON el array de centros
    if (groups.length > 0) {
      let centroSeguridad: Seguridad = { id: JSON.stringify(groups), desc:'' };
      this.seguridad.push(centroSeguridad);
    }
  }

  seleccionarEnvios(event: TipoEnvio[]){
    this.seguridad = [];
    event.map(x => {
      let envioSeguridad: Seguridad;
      envioSeguridad = {
        id:x.idTipoEnvio.toString(),
        desc:x.nombre
      }
      this.seguridad.push(envioSeguridad)
    })
  }

  seleccionarEmpresas(event: EmpresasModel[]){
    this.seguridad = [];
    event.map(x => {
      let empresaSeguridad: Seguridad;
      empresaSeguridad = {
        id:x.codigoEmpresa,
        desc:x.nombreEmpresa
      }
      this.seguridad.push(empresaSeguridad)
    })
  }

  guardarSeleccion(){
    this.dialogRef.close(this.seguridad);
  }
}
