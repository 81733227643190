<div class="container">
  <div class="flex-titulo">
    <h1>Nueva configuración de dosier</h1>
    <div class="flex-end">
      <app-selector [textoOpcion1]="''" [textoOpcion2]="'Activado'" [active]="activado" [control]="'estado'" (mostrarSeleccion)="toggleActivado($event)"></app-selector>
    </div>
  </div>
  <div class="content">
    <form [formGroup]="formularioEnvioService.formularioEnvio">

      <!-- Nombre -->
      <div class="container-seccion">
        <h2>Nombre del dosier</h2>
      </div>
      <div class="container-white">
        <input id="nombre" type="text" formControlName="nombre" [ngClass]="formularioValidacionesService.isCampoValido('nombre') ? 'errorInput':'' " >
        <br>
        <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('nombre')">
          {{formularioValidacionesService.mensajeErrorNombre}}
          </span>
      </div>
      <!-- Fin nombre -->

      <!-- Destinatarios -->
      <div class="container-seccion">
        <h2>Destinatarios</h2>
      </div>
      <div id="destinatarios" class="container-white">
        <app-firmantes [totalFirmantes]="firmantes" [hayCopiaBasica]="hayCopiaBasica"></app-firmantes>
      </div>
      <!-- Fin destinatarios -->

      <!-- Modo de envio -->
      <div class="container-seccion">
        <h2>Modo de envío</h2>
        <p class="subtitulo">Activa o desactiva distintos modos de envío.</p>
      </div>
      <div id="modo-envio" class="container-white">
        <div class="input-modo-envio">
          <div class="input-radio">
            <input type="radio" name="envioMail" formControlName="envioMail" [value]="true">
          </div>
          <label for="envioMail">Correo electrónico</label>
        </div>
        <div class="orden-envio">
          <label for="email" [ngClass]=" {'desactivado': !envioMail.value}">E-mail usado:</label>
          <select id="orden-mail-id" class="select-modo-envio" [ngClass]=" {'desactivado': !envioMail.value}" name="ordenMail" formControlName="ordenMail" (change)="changeValue($event, ordenMail )" [ngClass]="formularioValidacionesService.isCampoValido('ordenMail') ? 'errorInput':''">
            <option *ngFor="let mail of tiposMails"
                    [value]="mail.codigo"
                    >{{mail.desc}}</option>
          </select>
          <br>
          <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('ordenMail')">
            {{formularioValidacionesService.mensajeErrorMail}}
          </span>
        </div>
        <div>
          <div class="input-modo-envio">
            <div class="input-radio" [ngClass]="moduloSmsActivo ? '':'desactivado'" style="margin-bottom:3px;">
              <input type="radio" name="envioMail" formControlName="envioMail" [value]="false">
            </div>
            <label for="envioMail" [ngClass]="moduloSmsActivo ? '':'desactivado'">SMS</label>
            <!-- <span *ngIf="!moduloSmsActivo" class="infoSMS" matTooltip="{{smsToolTip}}" [matTooltipPosition]="'after'"> -->
            <span *ngIf="!moduloSmsActivo" class="infoSMS tooltip-wrapper" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()">
              <svg class="icon-help">
                <use xlink:href="#hs-help"></use>
              </svg>
              <app-custom-tooltip *ngIf="tooltipVisible"></app-custom-tooltip>
            </span>

          </div>
          <div class="orden-envio" [ngClass]="{'desactivado': envioMail.value || !moduloSmsActivo }">
            <label for="email">Teléfono usado:</label>
            <select class="select-modo-envio" formControlName="ordenTelefonoSMS" (change)="changeValue($event, ordenTelefonoSMS )" [ngClass]="formularioValidacionesService.isCampoValido('ordenTelefonoSMS') ? 'errorInput':''">
              <option *ngFor="let mail of tiposMails"
                      [value]="mail.codigo"
                      >{{mail.desc}}</option>
            </select>
            <br>
            <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('ordenTelefonoSMS')">
              {{formularioValidacionesService.mensajeErrorOrdenTelefonoSMS}}
            </span>
          </div>

          <app-selector [ngClass]=" {'desactivado': !envioMail.value}" id="selector-envioSecurizado" [textoOpcion1]="''" [textoOpcion2]="'Enviar código de verificación por SMS para acceder a la firma (OTP)'" [active]="securizadoSMS.value" [control]="'estado'" (mostrarSeleccion)="enableEnvioSecurizadoSMS($event)"></app-selector>
          <div class="orden-envio" [ngClass]=" {'desactivado': !securizadoSMS.value}">
            <label for="email">Teléfono usado:</label>
            <select class="select-modo-envio" formControlName="ordenTelefonoOTP" (change)="changeValue($event, ordenTelefonoOTP )" [ngClass]="formularioValidacionesService.isCampoValido('ordenTelefonoOTP') ? 'errorInput':''">
              <option *ngFor="let mail of tiposMails"
                      [value]="mail.codigo"
                      >{{mail.desc}}</option>
            </select>
            <br>
            <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('ordenTelefonoOTP')">
              {{formularioValidacionesService.mensajeErrorOrdenTelefonoOTP}}
            </span>
          </div>
        </div>

      </div>
      <!-- Fin modo de envio -->

      <!-- Subject -->
      <div class="container-seccion">
        <h2>Mensaje</h2>
        <p class="subtitulo">Texto que verán los destinatarios al recibir la comunicación.</p>
      </div>
      <div class="container-white">
        <div *ngIf="envioMail.value">
          <label for="subject">Asunto del correo electrónico</label>
          <div style="margin-bottom: 20px">
            <input id="subject" type="text" formControlName="subject">
          </div>
        </div>
        <!-- Fin subject -->

        <!-- Body del email -->
        <div *ngIf="envioMail.value">
          <label for="mensaje">Cuerpo del mensaje del correo electrónico</label>
          <div class="flex-input">
            <textarea id="body" formControlName="body"></textarea>
          </div>
        </div>
        <div *ngIf="!envioMail.value">
          <label for="mensaje">Cuerpo del mensaje de SMS (110 carácteres máximo)</label>
          <p class="subtitulo">El enlace para acceder a los documentos se añadirá después del texto</p>
          <div class="flex-input textarea-container">
            <textarea id="body" formControlName="body" [ngClass]="{'errorInput':formularioValidacionesService.isCampoValido('body')}"></textarea>
            <span class="char-counter">{{ characterCount }}/110</span>
          </div>
        </div>
        <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('body')">
          {{formularioValidacionesService.mensajeErrorBody}}
        </span>
      </div>
      <!-- Fin body del e-mail -->

      <!-- Documentos adjuntos -->
      <div class="container-seccion">
        <h2>Documentos adjuntos</h2>
        <p *ngIf="!envio?.esRLT" class="subtitulo">Puedes crear un nuevo documento o seleccionar uno ya existente en otro envío. También los puedes fusionar en uno solo.</p>
      </div>
      <div id="docs-adjuntos">
        <div *ngIf="documentosEnvio.length>0" id="documentos">
            <app-grupo-docs [totalDocumentos]="documentosEnvio" (docEliminadoEmitter)="refresh()"></app-grupo-docs>
        </div>
        <!-- <p style="margin-top: 10px">Puedes crear un nuevo documento o seleccionar uno ya existente en otro dosier.</p> -->
        <div *ngIf="!envio?.esRLT" class="anadir-docs">
          <label for="">Añadir documentos</label>
          <div class="flex-docs-adjuntos">
            <app-boton-accion id="addExistenteId" mat-button (botonClicked)="anadirDocExistente()" [titulo]="'Documentos existentes'" [outline]="true"[outlineYellow]="true" [cancelar]="false"></app-boton-accion>
            <app-boton-accion id="addNuevoId" mat-button (botonClicked)="anadirNuevoDoc()" [titulo]="'Nuevo documento'" [outline]="true"[outlineYellow]="true" [cancelar]="false"></app-boton-accion>
          </div>
        </div>
      </div>
      <!-- Fin documentos adjuntos -->

      <!-- Ajustes -->
      <div class="container-seccion">
        <h2>Ajustes adicionales</h2>
      </div>
      <div id="ajustes" class="container-white">

        <!-- Recordatorios programados -->
        <h3>Recordatorios programados</h3>
        <div class="checkboxAjustes">
          <input type="checkbox" formControlName="recordatorio" (click)="formularioValidacionesService.getCheckboxClick('recordatorio'); clickAvisoFirma()">
          <label for="recordatorio">Activar recordatorios</label>
        </div>
        <div *ngIf="recordatorio.value">
          <div class="diasEnvio">Días tras el envío</div>
          <div class="input-ajustes">
            <app-envios-recordatorios *ngFor="let recordatorio of formularioEnvioService.recordatorios; index as i" [indexAviso]="i" (eliminar)="eliminarAviso($event)" (update)="updateAviso($event)" [numAviso]="recordatorio"></app-envios-recordatorios>
            <div>
              <p class="link-agregar" (click)="agregarAviso()">Añadir otro aviso</p>
            </div>


          </div>
          <span class="texto-validacion val-avisos" *ngIf="formularioValidacionesService.validacionAvisos()">
            {{formularioValidacionesService.mensajeErrorAvisosFirma}}
          </span>
        </div>

        <!-- Fin recordatorios programados -->
        <!-- Envío universal -->
        <h3 style="margin-top: 20px">Envío universal</h3>
        <div class="checkboxAjustes">
          <input type="checkbox" formControlName="envioMasivo">
          <!-- <span>
            <svg class="icon-attach blue icon-ajustes">
              <use xlink:href="#icon-universal"></use>
            </svg>
          </span> -->
          <label for="envioMasivo">Activar envío universal</label>
        </div>
        <!-- Fin envío universal -->
      </div>
      <!-- Configuración avanzada - envío programado -->
      <!-- <div id="conf-avanzada">
        <div class="tab">
          <input type="checkbox" id="check-conf">
          <label class="tab-label" for="check-conf">CONFIGURACIÓN AVANZADA</label>
          <div class="tab-content">
            <div class="checkboxAjustes">
              <input type="checkbox" formControlName="programado" (click)="formularioValidacionesService.getCheckboxClick('programado')">
              <span>
                <svg class="icon-attach blue icon-ajustes">
                  <use xlink:href="#icon-pending"></use>
                </svg>
              </span>
              <label for="programado">Envío programado</label>
            </div>
            <div class="input-ajustes">
              <input type="number" formControlName="documentosEnvioProgramado" [ngClass]="[formularioValidacionesService.isCampoValido('documentosEnvioProgramado') ? 'errorInput':'', formularioEnvioService.disableProgramado ? 'bg-transparent':'']" [readonly]="formularioEnvioService.disableProgramado">
              <label for="documentosEnvioProgramado">Cantidad de envíos de este tipo a acumular para realizar el envío.</label>
              <br>
              <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('documentosEnvioProgramado')">
                {{formularioValidacionesService.mensajeErrorProgramado}}
              </span>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Fin configuración avanzada -->
      <!-- <span #hiddenText style="visibility: hidden;white-space: pre;">{{elasticInput.value}}</span> -->
      <!-- Fin ajustes -->
    </form>
  </div>
</div>

<app-barra-footer [id]="'nuevo envio configurado'" [icono]="'hs-send'" [titulo]="'Procesar y enviar'" [id2]="'guardar borrador'" [titulo2]="'Guardar borrador'" [icono2]="'hs-guardar'" [dobleAccion]="true" (botonClicked)="comprobarNumFirmantes()" (botonClicked2)="guardaBorrador()" class="sticky"></app-barra-footer>
