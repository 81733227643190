<form class="" [formGroup]="formUsuario" id="formUsuarioId">
  <div class="modal-container">
    <svg class="icon-close" mat-dialog-close>
      <use xlink:href="#icon-close"></use>
    </svg>
    <h1>{{tituloModal}}</h1>
    <div *ngIf="!nuevo && info.datosUsuario.usuarioActivo" class="estado-activo">{{estado}}</div>
    <div *ngIf="!nuevo && !info.datosUsuario.usuarioActivo && !info.datosUsuario.invitacionEnviada && !info.datosUsuario.invitacionAceptada" class="estado-inactivo">Desactivado</div>
    <div *ngIf="!nuevo && !info.datosUsuario.usuarioActivo && info.datosUsuario.invitacionEnviada && !info.datosUsuario.invitacionAceptada" class="estado-pendiente">Pendiente activación</div>
    <!-- <div [ngClass]="info.datosUsuario.usuarioActivo ? 'estado-activo':'estado-inactivo'">{{estado}}</div> -->

    <div class="flex-container">
      <div [ngClass]="nuevo ? 'modal-content-nuevo':'modal-content'">
        <span class="input-text-custom">
          <label for="nombreUsuario">Nombre</label>
          <div *ngIf="!isEditar && !nuevo" class="info" id="nombreUsuario">{{nombreUsuario.value}}</div>
          <input *ngIf="isEditar || nuevo"
                  type="text"
                  id="nombreUsuario"
                  name="nombreUsuario"
                  formControlName="nombreUsuario"
                  [ngClass]="{'errorInput': isCampoValido('nombreUsuario')}"
              />
          <span class="texto-validacion"
                  *ngIf="isCampoValido('nombreUsuario')">
                  El nombre del usuario es requerido.
          </span>
        </span>
        <span class="input-text-custom">
          <label for="apellidosUsuario">Apellidos</label>
          <div *ngIf="!isEditar && !nuevo" class="info" id="apellidoUsuario">{{apellidosUsuario.value}}</div>
          <input *ngIf="isEditar || nuevo"
                  type="text"
                  id="apellidosUsuario"
                  name="apellidosUsuario"
                  formControlName="apellidosUsuario"
                  [ngClass]="{'errorInput': isCampoValido('apellidosUsuario')}"
              />
          <span class="texto-validacion"
                  *ngIf="isCampoValido('apellidosUsuario')">
                  Los apellidos del usuario son requeridos.
          </span>
        </span>
        <span *ngIf="nuevo" class="input-text-custom">
          <label for="emailUsuario">Email</label>
          <input  type="text"
                  id="emailUsuario"
                  name="emailUsuario"
                  formControlName="emailUsuario"
                  [ngClass]="{'errorInput': isCampoValido('emailUsuario')}"/>
          <span class="texto-validacion"
                  *ngIf="isCampoValido('emailUsuario')">
                  El email del usuario es requerido y tiene que tener formato de email.
          </span>
        </span>
        <span *ngIf="!nuevo" class="input-text-custom">
          <label for="emailUsuario">Login</label>
          <div class="info" id="loginUsuario">{{emailUsuario.value}}</div>
          <!-- <input *ngIf="isEditar"
                  type="text"
                  id="emailUsuario"
                  name="emailUsuario"
                  formControlName="emailUsuario"
                  [ngClass]="{'errorInput': isCampoValido('emailUsuario')}"/> -->
          <!-- <span class="texto-validacion"
                  *ngIf="isCampoValido('emailUsuario')">
                  El email del usuario es requerido y tiene que tener formato de email.
          </span> -->
        </span>
        <!-- <span class="input-text-custom" >
          <label for="accesoUsuario">Usuario acceso</label>
          <div class="info">{{accesoUsuario.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="accesoUsuario"
                  name="accesoUsuario"
                  formControlName="accesoUsuario"
                  [ngClass]="{'errorInput': isCampoValido('accesoUsuario')}"/>
          <span class="texto-validacion"
                  *ngIf="isCampoValido('accesoUsuario')">
                  El email de acceso es requerido y tiene que tener formato de email.
          </span>
        </span> -->
        <span class="input-text-custom">
          <label for="rolUsuario">Rol</label>
          <div *ngIf="!isEditar && !nuevo" class="info" id="rolUsuario">{{rolUsuario.value}}</div>
          <select *ngIf="isEditar || nuevo"
                  (change)="cambiarRol($event)"
                  id="rolUsuario"
                  formControlName="rolUsuario"
                  style="margin-bottom: 20px;"
                  [ngClass]="{'errorInput': isCampoValido('rolUsuario')}">
          <option *ngFor="let usuario of usuarioTipos" [value]="usuario">
            {{ usuario }}
          </option>
        </select>
        <span class="texto-validacion"
              *ngIf="isCampoValido('rolUsuario')">
              El rol es requerido.
        </span>
        <!-- <div class="grey-subtitulo">
          <div *ngFor="let permiso of usuarioPermisos">
            <p class="capital">{{permiso.tituloPermisos}}</p>
            <ul>
              <li *ngFor="let perm of permiso.permisos">
                <svg class="icon-check">
                  <use attr.xlink:href="{{iconPermisos}}"></use>
                </svg>
                {{perm}}
              </li>

            </ul>
          </div>

        </div> -->

        </span>
        <div *ngIf="(isEditar || nuevo) && rolUsuario.value == 'Usuario'">
          <h6>Seguridad firmas</h6>
          <span class="input-text-custom">
            <label for="seguridadUsuario">Tipo seguridad</label>
            <select (change)="cambiarSeguridad($event)"
                    id="seguridadUsuario"
                    formControlName="seguridadUsuario"
                    [ngClass]="{'errorInput': isCampoValido('seguridadUsuario')}"
                    >
              <option *ngFor="let usuario of usuarioSeguridad" [value]="usuario.id">
                {{ usuario.desc }}
              </option>
            </select>
            <span class="texto-validacion"
                  *ngIf="isCampoValido('seguridadUsuario')">
                  El tipo de seguridad es requerida si el rol es "Usuario".
          </span>
          </span>
          <p *ngIf="mostrarLink" class="link-seguridad" (click)="abreModalSeguridad(seguridadUsuario.value)">{{seleccionarSeguridad}}</p>
          <span *ngIf="errorMessage.length>0" class="texto-validacion" style="margin-left: -5px;">{{errorMessage}}</span>
        </div>
      </div>
      <div class="modal-buttons">
          <app-boton-accion mat-dialog-close
                        *ngIf="!isEditar && isCerrar"
                        [icono]="'icon-close'"
                        [titulo]="'Cerrar'"
                        [id]="'Cerrar'"
                        [outline]="true"
                        [cancelar]="false">
          </app-boton-accion>
          <app-boton-accion
                        *ngIf="isDesactivar && !isEditar"
                        (botonClicked)="desactivarUsuario()"
                        [icono]="'hs-activar'"
                        [titulo]="'Desactivar'"
                        [id]="'Desactivar'"
                        >
          </app-boton-accion>
          <!-- <app-boton-accion mat-dialog-close
                            *ngIf="isEditar && !isActivar && !isSoloSwitch"
                            [icono]="'icon-close'"
                            [titulo]="'Cancelar'"
                            [id]="'Cancelar'"
                            [outline]="true"
                            [cancelar]="true">
          </app-boton-accion> -->
          <app-boton-accion mat-dialog-close
                            *ngIf="isEditar"
                            [icono]="'icon-close'"
                            [titulo]="'Cancelar'"
                            [id]="'Cancelar'"
                            [outline]="true"
                            [cancelar]="true">
          </app-boton-accion>


          <!-- <app-boton-accion *ngIf="isEditar && !isActivar && !isSoloSwitch"
                            (botonClicked)="guardarUsuario()"
                            [icono]="'hs-download'"
                            [titulo]="'Guardar'"
                            [id]="'Guardar'">
          </app-boton-accion> -->
          <app-boton-accion *ngIf="!nuevo && isEditar"
                            (botonClicked)="guardarUsuario(info.datosUsuario)"
                            [icono]="'hs-guardar'"
                            [titulo]="'Guardar'"
                            [id]="'Guardar'">
          </app-boton-accion>
          <app-boton-accion *ngIf="!nuevo && isActivar"
                            (botonClicked)="activarUsuario()"
                            [icono]="'hs-activar'"
                            [titulo]="'Activar'"
                            [id]="'Activar'">
          </app-boton-accion>
          <app-boton-accion *ngIf="!info.datosUsuario.usuarioActivo && info.datosUsuario.invitacionEnviada && !info.datosUsuario.invitacionAceptada && !isEditar"
                            (botonClicked)="eliminarUsuario()"
                            [icono]="''"
                            [titulo]="'Eliminar Usuario'"
                            [id]="'Eliminar Usuario'"
                            [outline]="true"
                            [cancelar]="true">
          </app-boton-accion>
          <app-boton-accion *ngIf="!nuevo && !info.datosUsuario.usuarioActivo && info.datosUsuario.invitacionEnviada && !info.datosUsuario.invitacionAceptada && !isEditar"
                            (botonClicked)="reInvitarUsuario()"
                            [icono]="''"
                            [titulo]="'Reenviar invitación'"
                            [id]="'Reenviar invitacion'"
                            [outline]="true"
                            [cancelar]="true">
          </app-boton-accion>
          <app-boton-accion *ngIf="!nuevo && !isEditar && !isCerrar"
                            (botonClicked)="editarUsuario()"
                            [icono]="'hs-edit'"
                            [titulo]="'Editar'"
                            [id]="'Editar'">
          </app-boton-accion>
          <app-boton-accion *ngIf="nuevo"
                            mat-dialog-close
                            [titulo]="'Cancelar'"
                            [id]="'Cancelar'"
                            [outline]="true"
                            [cancelar]="true">
          </app-boton-accion>
          <app-boton-accion *ngIf="nuevo"
                            (botonClicked)="invitarUsuario()"
                            [titulo]="'Invitar'"
                            [id]="'Invitar'">
          </app-boton-accion>
      </div>
    </div>
  </div>
</form>
