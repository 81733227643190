<ul>
  <li *ngFor="let tipoEstado of resumenFirmas"
      matTooltip="{{tipoEstado.tooltipMessage}}"
      [matTooltipPosition]="'after'"
      (click)="clickTipoFirma(tipoEstado)"
      [ngStyle]="{ 'background-color': (tipoEstado.estado==estadoSeleccionado && colorSeleccion!=='') ? colorSeleccion : ''}"
      [ngClass]="verNuevos ? 'verNuevos':'sinNuevos'">
    <div class="leyenda-color" [class]="tipoEstado.icono"></div>
    <div>{{tipoEstado.etiqueta}}</div>
    <app-indicador-numero *ngIf="verNuevos && tipoEstado.numerosNuevos!=='0'" [numero]="tipoEstado.numerosNuevos" [color]="'var(--hs-yellow)'"></app-indicador-numero>
    <span class="flex-right">{{tipoEstado.numeroElementos}}</span>
  </li>
</ul>

