import { ColDef } from 'ag-grid-community';
import { EstadosFirmaService } from 'src/app/pages/panelControl/services/estados-firma.service';



export class GridColDefsData {

    public columDefs:ColDef[] = [
            {field: 'estadoString', headerName: 'Estado', sortable: true, resizable: true, width: 130, suppressSizeToFit: true,
            //se modifica la vista y añade el icono del estado
              cellRenderer: params => {
                let estadoFirma = this.GetIcono(params.value);
                return estadoFirma;
              }
            },
            {field: 'empleado', headerName: 'Firmante principal',sortable: true, resizable: true, width: 220,
              cellRenderer: params => {
                if(params.value){
                  let empleado = params.value.toUpperCase();
                  return empleado
                }else return null

              }
             },
            // {field: 'esNueva',
            //   cellRenderer: params => {
            //     let style = "display: block;position: absolute;top: 0px;bottom: 0px;left: 12px;margin: auto;width: 8px;height: 8px;border-radius: 50%; background: #D87940;";
            //     return params.value ? `<span style="${style}" title="Firma nueva">` : '';
            //   },width: 5, headerName: '', sortable: false, resizable: false ,
            // },
            {field: 'fechaCreacion', headerName: 'Fecha creación', sortable: true, resizable: true, width: 190, cellClass:['celda-grid'],
              valueGetter: params => {
                return params.data.fechaCreacion ?? params.data.fechaUltimaActualizacion;
              },
              cellRenderer: params => {
                if(params.value){
                  let date = new Date(params.value);
                  return date.toLocaleDateString("en-GB");
                }else return null

              }
            },
            {field: 'fechaFirma', headerName: 'Fecha envío/firma', sortable: true, resizable: true, width: 190, cellClass:['celda-grid'],
              valueGetter: params => {
                // return params.data.fechaFirma ?? params.data.fechaEnvio;
                return params.data.fechaFirma ?? params.data.fechaEnvio ?? params.data.fechaUltimaActualizacion;
              },
              //pasamos data a formato en-GB
              cellRenderer: params => {
                if(params.value){
                  let date = new Date(params.value);
                  return date.toLocaleDateString("en-GB");
                }else return null

              },

              filterParams: {
                comparator: (filterDate: Date, cellValue: any) => {
                    let cellDate = new Date(cellValue);
                    cellDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
                    if (cellDate < filterDate) return -1;
                    else if (cellDate > filterDate) return 1;
                    else return 0;
                },
              },
            },

            {field: 'tipoEnvio', headerName: 'Tipo de dosier', sortable: true, resizable: true, width:  280 },

            {field: 'nombreCentro', headerName: 'Centro', sortable: true, resizable: true, width: 280,
            cellRenderer: params => {
              let codigoNombreCentro = params.data.nombreCentro == null ? params.data.codigoCentro : `${params.data.codigoCentro} - ${params.data.nombreCentro}`
              return codigoNombreCentro;
            }},
            {field: 'nombreEmpresa', headerName: 'Empresa', sortable: true, resizable: true, width: 280,
            cellRenderer: params => {
              let codigoNombreEmpresa = params.data.nombreEmpresa == null ? params.data.codigoEmpresa : `${params.data.codigoEmpresa} - ${params.data.nombreEmpresa}`
              return codigoNombreEmpresa;
            }},
            {field: 'numeroDocumentos', headerName: 'Docs', sortable: true, resizable: true, width: 70, suppressSizeToFit: true},
            // {field: 'descargar', headerName: 'Descargar', sortable: false, resizable: false, cellRenderer: 'iconoDescargar', width: 100 },
            {field: 'estadoRlt', headerName: 'RLT', sortable: true, resizable: true, width: 140,
              cellRenderer: params => {
                let estadoRlt = this.GetEstadoRlt(params.data);
                return estadoRlt;
              }, //hide: true // comprobamos desde la api si el modulo está habilitado
            },
            {field: 'fechaContrato', headerName: 'Fecha contrato', sortable: true, resizable: true, width: 190, cellClass:['celda-grid'],
            cellRenderer: params => {
              let date: Date;
              if(params.value){
                date = new Date(params.value);
                return date.toLocaleDateString("en-GB");
              } else return null
            }},
          ];
          //cellRender, aisla la imagen que se mete en la celda y se captura el click de la imagen

  GetColor(estadoString: string, soloIcono: boolean = false)

  {
    let icono = new EstadosFirmaService().GetIcono(estadoString);
    let estado='';
    let style = 'width: 8px; height: 50px; margin-right: 15px;';
    if (icono == 'icon-draft') style += 'background-color: var(--hs-grey4); border-top-left-radius: 8px;';
    else if (icono == 'icon-double-check') style += 'background-color: var(--hs-green2);';
    else if (icono == 'icon-check') style += 'background-color: var(--hs-orange2);';
    else if (icono == 'icon-rejected') style += 'background-color: var(--hs-rosa);';
    else if (icono == 'icon-pending') style += 'background-color: var(--hs-orange2);';
    else if (icono == 'icon-clock') style += 'background-color: var(--hs-rosa);';
    else if (icono == 'icon-alert') style += 'background-color:  var(--hs-red); border-bottom-left-radius: 8px;';
    else if (icono == 'icon-close') style += 'background-color:  var(--hs-rosa);';
    // if(soloIcono) return `<svg style="${style}"><use xlink:href="#${icono}"></use></svg>`;
    // else
    return `<span style="${style}"></span>`;
    //<span">${estado}</span>
  }

  GetIcono(estadoString: string, soloIcono: boolean = false, modal: boolean = false)
  {
    let icono = new EstadosFirmaService().GetIcono(estadoString);
    let style:string;
    if(soloIcono==true) style = 'border-radius: 16px; padding: 4px 10px; margin: 10px 0; font-size: 12px; align-self: center;'
    else style = 'border-radius: 16px; padding: 4px 10px; margin: 10px 0; font-size: 12px;'

    if (icono == 'icon-draft') style += 'background-color: var(--hs-grey4); color: var(--hs-grey1); font-weight: 400;';
    else if (icono == 'icon-double-check') style += 'background-color: var(--hs-green2); color:#006128; font-weight: 400;';
    else if (icono == 'icon-check' && modal == false) style += 'background-color: var(--hs-orange2); color: #965B02; font-weight: 400; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 85px; height: 23px; display:block; padding: 0px 10px;';
    else if (icono == 'icon-check' && modal == true) style += 'background-color: var(--hs-orange2); color: #965B02; font-weight: 400;';
    else if (icono == 'icon-rejected') style += 'background-color: var(--hs-rosa); color: #BE2121; font-weight: 400;';
    else if (icono == 'icon-pending') style += 'background-color: var(--hs-orange2); color: #965B02; font-weight: 400;';
    else if (icono == 'icon-clock') style += 'background-color: var(--hs-rosa); color: #BE2121; font-weight: 400;';
    else if (icono == 'icon-alert') style += 'background-color:  var(--hs-red); color: #fff; font-weight: 700;';
    else if (icono == 'icon-close') style += 'background-color:  var(--hs-rosa); color: #BE2121; font-weight: 400;';
    // if(soloIcono) return `<svg style="${style}"><use xlink:href="#${icono}"></use></svg>`;
    // else
    return `<span style="${style}">${estadoString}</span>`;
    // return `<svg style="${style}"><use xlink:href="#${icono}"></use></svg><span">${estadoString}</span>`;
  }
  // GetIcono(estadoString: string, soloIcono: boolean = false)
  // {
  //   let icono = new EstadosFirmaService().GetIcono(estadoString);
  //   let style = 'width: 20px; height: 20px; margin: 10px 5px -5px 0px;';
  //   if (icono == 'icon-draft') style += 'color: #48565F;';
  //   else if (icono == 'icon-double-check') style += 'color: #3FA677;';
  //   else if (icono == 'icon-check') style += '';
  //   else if (icono == 'icon-rejected') style += 'color: #A65959;';
  //   else if (icono == 'icon-pending') style += 'color: #48565F;';
  //   else if (icono == 'icon-clock') style += 'color: #48565F;';
  //   else if (icono == 'icon-alert') style += 'color: #A65959;';
  //   else if (icono == 'icon-close') style += '';
  //   if(soloIcono) return `<svg style="${style}"><use xlink:href="#${icono}"></use></svg>`;
  //   else return `<svg style="${style}"><use xlink:href="#${icono}"></use></svg><span">${estadoString}</span>`;
  // }

  // boolean|null

  GetEstadoRlt(params:any){
    let styleGreen = "width: 10px; height: 10px; border-radius: 50px; background-color: #3FA677; margin-right: 10px; display: inline-block";
    let styleYellow = "width: 10px; height: 10px; border-radius: 50px; background-color: #F7C351; margin-right: 10px; display: inline-block";

    if(params.estadoRlt === true) return `<span style="${styleGreen}"></span><span>Enviado</span>`;
    else if(params.estadoRlt === false) return `<span style="${styleYellow}"></span><span>Pendiente</span>`;
    else return ``;
  }

}
