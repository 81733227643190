<h1>Actualizar empleados</h1>
<div class="container">
  <div *ngIf="!mostrarTablaEmpleados && !cargandoEmpleados" class="top-info">
    <p>Puedes actualizar los datos de empleados arrastrando o seleccionando el documento Excel de empleados.
    </p>
    <!-- <div class="anchor-download" (click)="exportarPlantillaEmpleados()">Descarga la plantilla aquí</div> -->
    <app-boton-accion [titulo]="'Descarga plantilla empleados'" [icono]="'hs-download'" [outline]="true" [cancelar]="false" (botonClicked)="exportarPlantillaEmpleados()"></app-boton-accion>
  </div>
  <div class="content" *ngIf="!mostrarTablaEmpleados && !cargandoEmpleados">
    <app-upload-file [accept]="'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'" [textoFondo]="'Arrastra aquí el documento Excel de empleados o haz click para seleccionarlo'" (fileListEmitter)="uploadFiles($event)"></app-upload-file>
  </div>
  <div class="tabla" *ngIf="mostrarTablaEmpleados">
    <div *ngIf="hayErrores" class="alert">
      <svg class="icon-alert">
        <use xlink:href="#hs-alert"></use>
      </svg>
      <div class="alert-message">
        <span>La carga de datos ha finalizado</span>
        <br>
        <span>{{resumenEmpleados}}</span>
      </div>
    </div>
    <div *ngIf="!hayErrores" class="alert">
      <svg class="icon-ok">
        <use xlink:href="#hs-tick-circle"></use>
      </svg>
      <div class="alert-message">
        <span>La carga de datos ha finalizado correctamente. Para actualizar los empleados en el sistema haga clic en el botón de "Actualizar empleados".</span>
      </div>
    </div>
    <app-selector style="float: right; margin: -30px 0px;"  [textoOpcion1]="'Mostrar solo errores'" [active]="verNuevasParam" [control]="'nuevasFirmas'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector>
    <div class="grid">
      <ag-grid-angular class="ag-theme-alpine"
                        style="height: 100%;"
                        [rowData]="empleadosFiltro"
                        [columnDefs]="empleadoService.columnDefs"
                        (gridReady)="cargaApi($event)"
                        [getRowStyle]="getRowStyle"

                        [overlayLoadingTemplate]="overlayLoadingTemplate"
                        [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>
        <!-- [frameworkComponents]="frameworkComponents" -->
    </div>
    <div class="botonera">
      <app-boton-accion-secundaria [titulo]="'volver a cargar'" [icono]="'hs-arrow-back'" (botonClicked)="atras()"></app-boton-accion-secundaria>
      <div style="display: flex;">
        <app-boton-accion [titulo]="'descargar y editar errores'" [icono]="'hs-download'" [outline]="true" [cancelar]="false" (botonClicked)="exportarEmpleados()"></app-boton-accion>
        <app-boton-accion [titulo]="'actualizar empleados'" [icono]="'hs-upload'" (botonClicked)="actualizarEmpleados()"></app-boton-accion>
      </div>
    </div>
  </div>
  <app-upload-file-processing *ngIf="cargandoEmpleados" [process]="mensajeCarga"></app-upload-file-processing>
</div>
