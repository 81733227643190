import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { DatosEmpleadosService } from '../../../services/mantenimiento/datos-empleados.service';
import { formEmpleadoValidators, validacionCentroEmpleado, validacionCodigo } from '../../../services/mantenimiento/customValidatorsEmpleados';
import { RecuperaEmpresasApiService } from 'src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-api.service';
import { EmpresasModel } from 'src/app/pages/configuracion/maestros/empresas/models/empresas-model';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-empleados-modal',
  templateUrl: './empleados-modal.component.html',
  styleUrls: ['./empleados-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmpleadosModalComponent implements OnInit {

  formEmpleado: FormGroup = new FormGroup({});
  isEditar: boolean = false;
  nuevo: boolean = false;
  titulosArray: string[]=[
    "Detalle de empleado",
    "Edición de empleado",
    "Nuevo empleado"
  ]
  tituloModal: string = "";
  empresasList: string[] = [];
  centrosList: string[] = [];
  detallesIdentificativos: boolean = false;
  detallesDomicilio: boolean = false;
  detallesLaborales: boolean = false;
  detallesPersonales: boolean = false;
  empresas: EmpresasModel[];

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA)
              public info: any,
              private fb: FormBuilder,
              private datosEmpresasApiService: RecuperaEmpresasApiService,
              public datosEmpleadosService: DatosEmpleadosService) {  }

  ngOnInit(): void {
    this.getEmpresas();
    this.nuevo = this.info.nuevo;
    this.iniciarFormularioEmpleado();
    this.cambiarTituloModal();
  }

  cambiarTituloModal(){
    if(this.isEditar == false) this.tituloModal = `${this.nombre} ${this.apellido1}`;
    if(this.isEditar == true && this.nuevo == false) this.tituloModal = this.nombre;
    if(this.nuevo == true) this.tituloModal = this.tituloModal = this.titulosArray[2];
  }

  iniciarFormularioEmpleado(){
    this.formEmpleado = this.fb.group({
      codigo:[this.info.empleado.codigo? this.info.empleado.codigo:''],
      tipoDocumento:[this.info.empleado.tipoDocumento],
      nif:[this.info.empleado.nif ? this.info.empleado.nif:'', Validators.required],
      fechaNacimiento:[this.info.empleado.fechaNacimiento],
      nombre:[this.info.empleado.nombre, Validators.required],
      apellido1:[this.info.empleado.apellido1, Validators.required],
      apellido2:[this.info.empleado.apellido2],
      nacionalidad:[this.info.empleado.nacionalidad],
      lugarNacimiento:[this.info.empleado.lugarNacimiento],
      estadoCivil:[this.info.empleado.estadoCivil],
      sexo:[this.info.empleado.sexo],
      tipoPersona:[this.info.empleado.tipoPersona],
      empresa:[this.info.empleado.codigoEmpresa ? `${this.info.empleado.codigoEmpresa}-${this.info.empleado.nombreEmpresa}`: null, Validators.required],
      centro:[this.info.empleado.codigoCentro ? `${this.info.empleado.codigoCentro}-${this.info.empleado.nombreCentro}`:null, validacionCentroEmpleado(()=>this.formEmpleado, this.empresas)],
      provincia:[this.info.empleado.provincia],
      municipio:[this.info.empleado.municipio],
      codigoPostal:[this.info.empleado.codigoPostal],
      sigla:[this.info.empleado.sigla],
      numero:[this.info.empleado.numero],
      escalera:[this.info.empleado.escalera],
      piso:[this.info.empleado.piso],
      puerta:[this.info.empleado.puerta],
      mailPersonal:[this.info.empleado.mailPersonal, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      extensionTelefonoPersonal:[this.info.empleado.extensionTelefonoPersonal],
      telefonoPersonal:[this.info.empleado.telefonoPersonal],
      mailProfesional:[this.info.empleado.mailProfesional],
      extensionTelefonoProfesional:[this.info.empleado.extensionTelefonoProfesional],
      telefonoProfesional:[this.info.empleado.telefonoProfesional],
      fechaAlta:[this.info.empleado.fechaAlta, Validators.required],
      puestoTrabajo:[this.info.empleado.puestoTrabajo],
      categoria:[this.info.empleado.categoria],
      convenio:[this.info.empleado.convenio]
    },{ validator: [formEmpleadoValidators(this.info?.empleados, this.info?.empleado)]} as AbstractControlOptions)

    if(this.info.nuevo) this.formEmpleado.controls['centro'].disable();
    else this.setCentrosAsociados(this.info.empleado.codigoEmpresa);
  }

  get codigo(){ return this.formEmpleado.controls['codigo']}
  get tipoDocumento(){ return this.formEmpleado.controls['tipoDocumento'].value}
  get nif(){ return this.formEmpleado.controls['nif'].value}
  get fechaNacimiento(){ return this.formEmpleado.controls['fechaNacimiento']}
  get nombre(){ return this.formEmpleado.controls['nombre'].value}
  get apellido1(){ return this.formEmpleado.controls['apellido1'].value}
  get apellido2(){ return this.formEmpleado.controls['apellido2'].value}
  get nacionalidad(){ return this.formEmpleado.controls['nacionalidad'].value}
  get lugarNacimiento(){ return this.formEmpleado.controls['lugarNacimiento'].value}
  get estadoCivil(){ return this.formEmpleado.controls['estadoCivil'].value}
  get sexo(){ return this.formEmpleado.controls['sexo'].value}
  get tipoPersona(){ return this.formEmpleado.controls['tipoPersona'].value}
  get empresa(){ return this.formEmpleado.controls['empresa'].value}
  get centro(){ return this.formEmpleado.controls['centro'].value}
  get provincia(){ return this.formEmpleado.controls['provincia'].value}
  get municipio(){ return this.formEmpleado.controls['municipio'].value}
  get codigoPostal(){ return this.formEmpleado.controls['codigoPostal'].value}
  get sigla(){ return this.formEmpleado.controls['sigla'].value}
  get numero(){ return this.formEmpleado.controls['numero'].value}
  get escalera(){ return this.formEmpleado.controls['escalera'].value}
  get piso(){ return this.formEmpleado.controls['piso'].value}
  get puerta(){ return this.formEmpleado.controls['puerta'].value}
  get mailPersonal(){ return this.formEmpleado.controls['mailPersonal'].value}
  get extensionTelefonoPersonal(){ return this.formEmpleado.controls['extensionTelefonoPersonal'].value}
  get telefonoPersonal(){ return this.formEmpleado.controls['telefonoPersonal'].value}
  get mailProfesional(){ return this.formEmpleado.controls['mailProfesional'].value}
  get extensionTelefonoProfesional(){ return this.formEmpleado.controls['extensionTelefonoProfesional'].value}
  get telefonoProfesional(){ return this.formEmpleado.controls['telefonoProfesional'].value}
  get fechaAlta(){ return this.formEmpleado.controls['fechaAlta']}
  get puestoTrabajo(){ return this.formEmpleado.controls['puestoTrabajo'].value}
  get categoria(){ return this.formEmpleado.controls['categoria'].value}
  get convenio(){ return this.formEmpleado.controls['convenio'].value}

  isCampoValido(campo:string){
    return this.formEmpleado.controls[campo].errors && this.formEmpleado.controls[campo].touched;
  }

  editarEmpleado(){
    this.isEditar = true;
    this.detallesDomicilio = true;
    this.detallesIdentificativos = true;
    this.detallesLaborales = true;
    this.detallesPersonales = true;
    this.cambiarTituloModal();
  }

  guardarCambiosEmpleado(){
    if(this.formEmpleado.invalid){
      this.detallesDomicilio = true;
      this.detallesIdentificativos = true;
      this.detallesLaborales = true;
      this.detallesPersonales = true;
      this.formEmpleado.markAllAsTouched();
      return
    }
    let objetoEmpleado: any = this.formEmpleado.value;
    objetoEmpleado.codigoEmpresa = this.empresa.split('-')[0];
    objetoEmpleado.nombreEmpresa = this.empresa.split('-')[1];
    objetoEmpleado.codigoCentro = this.centro ? this.centro.split('-')[0]: null;
    objetoEmpleado.nombreCentro = this.centro ? this.centro.split('-')[1]: null;

    delete objetoEmpleado.empresa;
    delete objetoEmpleado.centro;
    this.isEditar = !this.isEditar;
    if (this.nuevo) {
      const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
      this.datosEmpleadosService.addEmpleado(objetoEmpleado).subscribe(
        ok => {
          dialogRefSpinner.close();
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: {
              mensaje: 'Empleado guardado correctamente',
            },
          });
          this.dialogRef.close('OK');
          this.datosEmpleadosService.refreshEmpleados = true;
        },
        error => {
          dialogRefSpinner.close();
          console.error("error", error);
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: {
              mensaje: 'Error. El empleado no se ha guardado',
              alert: true
            },
          });
        }
      );
    } else {
      const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
      this.datosEmpleadosService.updateEmpleado(objetoEmpleado).subscribe(
        ok => {
          dialogRefSpinner.close();
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: {
              mensaje: 'Empleado guardado correctamente',
            },
          });
          this.dialogRef.close('OK');
          this.datosEmpleadosService.refreshEmpleados = true;
        },
        error => {
          dialogRefSpinner.close();
          console.error("error", error);
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: {
              mensaje: 'Error. El empleado no se ha guardado',
              alert: true
            },
          });
        }
      );
    }
  }

  getEmpresas(){
    this.datosEmpresasApiService.getEmpresas().subscribe(
      next => {
        this.empresas = next;
        this.empresas.forEach(x=> this.empresasList.push(`${x.codigoEmpresa}-${x.nombreEmpresa.toUpperCase()}`))
      },
      error => {
        console.error();
      }
    )
  }

  mostrarDetalles(tipo: string){
    switch (tipo){
      case 'identificativos':
        this.detallesIdentificativos = !this.detallesIdentificativos;
      break
      case 'domicilio':
        this.detallesDomicilio = !this.detallesDomicilio;
      break
      case 'laborales':
        this.detallesLaborales = !this.detallesLaborales;
      break
      case 'personales':
        this.detallesPersonales = !this.detallesPersonales;
      break
    }
  }

  setFecha(event:any, tipo:string){
    if(tipo=='alta') this.fechaAlta.setValue(event);
    if(tipo=='nacimiento') this.fechaNacimiento.setValue(event);
  }

  seleccionarEmpresa(event:any){
    let codigoEmpresaSeleccionada = event.target.value.split('-')[0];
    this.setCentrosAsociados(codigoEmpresaSeleccionada);
  }

  setCentrosAsociados(codigoEmpresaSeleccionada: string){
    this.centrosList=[];
    let empresaSeleccionada:any = this.empresas.find(x=> x.codigoEmpresa==codigoEmpresaSeleccionada);
    empresaSeleccionada.centros.forEach(x=> this.centrosList.push(`${x.codigoCentro}-${x.nombreCentro.toUpperCase()}`))
    this.formEmpleado.controls['centro'].enable();
  }
}
