import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { SelectorComponent } from 'src/app/shared/components/sqp-components/sqpSelectores/components/selector/selector.component';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { FiltroEnvio } from 'src/app/shared/models/filtros';
import { GridColDefsEnvios } from 'src/app/pages/configuracion/Envios/envios/models/grid-col-defs-envios';
import { EstadosTipoEnvio, TipoEnvio } from '../../models/tipo-envio';
import { DatosEnviosService } from '../../services/datos-envios.service';
import { EnviosBarraSuperiorComponent } from '../envios-barra-superior/envios-barra-superior.component';
import { Firmantes } from '../../models/firmantes';
import { Location } from '@angular/common';
import { TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';

@Component({
  selector: 'app-envios-page',
  templateUrl: './envios-page.component.html',
  styleUrls: ['./envios-page.component.css']
})
export class EnviosPageComponent implements OnInit {

  // envios: TipoEnvio[] = [];
  textoBuscar: string = '';
  filtros: FiltroEnvio[] = [];
  @ViewChild(EnviosBarraSuperiorComponent) barrSuperior!: EnviosBarraSuperiorComponent;
  verIncompletos: boolean = false;
  @ViewChild(SelectorComponent) selector!: SelectorComponent;
  columnDefs: ColDef[] = new GridColDefsEnvios().columnDefs;
  gridOptions:any = {
    getRowStyle: (params:any) => {
      // if(params.data.estado === EstadosTipoEnvio.incompleto) {
      if(params.data.activo === false) {
        return {'font-weight':700}
      }
      return {'font-weight':400}
    }
  }

  constructor(public datosEnviosService: DatosEnviosService,
              private dialog: MatDialog,
              private exportadorPdfService: ExportadorPdfService,
              private exportadorExcelService: ExportadorExcelService,
              private router: Router,
              private location: Location) {

              }

  ngOnInit() {
    this.getEnviosData();
    if(this.router.url.includes('incompletos')){
      this.verIncompletos = true;
      this.filtros.push({ text: 'Estado',
      textoBuscar: '',
      value: 'Incompleto',
      estado: 'Incompleto',
      idTipoEnvio: ''
    })
    this.datosEnviosService.SetFilter(this.filtros);
    this.datosEnviosService.Filter();
    this.location.replaceState('/panel/dosieres-configurados');
    }

    if(this.datosEnviosService.filtros.length>0){
      this.datosEnviosService.filtros.forEach(x=>{
        if(x.text=='Texto') this.textoBuscar=x.textoBuscar
      })
    }
  }

  ngOnDestroy() :void {
    // this.filtros.forEach(x => this.quitarFiltro(x));
  }

  // getEnviosData(){
  //   this.datosEnviosService.getTipoEnvios().subscribe(
  //     next => {
  //       this.envios = next;
  //       this.filtros.push({ text: '',
  //                           textoBuscar: '',
  //                           value: '',
  //                           estado: '',
  //                           idTipoEnvio: 0
  //                         })
  //     },
  //     error => {
  //       this._snackBar.open(error, 'Cerrar',{duration: 3000});
  //     }
  //   )
  // }
  async getEnviosData(){
    await this.datosEnviosService.getTipoEnvios()
        // this.envios = this.datosEnviosService.envios
        this.filtros.push({ text: '',
                            textoBuscar: '',
                            value: '',
                            estado: '',
                            idTipoEnvio: ''
                          })
      }

  refresh(){
    // this.envios = [...this.datosEnviosService.envios]
    this.getEnviosData()
  }

  //#region Acciones barra superior

  clickBarraSuperior(id: string){
    switch (id) {
      case 'Excel':
        this.exportaExcel();
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      default:
        break;
    }
  }

  configuraNuevoEnvio(){
    this.router.navigate(['/panel/configuracion-dosier/0']);
  }

  exportaPdf(){
    // if(this.envios){
    if(this.datosEnviosService.enviosFiltrados){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: 'Genera un documento PDF con la información de los registros mostrados en el listado',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        }
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf();

      dialogRef.afterClosed().subscribe(result => {
        // if(result === 'OK') this.exportadorPdfService.exportaPdf(datos, this.envios)
        let arrayPdf: any[] = [];
        this.datosEnviosService.enviosFiltrados.forEach(x=>{
          let objetoPdf = {} as any;
          let validadoresArray = x.firmantes.filter((x: Firmantes) => x.tipoDestinatario.desc == 'Validador');
          x.validadores = validadoresArray.length;
          if(x.activo == true) x.estado = EstadosTipoEnvio.completo
          else x.estado = EstadosTipoEnvio.incompleto

          objetoPdf.nombre = x.nombre;
          objetoPdf.tiposDocumento = x.tiposDocumento.length;
          objetoPdf.firmantes = x.firmantes.length;
          objetoPdf.validadores = x.validadores;
          objetoPdf.estado = x.estado

          arrayPdf.push(objetoPdf)
        })

        // if(result === 'OK') this.exportadorPdfService.exportaPdf(datos, this.datosEnviosService.enviosFiltrados)
        if(result === 'OK') this.exportadorPdfService.exportaPdf(datos, arrayPdf)
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let columns: any [] = [];
    this.columnDefs.forEach( x => {
      columns.push( {header: x.headerName, dataKey: x.field} )
    })
    let fecha = new Date();
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_dosieres_configurados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  exportaExcel(){
    // if(this.envios){
    if(this.datosEnviosService.enviosFiltrados){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: 'Genera un documento Excel con la información de los registros mostrados en el listado',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        }
      });

      // let rows = this.GetRows(this.envios);
      let rows = this.GetRows(this.datosEnviosService.enviosFiltrados);
      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorExcelService.exportaExcel(datosExcel, rows);
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Dosieres',
      nombreFichero:`Hire&Sign_dosieres_configurados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumns()
    }
    return datosExcel
  }

  GetColumns(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach( x => {
        let element:any = {};
        element.name = x.headerName;
        element.filterButton = true;
        element.width = x.width;
        columns.push(element);
    });
    return columns;
  }

  GetRows(data: TipoEnvio[]): any{
    let rows:any[] = [];
    data.forEach(x=>{
      let validadoresArray = x.firmantes.filter((x: Firmantes) => x.tipoDestinatario.desc == 'Validador');
      x.validadores = validadoresArray.length;
      if(x.activo == true) x.estado = EstadosTipoEnvio.completo
      else x.estado = EstadosTipoEnvio.incompleto
      let row:any[] =
      [
        x.nombre,
        x.tiposDocumento.length,
        x.firmantes.length,
        x.validadores,
        x.estado
      ];
      rows.push(row);
    });
    return rows
  }

  buscarEnvios(textoBuscar: string){
    if(this.datosEnviosService.enviosFiltrados){
      let _filtroBusqueda: FiltroEnvio = this.datosEnviosService.filtros.find(x => x.text == 'Texto');
      if(_filtroBusqueda) {
        _filtroBusqueda.textoBuscar = textoBuscar;
        _filtroBusqueda.value = textoBuscar;
      } else {
        _filtroBusqueda = {
          textoBuscar: textoBuscar,
          text: 'Texto',
          value: textoBuscar,
          idTipoEnvio: "",
          estado:''
        };
        this.datosEnviosService.filtros.push(_filtroBusqueda);
      }
      this.datosEnviosService.SetFilter(this.datosEnviosService.filtros);
      this.datosEnviosService.Filter();

      // this.envios = this.datosEnviosService.enviosFiltrados;
    }
  }

  // quitarFiltro(filtro: FiltroEnvio){
  //   this.filtros = this.filtros.filter(x => x != filtro);
  //   this.datosEnviosService.SetFilter(this.filtros);
  //   this.datosEnviosService.Filter();
  //   // this.envios = this.datosEnviosService.enviosFiltrados;
  //   this.verIncompletos = false;
  //   // if(filtro.text == 'Texto') {
  //   //   this.barrSuperior.buscarTexto('');
  //   // }
  //   // if(filtro.estado == EstadosTipoEnvio.incompleto){
  //   //   this.selector.click();
  //   // }
  // }

  // filtrarEnvios(){
  //   // if(this.envios){
  //   if(this.datosEnviosService.enviosFiltrados){
  //     const dialogRef = this.dialog.open(EnviosFiltroSelectorComponent,{
  //       panelClass: 'modal-class-center'} );
  //     dialogRef.afterClosed().subscribe(result => {
  //         if(result == 'Filtrar'){
  //           // this.envios = this.datosEnviosService.enviosFiltrados;
  //           this.filtros = this.datosEnviosService.filtros;
  //         }
  //       });
  //     }
  // }

  // seleccionFiltroChange(verEstado: boolean){
  //   this.verIncompletos = !this.verIncompletos
  //   this.filtros[0]= {
  //     text: verEstado ? 'Mostrando dosieres' : '',
  //     textoBuscar: '',
  //     value: verEstado ? 'Incompleto' : '',
  //     estado: verEstado ? 'Incompleto' : 'TODOS',
  //     idTipoEnvio: ''
  //   };
  //   this.datosEnviosService.SetFilter(this.filtros);
  //   this.datosEnviosService.Filter();
  //   // this.envios = this.datosEnviosService.enviosFiltrados;
  // }

  getIncompleto(event:boolean){
    if(event==true) this.verIncompletos = true;
  }

  abrirDetalleDosier(event: any){
    let envio: TipoEnvio = event.data;
    this.router.navigate([`/panel/configuracion-dosier/${envio.idTipoEnvio}`])
  }

}
