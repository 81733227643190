<div class="modal-container">
  <svg class="icon-close" mat-dialog-close>
    <use xlink:href="#icon-close"></use>
  </svg>
  <h1>Aviso de errores</h1>
  <div class="scroll">
    <h2 style="margin-bottom: 10px;" id="revisarFirmantes1" *ngIf="(!isFusionado && data.extraInfo.diferenteNumeroFirmantes) || (!isFusionado && data.extraInfo.diferenteCodigoFirmantes) || data.extraInfo.isValidadorError">Revisar firmantes en dosier y {{docsTexto}}</h2>
    <h2 style="margin-bottom: 10px;" id="sinDocumentos" *ngIf="arrayDocumentos.length==0 && !isFusionado">El dosier no contiene documentos.</h2>
    <div *ngIf="arrayDocumentos.length>0">

      <p class="parrafo-modal" id="revisarFirmantes2" *ngIf="(!isFusionado && data.extraInfo.diferenteNumeroFirmantes) || (!isFusionado && data.extraInfo.diferenteCodigoFirmantes)">Los firmantes que contiene el dosier tienen que ser los mismos en número y tipo que los documentos adjuntos no fusionados. Por favor, cambie los firmantes asociados al dosier o modifique los de los documentos no fusionados.</p>
      <p class="parrafo-modal" *ngIf="data.extraInfo.isValidadorError">Revise los firmantes que son validadores. El tipo de destinatario del dosier debe coincidir con el tipo de firma del documento. Por favor, cambie el tipo de destinatario del firmante del dosier a "Validador" o modifique el tipo de firma del firmante en el documento a "Validador".</p>
      <!-- <p class="parrafo-modal" *ngIf="nombreEnvio==''"><strong>El dosier sin nombre contiene {{numFirmantesEnvio}} {{firmanteTexto}}.</strong></p>
      <p class="parrafo-modal" *ngIf="nombreEnvio!==''"><strong>El dosier con nombre "{{nombreEnvio}}" contiene {{numFirmantesEnvio}} {{firmanteTexto}}.</strong></p> -->
      <div id="revisarFirmantes3" *ngIf="(!isFusionado && data.extraInfo.diferenteNumeroFirmantes) || (!isFusionado && data.extraInfo.diferenteCodigoFirmantes) || data.extraInfo.isValidadorError">
        <h6>El dosier contiene {{numFirmantesEnvio}} {{firmanteTexto}}.</h6>
        <div style="margin-top: 10px;">
          <span *ngFor="let firmante of data.extraInfo.infoEnvio.firmantes; index as i">
            <p class="parrafo-modal">{{i+1}}º - {{firmante.tipoFirmante.desc}}: {{firmante.tipoDestinatario.desc}}</p>
          </span>
        </div>
      </div>


      <h6 *ngIf="(!isFusionado && data.extraInfo.diferenteNumeroFirmantes) || (!isFusionado && data.extraInfo.diferenteCodigoFirmantes)">Hay {{numDocsAdjuntos}} {{docsTexto}} no {{fusionadoText}} con la siguiente información:</h6>
      <div *ngIf="(!isFusionado && data.extraInfo.diferenteNumeroFirmantes) || (!isFusionado && data.extraInfo.diferenteCodigoFirmantes) || data.extraInfo.isValidadorError">
        <h6 *ngIf="arrayDocumentos.length>1 && isFusionado">Documentos adjuntos</h6>
        <h6 *ngIf="arrayDocumentos.length==1 && isFusionado">Documento adjunto</h6>
        <div style="margin-top: 10px;">
          <span *ngFor="let doc of arrayDocumentos; index as i">
            <h5 class="doc-nombre">{{doc.nombre}}</h5>
            <p class="parrafo-modal" *ngIf="sinFirmante">No contiene firmantes</p>
            <div *ngIf="!sinFirmante">
              <!-- <p class="parrafo-modal">Número de firmantes: {{doc.firmantesNif.length}}</p> -->
              <span *ngFor="let firmante of doc.firmantesNif; index as i;">
                <p class="parrafo-modal">{{i+1}}º - {{firmante.tipoFirmante.desc}}: {{firmante.tipoDestinatario.desc}}</p>
              </span>
            </div>
          </span>
        </div>
      </div>

      <h2 id="errorValidadores1" style="margin-top: 20px; margin-bottom: 10px;" *ngIf="data.extraInfo.isValidadorErrorCampos.length>0">Revisar formularios / campos editables de los documentos adjuntos</h2>
      <p class="parrafo-modal" id="errorValidadores2" *ngIf="data.extraInfo.isValidadorErrorCampos.length>0" style="margin-top: 10px;"><span *ngIf="data.extraInfo.isValidadorErrorCampos.length>1">Los siguientes documentos contienen </span><span *ngIf="data.extraInfo.isValidadorErrorCampos.length==1">El siguiente documento contiene </span>"formularios" y/o "campos editables" siendo el tipo de firma de uno de sus firmantes "Validador". Por favor, borre los "formularios" y/o "campos editables" del firmante con tipo de firma "Validador".</p>
      <div id="errorValidadores3" *ngIf="data.extraInfo.isValidadorErrorCampos.length>0">
        <!-- <h6 *ngIf="arrayDocumentos.length>1">Documentos adjuntos</h6>
        <h6 *ngIf="arrayDocumentos.length==1">Documento adjunto</h6> -->
        <div style="margin-top: 10px;">
          <span *ngFor="let doc of arrayDocumentos; index as i">
            <div *ngFor="let nombre of data.extraInfo.isValidadorErrorCampos">
              <div *ngIf="doc.nombre==nombre">
                <h5 class="doc-nombre">{{doc.nombre}}</h5>
                <span *ngFor="let firmante of doc.firmantesNif; index as i;">
                  <p class="parrafo-modal">{{i+1}}º - {{firmante.tipoFirmante.desc}}: {{firmante.tipoDestinatario.desc}}</p>
                </span>
              </div>
            </div>
          </span>
        </div>
      </div>

    </div>

    <div id="mostrarErroresForm" *ngIf="erroresForm.length>0">
      <h2 style="margin-top: 20px; margin-bottom: 10px;">Errores en el formulario de configuración de dosier</h2>
      <div *ngFor="let error of erroresForm">
        <p><span class="error-keys">{{error.key}}</span>: {{error.tipoError}}.</p>
      </div>
    </div>
  </div>
  <div>
    <!-- <div class="modal-title">
        <svg class="icon" *ngIf="data.icono">
          <use [attr.xlink:href]="data.icono|iconoRef"></use>
        </svg>
        <div class="modal-title-text">{{data.titulo}}</div>
    </div> -->
    <app-alerta-accion-irreversible *ngIf="data.tipo == 1 || data.tipo == 3"></app-alerta-accion-irreversible>
    <div [innerHTML]="data.mensaje"></div>
    <!-- <div *ngIf="data.tipo == 1">
        <form [formGroup]="formGroup">
            <p>{{data.preguntaConfirmacion}}</p>
            <div class="check-confirmacion">
                <mat-checkbox [color]="'primary'" formControlName="confirmar">{{data.textoConfirmacion}}</mat-checkbox>
            </div>

            <p>
                <mat-error *ngIf="!confirmarOk()">{{confirmarError()}}</mat-error>
            </p>
        </form>
    </div> -->
    <div class="modal-buttons">
      <app-boton-accion *ngIf="data.tipo == 0 || data.tipo == 1" mat-dialog-close class="btn-cancel-modal" [id]="'cancelar'" [outline]="true" [cancelar]="true" [titulo]="'cancelar'"></app-boton-accion>
      <app-boton-accion (click)="procesarAccion($event)" [id]="data.textoConfirmacion" [icono]="data.iconoConfirmacion" [titulo]="data.textoConfirmacion"></app-boton-accion>
    </div>
  </div>
</div>

