<div class="barra-flex-space-between">
  <h1>Gestión de envíos</h1>
  <div class="acciones">
    <app-barra-descargas (botonClicked)="clickBarraSuperior($event)"></app-barra-descargas>
    <app-boton-accion [id]="'nuevo envio'" [icono]="'hs-send'" [titulo]="'nuevo envío'" [outline]="false" (click)="irASubida()"></app-boton-accion>
  </div>
</div>
<div style="display: flex;">
  <app-filtro-lateral-sqp [resumenFirmas]="firmasResumen"
                          [filtrosSelectorResumen]="filtrosSelectorResumen"
                          (mostrarSeleccion)="clickDesdeFiltroLateralSqp($event)"
                          (estadoEmitter)="actualizarFiltrosDesdeFiltroLateralSqpEstado($event)"
                          [verEstado]="estadosFirmaService.estadoSeleccionado"
                          ></app-filtro-lateral-sqp>
  <div class="main">
    <app-filtro-superior-sqp [filtrosSqp]="filtrosSqp"
                              (filtroSqpModificadoEmitter)="actualizarFiltrosDesdeFiltroSuperior($event)"
                              (borrarTodosFiltrosEmitter)="borrarFiltros()"></app-filtro-superior-sqp>
    <app-sqp-grid-component [data]="firmas"
                            (refresh)="refresh()"
                            [columnDefs]="columnDefs"
                            [seleccion]="'single'"
                            (abrirModalDataEmitter)="selectRowChanged($event)"
                            [gridOptions]="gridOptions"
                            [tipoData]="'firmas'"
                            ></app-sqp-grid-component>
  </div>
</div>
