import { Component, Input, OnInit } from '@angular/core';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { GridColDefsData } from '../../models/grid-col-defs-data';
import {  nullFirma } from '../../models/firmas';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { EstadosFirmaService } from '../../services/estados-firma.service';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-detalle-firma-cabecera',
  templateUrl: './detalle-firma-cabecera.component.html',
  styleUrls: ['./detalle-firma-cabecera.component.css']
})
export class DetalleFirmaCabeceraComponent implements OnInit {

  @Input() firma: Firmas = nullFirma();

  constructor(public datosFirmaService: DatosFirmaService,
              public estadosFirmas:EstadosFirmaService,
              private domSanitizer: DomSanitizer) {  }

  ngOnInit(): void {
  }

  // GetIcono(){
  //   return this.estadosFirmas.GetIcono(this.firma.estadoString);
  // }
  getNombreCentro():string{
    if(!this.firma.nombreCentro||this.firma.nombreCentro.trim().length==0)
      return this.firma.codigoCentro;
    else
    return this.firma.nombreCentro;
  }

  getIcono(){
    return this.domSanitizer.bypassSecurityTrustHtml(new GridColDefsData().GetIcono(this.firma.estadoString, true, true));
  }

}
