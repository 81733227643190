import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SeleccionItems } from '../../../sqpDesplegable/models/desplegable-data';
import { FiltroSqp, VisualizacionDestacada } from '../../../sqpFiltroSuperior/models/filtro-sqp';
import { TipoFiltro } from '../../../sqpFiltroSuperior/models/tipo-filtro';

@Component({
  selector: 'app-indicador-pastilla-filtro',
  templateUrl: './indicador-pastilla-filtro.component.html',
  styleUrls: ['./indicador-pastilla-filtro.component.css']
})
export class IndicadorPastillaFiltroComponent {

  @Input() tituloFiltro:string = '';
  @Input() num:number = null;
  @Input() color:string = 'var(--hs-black)';
  @Input() bgColor:string = 'var(--hs-yellow1)';
  @Input() colorNum:string = 'white';
  @Input() colorX:string;
  @Input() colorHover:string = 'var(--hs-grey1)';
  @Input() bgCirculo:string = '#f57411';
  @Input() paddingPastilla:number = 4;
  // @Output() limpiar: EventEmitter<SeleccionItems> = new EventEmitter<SeleccionItems>();
  @Output() limpiarPastillas: EventEmitter<FiltroSqp> = new EventEmitter<FiltroSqp>();
  hover: boolean;
  @Input() visualizacionDestacada: VisualizacionDestacada;
  @Input() limpiarTodosFiltrosSqp:number = 0;

  ngOnChanges(changes: SimpleChanges){
    // if(changes.limpiarTodo?.currentValue.num>0) this.limpiarFiltro();
    if(changes.limpiarTodosFiltrosSqp?.currentValue>0 && changes.limpiarTodosFiltrosSqp?.previousValue!==undefined) this.limpiarFiltro();
  }

  ngOnInit(){
    if(this.visualizacionDestacada==1){
      this.color = 'white';
      this.bgColor = 'var(--hs-lila)';
      this.colorNum = 'var(--hs-lila)';
      this.colorX = 'white';
      this.colorHover = 'var(--hs-grey3)';
      this.bgCirculo = 'white';
    }
  }

  limpiarFiltro(){
    this.limpiarPastillas.emit({
      tipoFiltro: TipoFiltro.PASTILLA,
      titulo: this.tituloFiltro,
      valoresSeleccionados: {valorBool:false},
      visualizacionDestacada: this.visualizacionDestacada
    })
    this.limpiarTodosFiltrosSqp=0;
  }
}
