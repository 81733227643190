<div class="footer">
  <!-- FIN BOTÓN FOOTER ATRÁS -->
  <app-boton-accion-secundaria (click)="atras()" [id]="'atrás'" [icono]="'hs-arrow-back'" [titulo]="'volver'"></app-boton-accion-secundaria>
  <!-- BOTÓN FOOTER NUEVO ENVÍO -->
  <div *ngIf="dobleAccion" class="flexRight dobleAccion">
    <app-boton-accion *ngIf="botonAccion" class="flexRight accion2" [id]="id2" [icono]="icono2" [titulo]="titulo2" (click)="clicked2()" [outline]="true"></app-boton-accion>
    <app-boton-accion *ngIf="botonAccion" class="flexRight accion1" [id]="id" [icono]="icono" [titulo]="titulo" (click)="clicked()"></app-boton-accion>
  </div>
  <div *ngIf="!dobleAccion" class="flexRight">
    <app-boton-accion *ngIf="botonAccion"  [id]="id" [icono]="icono" [titulo]="titulo" (click)="clicked()"></app-boton-accion>
  </div>
</div>
