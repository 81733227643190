import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { environment } from 'src/environments/environment';
import { ResponseSubidaFicheros } from '../models/response-subida-ficheros';
import { DatosSubidaFicheros } from '../models/subida-ficheros';
import { ColDef, RowHeightParams } from 'ag-grid-community';
import { BehaviorSubject, iif, Observable, Subscriber, throwError } from 'rxjs';
import { DatosProcesoServidor } from '../models/datosProceso';
import { DatosFirmaService } from '../../panelControl/services/datos-firma.service';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { DatosEmpleados } from '../../empleados/models/datos-empleados';
import { IGestionarDatosSubidaService } from './ports/i-gestionar-datos-subida-service';


@Injectable({
  providedIn: 'root'
})
export class DatosSubidaService {

  readonly PASOS_ENVIO_NORMAL: string[] = ["Tipo de envío", "Tipo de dosier", "Subida", "Procesamiento"];
  readonly PASOS_ENVIO_MASIVO: string[] =["Tipo de envío", "Tipo de dosier", "Subida Excel","Subida", "Procesamiento"];
  readonly SUBIDA_NORMAL:string = 'NORMAL';
  readonly SUBIDA_MASIVA:string = 'MASIVA';
  readonly SEGUNDOS_CANCELA:Number = 5;
  pasos: string[] = [];
  stepActive:string = '';
  indexStepActive:number = 0;
  datosProcesoServidor:DatosProcesoServidor = new DatosProcesoServidor();


  tipoSubida: string = "";
  tipoEnvio?: TipoEnvio;
  files: DatosSubidaFicheros[] = [];
  emplados:DatosEmpleados[]=[]

  interval:any={};
  logSubidaPrint: ResponseSubidaFicheros;

  infoLog$ = new BehaviorSubject<any>({});
  datosLog$ = this.infoLog$.asObservable();

  columnDefs: ColDef[]=[
    { field: 'estado', headerName: 'Estado', resizable: true, width: 115, suppressSizeToFit: true,
      cellStyle: params => {
        if (params.value === 'Error') {
          return { 'color': '#D92D20', 'font-weight': '600', 'background-color': '#FEE4E2', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}
        }else{
          return {color: '', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}
        }
      }
    },
    { field: 'tipoEnvio', headerName: 'Tipo de envío', resizable: true, width: 500, wrapText: true, autoHeight: true,
      cellRenderer: params => {
        if (params.value == null) return '';
        else {
          // var row = `<span><svg style="width: 15px;height: 25px; margin: 10px 5px -6px 0px;"><use xlink:href="#icon-files"></use></svg>${params.value.nombre} (${params.value.tiposDocumento.length} docs)</span><br>`;
          let row: string = `<span></span>`
          params.data.documentos.forEach((documento: any) => {
            // row += `<span>`;

            if(documento.tipoDocumento == ('Documento no reconocido')) row += `<svg style="color: #A65959; width: 20px; height: 20px;margin: 0px 5px -5px 20px;"><use xlink:href="#icon-alert"></use></svg>${documento.tipoDocumento} (${documento.fileName})</span>`;
            else{
              row += `<svg style="width: 15px;height: 20px;margin: 0px 5px -5px 0px;"><use xlink:href="#icon-file"></use></svg> ${documento.tipoDocumento} (${documento.fileName})</span>`;
              // row += (documento.estado != 'Error') ? `</span>` :  ` <svg style="color: #A65959; width: 20px; height: 20px;"><use xlink:href="#icon-alert"></use></svg></span>`;
            }
          });
          return row;
        }

      },
      cellStyle: params =>{
        if (params.data.estado === 'Error'){
          return {'background-color': '#FEE4E2'  }
        }else return {};
      }
    },
    {
      field: 'firmante', headerName: 'Firmante', resizable: true, width: 300,
      cellStyle: params =>{
        if (params.data.estado === 'Error'){
          return {'background-color': '#FEE4E2'  }
        }else return {};
      }
    },

    { field: 'mensaje', headerName: 'Mensaje', resizable: true, wrapText: true, autoHeight: true,
          cellStyle: params => {
        if (params.data.estado === 'Error'){
          return { 'color': 'rgba(166, 89, 89, 1)', 'background-color': '#FEE4E2', 'padding-top': '10px', 'padding-bottom': '10px' }
        }else return {'padding-top': '10px', 'padding-bottom': '10px'};
      }, width: 600
    },
  ];


  constructor(@Inject('GestionaDatosSubida') private datosSubidaService: IGestionarDatosSubidaService) { }
  //Indica que estamos en la fase de subida de ficheros
  get faseSeleccionMetodo():boolean{
    return this.indexStepActive==0;
  }
  get faseSeleccionTipoEnvio():boolean{
    return this.indexStepActive==1;
  }
  get faseSubidaExcel():boolean{

    return this.tipoSubida==this.SUBIDA_MASIVA && this.indexStepActive==2;
  }
  get faseSubidaFichero():boolean{

    return (this.tipoSubida==this.SUBIDA_NORMAL && this.indexStepActive==2)||(this.tipoSubida==this.SUBIDA_MASIVA && this.indexStepActive==3);
  }
  get faseProceso():boolean{
    return (this.tipoSubida==this.SUBIDA_NORMAL && this.indexStepActive==3)||(this.tipoSubida==this.SUBIDA_MASIVA && this.indexStepActive==4);
  }
  get datosProcesosAnteriores():Observable<any[]>{
    return new Observable((obsrver)=>{
      if(this.interval) clearInterval(this.interval);
      this.interval= setInterval(()=>this.gestionaLogsFirmas(obsrver),1000)
    });

  }
  getRowHeight(params: RowHeightParams): number | undefined | null {
    if(params.data?.documentos.length > 0) return ((params.data.documentos.length+1) * 50);
    return params.data.rowHeight;
  }

  //MR 250724 - función no utilizada
  async gestionaLogsFirmas(observer:Subscriber<any[]>){

    if(this.stepActive == this.SUBIDA_MASIVA[this.indexStepActive] || this.stepActive == this.SUBIDA_NORMAL[this.indexStepActive]) {
      if(this.datosProcesoServidor.codigoSubida==0){
        //No hay subidas pendientes retornamos nada
        this.datosProcesoServidor.dataLogSubida = [];
        observer.next( this.datosProcesoServidor.dataLogSubida);
        return;
      }else{
        //Hay subidas, retonmaos el log

        const finalizado = await this.getLogSubida(this.datosProcesoServidor.codigoSubida).toPromise();
        //Calculamos el porcentaje
        this.datosProcesoServidor.total =  this.datosProcesoServidor.dataLogSubida.length;
        this.datosProcesoServidor.procesados = this.datosProcesoServidor.dataLogSubida.filter(x=>x.finalizado).length;

        this.datosProcesoServidor.avanceProceso = (this.datosProcesoServidor.procesados/ this.datosProcesoServidor.total) *100
        if(this.datosProcesoServidor.avanceProceso!=this.datosProcesoServidor.avanceAnterior){

          this.datosProcesoServidor.fechaUltimoAvance = new Date();
          this.datosProcesoServidor.avanceAnterior = this.datosProcesoServidor.avanceProceso;
        }
        const ahora = new Date();
        const dif = (ahora.getTime()-this.datosProcesoServidor.fechaUltimoAvance.getTime())/1000;
        if(finalizado && this.interval){
          clearInterval(this.interval);

        }
        // if(dif> this.SEGUNDOS_CANCELA){
        //   await this.cancelaSubidaInacitivad();
        //   this.datosProcesoServidor = new DatosProcesoServidor();
        // }
      }
    }
  }

  getIdActiva(){
     return this.datosSubidaService.getIdActiva().pipe(
      tap((resp:any)=>{
        this.datosProcesoServidor.avanceProceso =0;
        this.datosProcesoServidor.codigoSubida = resp.Data.id;
        this.datosProcesoServidor.fechaUltimoAvance= new Date();
      })
    );
  }

  getEmpleados(file:File){
    return this.datosSubidaService.getEmpleados(file).pipe(
      tap((response: any) =>{
        this.emplados= response.Data;
      })
    );
  }

  //MR 250724 - función no utilizada
  subeFichero(){
    let idTipoEnvio = this.tipoEnvio?.idTipoEnvio;
    let files = this.files;
    return this.datosSubidaService.subeFichero(idTipoEnvio, files).pipe(
      map((response: any) =>{
        if (response) return response
        // else throw new Error(response.Message);
      }),
      catchError(this.handleErrorSubida));
  }

  incializaSubida(){
    this.pasos=this.PASOS_ENVIO_NORMAL;
    this.indexStepActive = 0;
    this.stepActive = this.pasos[0];
    this.emplados = [];
    this.files = [];
    this.datosProcesoServidor.dataLogSubida = [];
  }

  async getIdSubida(){
    return this.datosSubidaService.getIdSubida().pipe(
      tap((ret:any)=>{

        this.datosProcesoServidor.codigoSubida = ret.Data;

      })
    ).toPromise();
  }

  async SubeFichero(file:File){
    const data:FormData = new FormData();
    const idTipoEnvio = this.tipoEnvio?.idTipoEnvio??0;
    data.append('idTipoEnvio', idTipoEnvio.toString());
    data.append('file', file, this.normalizaNombreFichero(file.name));
    let codigoSubida = this.datosProcesoServidor.codigoSubida;
    return this.datosSubidaService.SubeFichero(data, codigoSubida).toPromise();
  }

  // Eliminamos caracteres no deseados de los nombres de los ficheros a subir para solucionar
  // incidencia en cascada porque en signaturit sustituyen espacios por guiones bajos y eso nos
  // daba problemas a la hora de descargar los documentos
  normalizaNombreFichero(fileName: string): string {
    return fileName.replace(/[^a-zA-Z0-9_\-\.]+/g, "");
  }

  procesaFicheros(idSubida: number, isEnvioTest: boolean){

    const param:any =  {idTipoEnvio: this.tipoEnvio?.idTipoEnvio, idSubida: idSubida, isEnvioTest: isEnvioTest};
    if(this.tipoSubida==this.SUBIDA_MASIVA){
      param.empleados =this.emplados;
    }

    return this.datosSubidaService.procesaFicheros(idSubida, param).pipe(
      map((response: any) =>{

        if (response) return response;
      })
    )
  }

  getLogSubida(idSubida: number) {
    return this.datosSubidaService.getLogSubida(idSubida).pipe(
      map((response: any) => {

        let logSubida: ResponseSubidaFicheros = response;
        this.logSubidaPrint = response;
        this.infoLog$.next(response);
        if (logSubida.ficheros.find(x => x.cancelado)) return true;
        // Limpiamos el contenido de la tabla y el id de la subida antes de obtener los datos de la nueva subida
        this.datosProcesoServidor.dataLogSubida = [];
        this.datosProcesoServidor.codigoSubida = logSubida.subida.id;
        this.buildLogTable(logSubida);
        this.datosProcesoServidor.procesados = logSubida.ficheros.filter(x => x.estado == "Error" || x.estado == "Finalizado" ||  x.estado == "En Proceso").length;
        this.datosProcesoServidor.total = logSubida.ficheros.length;
        this.datosProcesoServidor.avanceProceso = (this.datosProcesoServidor.procesados/this.datosProcesoServidor.total) * 100;
        this.datosProcesoServidor.codigoSubida = logSubida.subida.id;
        this.datosProcesoServidor.sinConexion = logSubida.subida.sinConexion;
        return logSubida.subida.finalizado;
      })
    );
  }

  buildLogTable(logSubida:ResponseSubidaFicheros ){
    logSubida.ficheros.forEach(fichero => {
      let log = logSubida.logs.find(x => x.idSubida == fichero.id)?.log;
      if (logSubida.firmantes.length == 0) // Envío estándar
      {
        // Obtenemos el tipo de documento
        let documentos: any[] = this.datosProcesoServidor.dataLogSubida.find(x => x.idFirma == fichero.idFirma)?.documentos ?? [];
        let tipoDocumento = '';
        if(fichero.tipoDocumento == null && fichero.estado == 'Error') tipoDocumento == 'Documento no reconocido';
        else if (fichero.tipoDocumento == null) tipoDocumento = 'Procesando';
        else tipoDocumento = fichero.tipoDocumento.nombre;
        // Buscamos si ya se ha registrado el documento en la tabla y su log y lo actualizamos/añadimos
        if(!documentos?.find(x => x.fileName == fichero.fileName)) {
          documentos.push({fileName: fichero.fileName, tipoDocumento: tipoDocumento, estado: fichero.estado });
        }
        let dataLogSubida = this.datosProcesoServidor.dataLogSubida.find(x => x.idFirma == fichero.idFirma);
        if(dataLogSubida)
        {
          dataLogSubida.documentos = documentos;
          if(fichero.emailEmpleado != null)dataLogSubida.firmante = fichero.emailEmpleado;
          dataLogSubida.estado = fichero.estado;
          dataLogSubida.mensaje = log;
          dataLogSubida.finalizado = fichero.finalizado;
        } else{
          this.datosProcesoServidor.dataLogSubida.push({
            documentos: documentos,
            firmante: fichero.emailEmpleado,
            estado: fichero.estado,
            mensaje: log,
            finalizado: fichero.finalizado,
            tipoEnvio: logSubida.subida.tipoEnvio,
            idFirma: fichero.idFirma,
          });
        }
      }
      else // Envío universal
      {
        logSubida.firmantes.forEach(firmante => {
          let logFichero = logSubida.ficheros.find(x => x.id == firmante.idFichero);
          if(logFichero)
          {
            // Obtenemos el tipo de documento
            let tipoDocumento = '';
            if(fichero.tipoDocumento == null && fichero.estado == 'Error') tipoDocumento == 'Documento no reconocido';
            else if (fichero.tipoDocumento == null) tipoDocumento = 'Procesando';
            else tipoDocumento = fichero.tipoDocumento.nombre;
            let documento = {fileName: logFichero.fileName, tipoDocumento: tipoDocumento, estado: fichero.estado };
            // Al ser una fila por firmante, añadimos siempre un nuevo registro
            this.datosProcesoServidor.dataLogSubida.push({
              documentos: [documento],
              firmante: firmante.mail,
              estado: fichero.estado,
              mensaje: log,
              finalizado: fichero.finalizado,
              tipoEnvio: logSubida.subida.tipoEnvio,
              idFirma: fichero.idFirma
            });
          }
        });
      }
    });
  }

  cancelaSubida(idSubida: number){

    return this.datosSubidaService.cancelaSubida(idSubida).pipe(
      map((response: any)=>{

        return response
      })
    );
  }

  cancelaSubidaInacitivad(){
    let codigoSubida = this.datosProcesoServidor.codigoSubida.toString();
    return this.datosSubidaService.cancelaSubidaInacitivad(codigoSubida).pipe(
      map((response: any)=>{

        return response
      })
    ).toPromise();
  }

  private handleErrorSubida(error: any) {

    let errorMessage = "";

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage =`Error en el cliente : ${error.statusText}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      switch(error.status){
        case 401:
          errorMessage = "Error en la subida";
          break;
        default:
          errorMessage = `Error en el Serviodor (${error.status}): ${error.statusText}`;
      }
    }
    return throwError( errorMessage);
  }
}
