import { Component, ElementRef, HostListener, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { IBarraSuperiorService } from '../../services/ports/iBarraSuperiorService';
import { openWidget,showWidget, hideWidget } from 'src/assets/js/freshworks.js';
import { environment } from 'src/environments/environment';
import { DatosUsuariosService } from 'src/app/pages/seguridad/services/datos-usuarios.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/pages/login/services/login.service';


@Component({
  selector: 'app-barra-superior-sqp',
  templateUrl: './barra-superior-sqp.component.html',
  styleUrls: ['./barra-superior-sqp.component.css']
})
export class BarraSuperiorSqpComponent implements OnInit {

  @Input() logo:string='';
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('flexConf') flexConf: ElementRef;
  @ViewChild('icon') icon: ElementRef;
  @ViewChild('iconSvg') iconSvg: ElementRef;
  @ViewChild('iconUse') iconUse: ElementRef;
  @ViewChild('nameHeader') nameHeader: ElementRef;
  @ViewChild('name') name: ElementRef;
  @ViewChild('entorno') entorno: ElementRef;
  configuracion: boolean = false;
  verConfiguracion: boolean = true;
  nombreEntorno:string='';
  mostrarWidget:boolean = false;

  constructor(@Optional() @Inject('RecuperaBarraSuperior') public barraSuperiorService: IBarraSuperiorService,
              public eRef: ElementRef,
              public loginService: LoginService) { }

  ngOnInit(): void {
    this.nombreEntorno = this.barraSuperiorService.getTenant();
    this.verConfiguracion = environment.LoginUniversal;
  }

  ngOnDestroy(){
    this.barraSuperiorService.destroyComponente();
  }

  @HostListener('click', ['$event']) onClick(event: any) {
    if(event.target == this.toggleButton.nativeElement ||
      event.target == this.flexConf.nativeElement ||
      event.target == this.icon.nativeElement ||
      event.target == this.iconSvg.nativeElement ||
      event.target == this.iconUse.nativeElement ||
      event.target == this.nameHeader.nativeElement ||
      event.target == this.name.nativeElement ||
      event.target == this.entorno.nativeElement) this.configuracion = !this.configuracion
    else this.configuracion = false
  }

  //cerrar el menú si clicamos fuera del componente
  @HostListener('document:click', ['$event']) clickout(event:any) {
    if(!this.eRef.nativeElement.contains(event.target)) this.configuracion = false
  }

  abrirFreshWorks(){
    //  eval('window.FreshworksWidget("open")');
     openWidget();
  }

  getNameLogin(){
    return this.barraSuperiorService.getNameLogin();
  }

  // goHelp(){
  //   this._snackBar.open("Opción no disponible", "Cerrar", { duration: 3000 });
  // }

  cerrarMenu(event: any){
    if(event && !this.eRef.nativeElement) this.configuracion=false
  }

  goHome(){
    this.barraSuperiorService.goHome();
  }
}
