import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatosEnviosService } from '../../services/datos-envios.service';
import { FiltroEnvio } from 'src/app/shared/models/filtros';
import { Router } from '@angular/router';
import { DesplegableData } from 'src/app/shared/components/sqp-components/sqpDesplegable/models/desplegable-data';

@Component({
  selector: 'app-envios-filtros-nuevos',
  templateUrl: './envios-filtros-nuevos.component.html',
  styleUrls: ['./envios-filtros-nuevos.component.css']
})
export class EnviosFiltrosNuevosComponent {

  formGroup: FormGroup = new FormGroup({});
  tiposDosier: DesplegableData[] = [];
  estados: DesplegableData[] = [];

  @Output() filtrarEnvios: EventEmitter<string> = new EventEmitter<string>();

  constructor(private datosEnviosService: DatosEnviosService,
              private router: Router) {
                this.inicializaFormulario();
              }

  ngOnInit(): void {
    if(this.router.url.includes('incompletos')){
      this.formGroup = new FormGroup({
        estados: new FormControl('Incompleto')
      });
    }else if(this.datosEnviosService.filtros.length>0){
      this.datosEnviosService.filtros.forEach(x=>{
        if(x.estado!=''){
          this.formGroup = new FormGroup({
            estados: new FormControl(x.estado)
          });
        }
        if(x.estado=='TODOS'){
          this.formGroup = new FormGroup({
            estados: new FormControl('Todos los estados')
          });
        }
      })
    }
  }

  inicializaFormulario() {
    if(this.datosEnviosService.enviosFiltrados){
      // this.tiposDosier = this.datosEnviosService.GetTipoDosierLista();
      this.estados  = this.datosEnviosService.GetEstadosLista();
      this.formGroup = new FormGroup({
        // tiposDosier: new FormControl([]),
        estados: new FormControl('Todos los estados')
      });
    }

  }


  aplicarFiltros(){
    let filtros: FiltroEnvio[] = [];
    this.actualizaFiltroEstado(filtros);
  }

  // actualizaFiltroDosier(filtros: FiltroEnvio[]){
  //   const tipoDosier = this.formGroup.controls["tiposDosier"].value;
  //   if(tipoDosier=='Nombre dosier'||tipoDosier=='') return

  //   const tipo = this.tiposDosier.find(x=>x.desc==tipoDosier);
  //   const filtroDosier:FiltroEnvio ={
  //     text: 'Tipo Dosier',
  //     textoBuscar: '',
  //     value: tipo?.desc??'',
  //     estado:'',
  //     idTipoEnvio: tipo?.id??''
  //   }
  //   filtros.push(filtroDosier)

  // }

  actualizaFiltroEstado(filtros: FiltroEnvio[]){
    const tipoEstado = this.formGroup.controls["estados"].value;

    let tipo;
    if(tipoEstado=='Todos los estados'){
      tipo = {id:'TODOS', desc:'TODOS'}
    }else{
      tipo = this.estados.find(x => x.id == tipoEstado)
    }

    const filtroEstado: FiltroEnvio = {
      text: 'Estado',
      textoBuscar: '',
      value: tipo?.desc??'',
      estado: tipo?.desc??'',
      idTipoEnvio: ""
    }

    let existeEstado = this.datosEnviosService.filtros.findIndex(x=>x.text=='Estado')
    if(existeEstado>-1) this.datosEnviosService.filtros[existeEstado] = filtroEstado;
    else this.datosEnviosService.filtros.push(filtroEstado)
    filtros = this.datosEnviosService.filtros
    // filtros.push(filtroEstado)
    this.datosEnviosService.SetFilter(filtros);
    this.datosEnviosService.Filter();
  }

}
