import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';

import { EmpresasPageComponent } from './empresas/components/empresas-page/empresas-page.component';
import { EmpresasBarraSuperiorComponent } from './empresas/components/empresas-barra-superior/empresas-barra-superior.component';
import { EmpresasModalComponent } from './empresas/components/empresas-modal/empresas-modal.component';
import { NuevaEmpresaModalComponent } from './empresas/components/nueva-empresa-modal/nueva-empresa-modal.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CentrosBarraSuperiorComponent } from './centros/componentes/centros-barra-superior/centros-barra-superior.component';
import { CentrosModalComponent } from './centros/componentes/centros-modal/centros-modal.component';
import { CentrosPageComponent } from './centros/componentes/centros-page/centros-page.component';
import { CentrosUploadExcelPageComponent } from './centros/componentes/centros-upload-excel-page/centros-upload-excel-page.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';


@NgModule({
  declarations: [
    EmpresasPageComponent,
    EmpresasBarraSuperiorComponent,
    EmpresasModalComponent,
    NuevaEmpresaModalComponent,
    CentrosBarraSuperiorComponent,
    CentrosModalComponent,
    CentrosPageComponent,
    CentrosUploadExcelPageComponent
  ],

  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDialogModule,
    SqpComponentsModule
  ],

  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
 ],
})
export class MaestrosModule { }
