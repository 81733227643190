import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ini-page',
  templateUrl: './ini-page.component.html',
  styleUrls: ['./ini-page.component.css']
})
export class IniPageComponent implements OnInit {

  constructor(private loginService: LoginService,
              private router: Router,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    localStorage.setItem('SQPFIRMA_login', '');
    if(!this.loginService.tokenIsExpired()){
      this.router.navigate(["/panel/main"]);
    }else{
      if(environment.LoginUniversal){
          this.loginService.GetUrlLogin().subscribe(next=>{
            if(!next) this._snackBar.open(`Se ha producido un error inesperado. No podemos acceder al login`, 'Cerrar');
            window.location.href = next;
            },error=>{
              console.log(error);
              this._snackBar.open(`Se ha producido un error inesperado. Contacte con su proveedor ${error}`, 'Cerrar');
            }
          );
      }else{
        this.router.navigate(["/login"]);
      }
    }
  }
}
