import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { TipoDocumento } from '../../documentos/models/tipo-documento';
import { TipoEnvio } from '../models/tipo-envio';

export function validacionNombre(envios: TipoEnvio[], envio: TipoEnvio): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      if (value == '') {
          return {nombreVacio: true};
      }else{
        let nombreExiste = envios.findIndex(x => removeAccents(x.nombre.toUpperCase()) == removeAccents(control.value.toLocaleUpperCase()))

        if(nombreExiste > -1){
          let mismoId = envios.findIndex(x=> x.idTipoEnvio == envio.idTipoEnvio)
          if(mismoId > -1 && nombreExiste == mismoId){
            return null
          }else{
            return {nombreExiste: true}
          }
        }else{
          return null
        }
      }
    }
}

export function removeAccents(str:string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function validacionTipoDocs(envio: TipoEnvio){
  return (control:AbstractControl): ValidationErrors | null => {
    const valueDoc = control.value;
    if(envio.esRLT) return null;
    if(valueDoc.length > 0){
      const fusionableArray = valueDoc.filter((x: TipoDocumento) => x.fusionable == true)
      if(fusionableArray.length == 1) return {docsFusionables: true}
      return null
    }
    return null

  }
}

export function validacionBody(predicate:any): ValidatorFn{
  return (control:AbstractControl): ValidationErrors | null => {
    const  valueBody = control.value;
    const  valueEnvioSMS = predicate().get('envioSMS').value;
    let maxLengthError;
    if(valueEnvioSMS){
      maxLengthError = Validators.maxLength(110)(control);
    }
    return maxLengthError ? maxLengthError : null;
  }
}

export function validacionBody2(predicate:any): ValidatorFn{
  return (control:AbstractControl): ValidationErrors | null => {
    const  valueBody = control.value;
    const  valueEnvioSMS = predicate().get('envioSMS').value;

    if(!valueEnvioSMS && valueBody.includes('{{url}}')) return {noNecesitaUrl: true}
    else if(valueEnvioSMS && !valueBody.includes("{{url}}")) return {necesitaUrl: true}
    else return null
  }
}

// export function notRequiredIfDefecto(predicate:any) {
//   return ((control: FormControl) => {
//     if (!control.parent) return null;
//     if (predicate()) return null;
//     return Validators.required(control);
//   })
// }

// export function requiredIfAdjuntoSelected(predicate:any) {
//   return ((formControl: FormControl) => {
//     if (!formControl.parent) return null;
//     if (predicate()){
//       if(formControl.value == null || formControl.value < 1 || formControl.value > 15) return {validadoresAdjunto: false};
//       else return null;
//     }else{
//       return null;
//     }
//   })
// }

// export function requiredIfRecordatorioSelected(predicate:any) {
//   return ((formControl: FormControl) => {
//     if (!formControl.parent) return null
//     if (predicate()){
//       // let regex = /^[0-9,]+$/;  || !regex.test(formControl.value)
//       const arrayValues = formControl.value.split(',')

//       arrayValues.forEach((x:any)=> {

//         if(Number(x) < 0 || Number(x) > 60) return {validadoresRecordatorioNum: false}
//         else return null
//       })
//       if(formControl.value == null || formControl.value == '' ) return {validadoresRecordatorio: false};
//       else return null;
//     }else{
//       return null;
//     }
//   })
// }

//*****comentado el 28/05/24
// export function requiredIfRecordatorioSelected(predicate:any) {
//   return ((formControl: FormControl) => {
//     if (!formControl.parent) return null
//     if (predicate()){
//       let returnValidacion: boolean[] = []
//       const arrayValues = formControl.value.split(',')

//       arrayValues.forEach((x:any)=> {
//         if(Number(x) < 0 || Number(x) > 60) {
//           returnValidacion.push(true)
//         }
//       })
//       if(formControl.value == null || formControl.value == '' ) return {validadoresRecordatorio: false};
//       else if(returnValidacion.includes(true)) return {validadoresRecordatorioNum: false}
//       else return null;
//     }else
//     return null
//   })
// }
/***** FIN */

    // let returnValidacion: boolean[] = []
    // if(this.reminders_String.value !== null){
    //   const arrayValues = this.reminders_String.value.split(',')

    //   arrayValues.forEach((x:any)=> {

    //     if(Number(x) < 0 || Number(x) > 60) {

    //       returnValidacion.push(true)
    //     }
    //   })
    // }
    // if(returnValidacion.includes(true)) return true;
    // else return false;

// export function requiredIfProgramadoSelected(predicate:any) {
//   return ((formControl: FormControl) => {
//     if (!formControl.parent) return null
//     if (predicate()){
//       if(formControl.value == null || formControl.value < 1) return {validadoresProgramado: false};
//       else return null;
//     }else{
//       return null;
//     }
//   })
// }

