<div class="container-filtro-lateral">
  <div class="filtros">
    <div class="titulo-filtro-lateral">
      <h4>Filtros</h4>
    </div>
    <div *ngIf="filtrosSelectorResumen.length>0" class="selectores">
      <app-selector *ngFor="let filtro of filtrosSelectorResumen" [textoOpcion2]="filtro.textoOpcion2" [active]="filtro.active" [control]="filtro.control" (mostrarSeleccionSqp)="clickFiltroSelector($event)"></app-selector>
    </div>
  </div>
  <app-resumen-estados-sqp  [resumenFirmas]="resumenFirmasSqp"
                            (clickEstado)="estadosFirmaService.clickEstadoEnvio($event);clickEstado($event)"
                            [estadoSeleccionado]="estadosFirmaService.estadoSeleccionado"
                            [verNuevos]="false"
                            [verTooltip]="true"
                            [colorSeleccion]="'var(--hs-yellow1)'"
                            ></app-resumen-estados-sqp>
</div>

