import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { TenantData } from '../../models/TenantData';
import { IBarraSuperiorService } from '../../../sqpBarraSuperior/services/ports/iBarraSuperiorService';

@Component({
  selector: 'app-usuario-activo-sqp',
  templateUrl: './usuario-activo-sqp.component.html',
  styleUrls: ['./usuario-activo-sqp.component.css']
})
export class UsuarioActivoSQPComponent implements OnInit {

  @Input() verConfigUsuario:boolean = true;
  @Input() verCambioTenant:boolean = true;
  @Input() tenantDataArray: TenantData[] = [];

  @Output() clickFuera: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private eRef: ElementRef,
              @Optional() @Inject('RecuperaBarraSuperior') public barraSuperiorService: IBarraSuperiorService) { }

  ngOnInit(): void {
  }

  clickConfigUsuario() {
    if(this.barraSuperiorService) this.barraSuperiorService.clickConfigUsuario();
  }

  clickCambioTenant() {
    if(this.barraSuperiorService) this.barraSuperiorService.clickCambioTenant();
  }

  clickLogOut() {
    if(this.barraSuperiorService) this.barraSuperiorService.clickLogOut();
  }

  @HostListener('document:click', ['$event']) clickout(event:any) {
    if(this.eRef.nativeElement.contains(event.target)) {
      this.clickFuera.emit(false)
    } else {
      this.clickFuera.emit(true)
    }
  }

}
