import { Component, Inject, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';
import { DetallesEstado } from '../../models/detalles-estado';
import { TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';


@Component({
  selector: 'app-detalle-firma',
  templateUrl: './detalle-firma.component.html',
  styleUrls: ['./detalle-firma.component.css']
})
export class DetalleFirmaComponent implements OnInit {
  @Input() visible:boolean = true;

  estadoFirma: string = '';
  mostrarDetalles: {count: number, abrir: boolean} = {count:0, abrir: false};
  tituloMostrarDetalles: string = 'Abrir todos los detalles';
  detallesEstado:DetallesEstado[]=[
    {tipo:'firmantes', estado:false},
    {tipo:'documentos', estado:true},
    {tipo:'eventos', estado:false},
    {tipo:'remitente', estado:false}
  ]

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public firma: Firmas,
              public datosFirmaService:DatosFirmaService) {
  }

  ngOnInit(): void {
    if(this.firma.estadoString == 'Parcialmente firmado' || this.firma.estadoString == 'No firmado'){
      this.detallesEstado.push({tipo:'caducidad', estado:false})
    }
  }

  verBorrar():boolean{
    if(['BORRADOR', 'ERROR'].includes(this.firma.estadoString.toUpperCase())){
      return true;
    }else{
      return false;
    }
  }

  verCancelar():boolean{
    if(this.firma.estadoString.toUpperCase()=='NO FIRMADO'){
      return true;
    }else{
      return false;
    }
  }

  verDescargar():boolean{
    if(['FIRMADO','PARCIALMENTE FIRMADO'].includes(this.firma.estadoString.toUpperCase())){
      return true;
    }else{
      return false;
    }
  }

  borrarFirma(){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
        icono: 'icon-delete',
        titulo: '¿Eliminar registro?',
        // preguntaConfirmacion: '¿Eliminar registro?',
        textoConfirmacion: 'Eliminar el registro seleccionado',
        textoBoton: 'Eliminar',
        iconoConfirmacion: 'icon-delete'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK')
      {
        dialogRef.close();
        const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
        this.datosFirmaService.BorrarFirma(this.firma.idFirma).subscribe(
          ok =>{
            dialogRefSpinner.close();
            // this.dialog.open(ModalMessageComponent, {data: { mensaje: ok }, panelClass: 'modal-class' });
            this.dialog.open(ModalMessageComponent, {data: { mensaje: 'Envío eliminado correctamente' }, panelClass: 'modal-class' });
            this.dialogRef.close({refresh: true});
          },
          error => {
            dialogRefSpinner.close();
            this.dialog.open(ModalMessageComponent, { data: {alert: true, mensaje: error }, panelClass: 'modal-class' });
            this.dialogRef.close({refresh: true});
          }
        );
      }
    });
  }

   cancelarFirma(){
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.datosFirmaService.CancelarFirma(this.firma.idFirma).subscribe(
      ok =>{
        dialogRef.close();
        this.dialog.open(ModalMessageComponent, { data: { mensaje: 'Envío cancelado correctamente.' }, panelClass: 'modal-class' });
        this.dialogRef.close({refresh: true});
      },
      error => {
        dialogRef.close();
        this.dialog.open(ModalMessageComponent, { data: {alert: true, mensaje: error }, panelClass: 'modal-class' });
      }
    );
  }

  descargando: boolean = false;
  descargarFirma(){

    this.datosFirmaService.DescargaFirmaCompleta(this.firma.idFirma);

  }

  // cargarDetallesFirma(){
  //   if(this.firma != undefined)
  //   {
  //     this.estadoFirma = this.firma.estadoString.toUpperCase();
  //     return true;
  //   }
  //   else return false;
  // }

  switchDetalles(){
    this.mostrarDetalles = {count: this.mostrarDetalles.count+1, abrir: !this.mostrarDetalles.abrir}
    // this.tituloMostrarDetalles = this.mostrarDetalles.abrir ? 'Cerrar todos los detalles':'Abrir todos los detalles';
  }

  checkSwitchDetalles(event:DetallesEstado){
    let index = this.detallesEstado.findIndex(x=> x.tipo==event.tipo);
    this.detallesEstado[index] = event;

    const resultadoTrue = this.detallesEstado.every(x=>x.estado);
    const resultadoFalse = this.detallesEstado.every(x=>!x.estado);

    if(resultadoTrue) this.tituloMostrarDetalles = 'Cerrar todos los detalles';
    if(resultadoFalse) this.tituloMostrarDetalles = 'Abrir todos los detalles';
  }

}
