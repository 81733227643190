import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ResumenFirmasSqp } from '../../../sqpResumenEstados/models/resumen-firmas-sqp';
import { EstadosFirmaService } from 'src/app/pages/panelControl/services/estados-firma.service';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { TooltipMessages } from 'src/app/shared/models/tooltip.mesages';
import { FiltrosSelectorResumen } from '../../../sqpSelectores/models/filtros-selector-resumen';

@Component({
  selector: 'app-filtro-lateral-sqp',
  templateUrl: './filtro-lateral-sqp.component.html',
  styleUrls: ['./filtro-lateral-sqp.component.css']
})
export class FiltroLateralSqpComponent implements OnInit {

  resumenFirmasSqp: ResumenFirmasSqp[] = [];
  @Input() resumenFirmas: ResumenFirmas[]= [];
  @Input() filtrosSelectorResumen: FiltrosSelectorResumen[]=[];
  @Output() mostrarSeleccion: EventEmitter<FiltrosSelectorResumen> = new EventEmitter<FiltrosSelectorResumen>();
  @Output() estadoEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() verEstado:string='';

  constructor(public estadosFirmaService: EstadosFirmaService) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.resumenFirmas.currentValue){
      this.resumenFirmasSqp = [];
      this.resumenFirmas = changes.resumenFirmas.currentValue;
      this.resumenFirmas.forEach(x=>{
        let firma = {} as ResumenFirmasSqp;
        firma.estado = x.estado;
        firma.etiqueta = x.etiqueta;
        firma.hayNovedades = x.hayNovedades;
        firma.icono = x.icono;
        firma.numeroElementos = x.firmas.length;
        firma.numerosNuevos = '';
        firma.tooltipMessage = this.getTooltip(x.estado);

        this.resumenFirmasSqp.push(firma);
      })
    }
    if(changes.verEstado?.currentValue){
      this.estadoEmitter.emit(changes.verEstado?.currentValue);
    }
  }

  //verEstados lo ponemos como input para ver que ha cambiado el estado del servicio, lo volvelos a enviar al padre (panel de control) para que lo añada al array de filtros

  ngOnInit(): void {
  }

  getTooltip(estado: string){
    if (estado.toUpperCase() == "BORRADOR") return TooltipMessages.envio_borradores;
    else if (estado.toUpperCase() == "FIRMADO") return TooltipMessages.envio_firmados;
    else if (estado.toUpperCase() == "PARCIALMENTE FIRMADO") return TooltipMessages.envio_parc_firmados;
    else if (estado.toUpperCase() == "RECHAZADO") return TooltipMessages.envio_rechazados;
    else if (estado.toUpperCase() == "NO FIRMADO") return TooltipMessages.envio_por_firmar;
    else if (estado.toUpperCase() == "CADUCADO") return TooltipMessages.envio_caducados;
    else if (estado.toUpperCase() == "ERROR") return TooltipMessages.envio_errores;
    else if (estado.toUpperCase() == "CANCELADO") return TooltipMessages.envio_cancelados;
    else return '';
  }

  clickFiltroSelector(selector: FiltrosSelectorResumen){
    this.mostrarSeleccion.emit(selector)
  }

  clickEstado(estado:string){
    this.estadoEmitter.emit(estado);
  }
}
