<div class="modal-container">
  <svg class="icon-close" mat-dialog-close>
    <use xlink:href="#icon-close"></use>
  </svg>
  <h1>{{titulo}}</h1>
  <div class="flex-container">
    <div class="modal-content">
        <div class="grid-seguridad">
          <app-grid-seguridad-tipos-envio *ngIf="titulo == 'Selección tipo de dosier'" [enviosData]="enviosData" (seleccionEnvios)="seleccionarEnvios($event)" [filtroSeguridadFirmas]="data.filtroSeguridadFirmas" [dataSeguridad]="data"></app-grid-seguridad-tipos-envio>
          <app-grid-seguridad-empresas *ngIf="titulo == 'Selección empresas'" [empresasData]="empresasData" (seleccionEmpresas)="seleccionarEmpresas($event)" [filtroSeguridadFirmas]="data.filtroSeguridadFirmas" [dataSeguridad]="data"></app-grid-seguridad-empresas>
          <app-grid-seguridad-centros *ngIf="titulo == 'Selección centros de trabajo'" [centrosData]="centrosData" (seleccionCentros)="seleccionarCentros($event)" [filtroSeguridadFirmas]="data.filtroSeguridadFirmas" [dataSeguridad]="data"></app-grid-seguridad-centros>
        </div>
      </div>
      <div class="modal-buttons">
          <app-boton-accion mat-button (botonClicked)="guardarSeleccion()" [icono]="" [titulo]="'aceptar'" [id]="'aceptar'">
          </app-boton-accion>
      </div>
  </div>
</div>
