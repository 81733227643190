<div class="aside-main">
  <div>
    <button *ngFor="let opcion of itemsMenu" class="btn2 buttonAside" [ngClass]="{'seleccion': focusSeleccion==opcion.ruta}" (click)="avisaCerrarConfiguraciones(opcion.ruta)" routerLinkActive="buttonAside-active">
      <span class="btn-icon">
        <svg class="icon">
          <use attr.xlink:href="{{opcion.icono}}"></use>
        </svg>
    </span>
    </button>
  </div>
</div>
