<div class="container">
  <div class="top-info" [ngStyle]="{'background-color': isSandbox ? '#F7F4FF':''}">
    <app-info-dosier></app-info-dosier>
  </div>
  <div class="content">
      <div *ngIf="hayErrores" class="alert">
        <svg class="icon-alert">
          <use xlink:href="#hs-alert"></use>
        </svg>
        <div class="alert-message">
          <span>Se han encontrado algunos errores</span>
          <br>
          <span>Por favor, revisa los registros con anomalías.</span>
        </div>
      </div>
      <div *ngIf="noErrores" class="alert">
        <svg class="icon-ok">
          <use xlink:href="#hs-tick-circle"></use>
        </svg>
        <div class="alert-message">
          <span>Se han procesado todos los documentos correctamente.</span>
        </div>
      </div>
      <app-selector *ngIf="subidaFinalizada" style="float: right; margin: -30px 0px;"  [textoOpcion1]="'Mostrar solo errores'" [active]="verNuevasParam" [control]="'nuevasFirmas'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector>
      <div class="grid">
        <app-avance-subida *ngIf="!subidaFinalizada"></app-avance-subida>
        <ag-grid-angular *ngIf="subidaFinalizada"
                          style="height: 100%;"
                          class="ag-theme-alpine"
                          [rowData]="datosSubidaService.datosProcesoServidor.dataLogSubida"
                          [columnDefs]="datosSubidaService.columnDefs"
                          [pagination]="false"
                          (gridReady)="cargaApi($event)"
                          [getRowHeight]="datosSubidaService.getRowHeight"
                          [rowClassRules]="rowClassRules">
        </ag-grid-angular>
      </div>
  </div>
</div>
