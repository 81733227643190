import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { IMenuLateralService } from '../../services/ports/iMenuLateralService';
import { OpcionesMenuLateral } from '../../models/opcionesMenuLateral';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-menu-lateral-sqp',
  templateUrl: './menu-lateral-sqp.component.html',
  styleUrls: ['./menu-lateral-sqp.component.css']
})
export class MenuLateralSqpComponent implements OnInit {

  @Input() itemsMenu: OpcionesMenuLateral[] = [];
  focusSeleccion:string='';

  constructor(@Optional() @Inject('RecuperaMenuLateral') public menuLateralService: IMenuLateralService,
              private router: Router) { }

  ngOnInit() {
    this.checkFocusSeleccion(this.router.url);
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.checkFocusSeleccion(event.url)
      );
  }

  checkFocusSeleccion(currentUrl: string){
    let splitUrl = currentUrl.split('/');
    if(currentUrl == '/panel/dosieres-configurados'||
        currentUrl == '/panel/dosieres-configurados/incompletos' ||
        currentUrl == `/panel/configuracion-dosier/${splitUrl[splitUrl.length-1]}` ||
        currentUrl == '/panel/documentos-configurados' ||
        currentUrl == '/panel/nueva-configuracion-documento' ||
        currentUrl == '/panel/editar-configuracion-documento' ||
        currentUrl == '/panel/configuracion/empresas' ||
        currentUrl == '/panel/configuracion/centros' ||
        currentUrl == '/panel/usuarios' ||
        currentUrl == '/panel/configuracion-rlt' ||
        currentUrl == '/panel/configuracion-usuario') this.focusSeleccion = '/panel/opciones-configuracion';
    else if(currentUrl == '/panel/subida?envio=estandar' ||
            currentUrl == '/panel/subida?envio=universal') this.focusSeleccion = '/panel/subida';
    else if(currentUrl =='/panel/envios/nuevos') this.focusSeleccion = '/panel/envios';
    else this.focusSeleccion = currentUrl;
  }

  avisaCerrarConfiguraciones(ruta:string){
    if(this.menuLateralService){
      this.menuLateralService.puedoIrMenu(ruta).then(result => {
        if (result) {
          this.focusSeleccion = ruta;
          this.router.navigate([ruta]);
        }
      });
    } else {
      this.focusSeleccion = ruta;
      this.router.navigate([ruta]);
    }
  }

}
