<div class="main-menu">
  <!-- <h1>Hola {{getNameLogin()}}</h1>
  <p style="color: #898989;">Bienvenido/a de nuevo al dashobard e Hire & Sign.</p> -->
  <div class="main-container">
    <div class="column-medium">
        <h3>Nuevos envíos</h3>
        <div class="nuevas-firmas" matTooltip="{{getTooltip()}}" [matTooltipPosition]="'after'">
          <app-indicador-numero [numero]="datosFirmasService.firmasNuevas.length.toString()" [color]="'var(--hs-yellow)'"></app-indicador-numero>
          <div>Nuevos envíos</div>
          <div (click)="navega('NUEVAS')">Acceder</div>
        </div>
        <app-boton-resumen-firmas [resumenFirmas]="firmasResumen"></app-boton-resumen-firmas>
        <!-- <app-boton-resumen-firmas [resumenFirmas]="firmasResumen" [texto]="'Estado de los envíos'" [color]="'button-lanzadera white'"></app-boton-resumen-firmas> -->
    </div>
    <div class="column-big">
        <app-boton-ultimas-firmas [UltimasFirmas]="ultimasFirmas"></app-boton-ultimas-firmas>
    </div>
    <div class="column-medium">
      <h6>ACCESOS DIRECTOS</h6>
      <app-boton-acceso-directo [icono]="'#hs-send'" [titulo]="'Nuevo envío estándar'" [texto]="'Dirigido solo a empleados con documentos genéricos.'" (click)="navega('ESTANDAR')"></app-boton-acceso-directo>
      <app-boton-acceso-directo [icono]="'#hs-send-universal'" [titulo]="'Nuevo envío universal'" [texto]="'Dirigido a empleados y no empleados sin datos personales.'" (click)="navega('UNIVERSAL')"></app-boton-acceso-directo>
      <app-boton-acceso-directo [icono]="'#hs-employees'" [titulo]="'Actualizar empleados'" [texto]="'Autorellena al combinarlo con una plantilla excel de firmante.'" (click)="navega('EMPLEADOS')"></app-boton-acceso-directo>
      <h6 style="margin-top: 20px!important; margin-bottom: 5px!important;">OTROS ACCESOS</h6>
      <ul>
        <div class="li-flex">
          <li style="padding-right: 10px;" (click)="navega('INCOMPLETOS')">Dosieres incompletos</li>
          <app-indicador-numero [numero]="numEnviosIncompletos" [color]="'var(--hs-red)'"></app-indicador-numero>
        </div>
        <li (click)="navega('DOCUMENTOS')">Documentos</li>
        <li><a href="https://soporte.hireandsign.com/"  target="_blank">Centro de ayuda</a></li>
      </ul>
    </div>
  </div>
</div>
