import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationMessageComponent } from '../../sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { FormularioEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/formulario-envio.service';
import { TipoEnvioClass } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { TipoMensajeConfirmacion } from '../../sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';

@Component({
  selector: 'app-barra-footer',
  templateUrl: './barra-footer.component.html',
  styleUrls: ['./barra-footer.component.css']
})
export class BarraFooterComponent implements OnInit {

  // @Input() botones: BotonAccionData[] = [];
  @Input() icono: string = '';
  @Input() titulo: string = '';
  @Input() id: string = '';
  @Input() botonAccion: boolean = true;
  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input() dobleAccion: boolean = false;
  @Input() id2: string = '';
  @Input () titulo2: string = '';
  @Input() icono2: string = '';
  @Output() botonClicked2: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router,
              private dialog: MatDialog,
              private formularioEnvioService: FormularioEnvioService) { }

  ngOnInit(): void {
  }

  atras(){
    if(this.router.url.includes('configuracion-dosier')){
      if(!this.formularioEnvioService.hayCambiosDosier()){
        history.back();
        this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
      }else{
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-save2',
          titulo: '¿Deseas salir sin guardar?',
          mensaje:'Se perderán todos los cambios realizados en la configuración del dosier.',
          textoConfirmacion: 'Salir sin guardar',
          iconoConfirmacion: 'icon-unsaved',
          textoCancelar: 'cerrar'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK'){
          history.back();
          this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
        }
      });
      }
    }else{
      history.back();
    }

  }

  clicked(){
    this.botonClicked.emit(this.id);
  }

  clicked2(){
    this.botonClicked2.emit(this.id2);
  }
}
