import { DesplegableData } from "../../sqpDesplegable/models/desplegable-data";
import { TipoFiltro } from "./tipo-filtro";

export interface FiltroSqp{
  tipoFiltro: TipoFiltro;
  titulo: string;
  valoresDesplegable?: DesplegableData[];
  visualizacionDestacada?: VisualizacionDestacada;
  valoresSeleccionados?: SeleccionSqp;
}

export interface SeleccionSqp{
  valorFechaDesde?: Date | null;
  valorFechaHasta?: Date | null;
  valorString?: string; //buscar y estado
  valorBool?: boolean; //nuevo y sandbox
  listaValores?: string[]; //respuesta de los desplegables
}

export enum VisualizacionDestacada{
  PRIMARY,
  TEST
}

export interface ListaDropDown{
  titulo: string;
  listaDesplegable: DesplegableData[];
}
