import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormularioEnvioService } from '../../services/formulario-envio.service';
import { FormularioValidacionesService } from '../../services/formulario-validaciones.service';

@Component({
  selector: 'app-envios-recordatorios',
  templateUrl: './envios-recordatorios.component.html',
  styleUrls: ['./envios-recordatorios.component.css']
})
export class EnviosRecordatoriosComponent implements OnInit {

  @Output() eliminar: EventEmitter<any> = new EventEmitter<any>();
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Input() indexAviso: number;
  @Input() numAviso: number;
  @ViewChild('inputAviso') inputAviso: ElementRef;

  constructor(public formularioEnvioService: FormularioEnvioService,
              public formularioValidacionesService: FormularioValidacionesService) { }

  ngOnInit(): void {
  }

  eliminarAviso(){
    if(!this.formularioEnvioService.disableRecordatorio) this.eliminar.emit(this.indexAviso)
  }

  updateAviso(num: string){
    if(!this.formularioEnvioService.disableRecordatorio){
      let numEnviar = num == '' ? null : Number(num);
      let infoAviso: {index: number, valor: number}
      infoAviso = {
        index: this.indexAviso,
        valor: numEnviar
      }
      this.update.emit(infoAviso)
    }
  }

}

