import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfoApi } from 'src/environments/ModelosApis/InfoApi';
import { IListaEndpointsService } from './Ports/i-lista-endpoints.service';
import { TiposConfiguracion } from 'src/environments/Configuration/TiposConfiguracion';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor( @Inject('ListaEndpints') private endpoints: IListaEndpointsService,) { }


  private get UrlBase():string{
    return localStorage.getItem("UrBlase")
  }
  public get UrlLogin(){
    //Solucion temporal para actibar rebandig
    if(environment.LoginUniversal==false && environment.Configuracion == TiposConfiguracion.V3){
        return environment.ApiUrlV2+'api/v2/Auth/LoginV3Loc'
    }else{
      return this.montaUrl('ApiLogin');
    }



  }
  public get UrlRefreshToken(){
    return this.montaUrl('ApiRefreshToken')
  }
  public get UrlLogOut(){
    return this.montaUrl('ApiLogOut')
  }
  public get UrlGetTipoEnvio(){
    return this.montaUrl('ApiGetTipoEnvio');
  }
  public get UrlAltaTipoEnvio(){
    return this.montaUrl('ApiAltaTipoEnvio');
  }
  public get UrlGetTipoEnvioId(){
    return this.montaUrl('ApiGetTipoEnvioId');
  }
  public get UrlGetTipoDocumento(){
    return this.montaUrl('ApiGetTipoDocumento');
  }
  public get UrlAltaTipoDocumento(){
    return this.montaUrl('ApiAltaTipoDocumento');
  }
  public get UrlGetTipoDestinatarios(){
    return this.montaUrl('ApiGetTipoDestinatarios');
  }
  public get UrlGetTipoBusquedas(){
    return this.montaUrl('ApiGetTipoBusquedas');
  }
  public get UrlGetTipoFirmantes(){
    return this.montaUrl('ApiGetTipoFirmantes');
  }
  public get UrlGetTipoDirecciones(){
    return this.montaUrl('ApiGetTipoDirecciones');
  }
  public get UrlDescargarFirmas(){
    return this.montaUrl('ApiDescargarFirmas');
  }
  public get UrlFirmaGetDocumentos(){
    return this.montaUrl('ApiFirmaGetDocumentos');
  }
  public get UrlFirmaDownloadDocument(){
    return this.montaUrl('ApiFirmaDownloadDocument');
  }
  public get UrlFirmaDescargaMasiva(){
    return this.montaUrl('ApiFirmaDescargaMasiva');
  }
  public get UrlSubidaSubirFichero(){
    return this.montaUrl('ApiSubidaSubirFichero');
  }
  public get UrlSubidaProcesaSubida(){
    return this.montaUrl('ApiSubidaProcesaSubida');
  }
  public get UrlSubidaCancelaSubida(){
    return this.montaUrl('ApiSubidaCancelaSubida');
  }
  public get UrlSubidaGetLog(){
    return this.montaUrl('ApiSubidaGetLog');
  }
  public get UrlSubidaCargaExcelMasivo(){
    return this.montaUrl('ApiSubidaCargaExcelMasivo');
  }
  public get UrlSubidaCreaSubida(){
    return this.montaUrl('ApiSubidaCreaSubida');
  }
  public get UrlSubidaAddFichero(){
    return this.montaUrl('ApiSubidaAddFichero');
  }
  public get UrlSubidaGetIdActiva(){
    return this.montaUrl('ApiSubidaGetIdActiva');
  }
  public get UrlCancelaSubidaAuto(){
    return this.montaUrl('ApiCancelaSubidaAuto');
  }
  public get UrlSubidaGetCabeceraExcelConfigurada(){
    return this.montaUrl('ApiSubidaGetCabeceraExcelConfigurada');
  }
  //se sigue usando? hay doble / en la url
  public get UrlEmpleadosGetExcelConfiguration(){
    return this.montaUrl('ApiEmpleadosGetExcelConfiguration');
  }
  //se sigue usando? hay doble / en la url
  public get UrlEmpleadoDeleteEmpleados(){
    return this.montaUrl('ApiEmpleadoDeleteEmpleados');
  }
  public get UrlEmpleadoAddEmpleados(){
    return this.montaUrl('ApiEmpleadoAddEmpleados');
  }
  public get UrlManuales(){
    return this.montaUrl('ApiManuales');
  }
  public get UrlUserValidEnvioTest(){
    return this.montaUrl('ApiUrlUserValidEnvioTest');
  }
  public get UrGetEstadoSubidaExcel(){
    return this.montaUrl('GetEstadoSubidaExcel');
  }
  public get UrlGetSubidasPendientesExcel(){
    return this.montaUrl('GetSubidasPendientesExcel');
  }
  public get UrlApiSubeFicheroTemporal(){
    return this.montaUrl('ApiSubeFicheroTemporal');
  }

  public get UrlGetUrlFileTemporal(){
    return this.montaUrl('GetUrlFileTemporal');
  }

  public get UrlGetTiposContenido(){
    return this.montaUrl('GetTiposContenido');
  }

  public get UrlGetCifDocumento(){
    return this.montaUrl('GetCifDocumento');
  }

  public get UrlGetNifDocumento(){
    return this.montaUrl('GetNifDocumento');
  }

  public get UrlGetUsuariosTipo(){
    return this.montaUrl('GetUsuariosTipo');
  }

  public get UrlGetEstadosUsuario(){
    return this.montaUrl('GetEstadosUsuario');
  }

  public get UrlGetEmpresas(){
    return this.montaUrl('GetEmpresas');
  }

  public get UrlGuardaEmpresa(){
    return this.montaUrl('GuardaEmpresa')
  }

  public get UrlDeleteEmpresa(){
    return this.montaUrl('DeleteEmpresa')
  }

  public get UrlGetCentros(){
    return this.montaUrl('GetCentros');
  }

  public get UrlLoginA3Api(){
    return this.montaUrl('LoginA3Api');
  }

  public get UrlGuardaCentro(){
    return this.montaUrl('GuardaCentro');
  }
  public get UrlGuardaCentros(){
    return this.montaUrl('GuardaCentros');
  }
  public get UrlGetUsers(){
    return this.montaUrl('GetUsers');
  }

  public get UrlGuardaUser(){
    return this.montaUrl('GuardaUser');
  }

  public get UrlIsModuloRltActivo(){
    return this.montaUrl('IsModuloRltActivo');
  }

  public get UrlGetConfiguracionRlt(){
    return this.montaUrl('GetCofiguracionRlt');
  }

  public get UrlGetIdTipoEnvioRlt(){
    return this.montaUrl('GetIdTipoEnvioRlt');
  }

  public get UrlGuardaConfiguracionRlt(){

    return this.montaUrl('GuardaConfiguracionRlt');
  }

  public get UrlDeleteCentro(){
    return this.montaUrl('DeleteCentro');
  }

  public get UrlGetLoginAzureAdUrl(){
    if(environment.DebugMode && environment.LoginUniversal){
      return environment.ApiUrlV3+'api/Authentication/GetLoginAzureAdLocal';
    }else{
      return environment.ApiUrlV3+'api/Authentication/GetLoginAzureAd';
    }

  }

  public get UrlLoginAzureAd(){
    return environment.ApiUrlV3+'api/Authentication/LoginAzureAd';
  }

  public get UrlInvitaUsuario(){
    return environment.ApiUrlV3+'api/Users/InvitaUsuario';
  }

  public get UrlActivaUsuario(){
    return environment.ApiUrlV3+'api/Users/ActivaUsuario/{login}';
  }

  public get UrlDesactivaUsuario(){
    return environment.ApiUrlV3+'api/Users/DesactivaUsuario/{login}';
  }

  public get UrlDeleteUsuario(){
    return environment.ApiUrlV3+'api/Users/DeleteUser/{login}';
  }

  public get UrlResetPasswordAzureAd(){
    return environment.ApiUrlV3+'api/Users/GetResetPasswordAzureAd';
  }
  public get UrlUpdateUser(){
    return environment.ApiUrlV3+'api/Users/UpdateUser/{tenant}';
  }
  public get UrlGetTiposContenidoRLT(){
    return environment.ApiUrlV3+'api/TipoContenido/GetTiposContenidoRLT';
  }
  public get UrlGetEmpleadosData(){
    return this.montaUrl('GetEmpleadosData');
  }
  public get UrlUpdateEmpleado(){
    return this.montaUrl('UpdateEmpleado');
  }
  public get UrlDeleteEmpleado(){
    return this.montaUrl('DeleteEmpleado');
  }
  public get UrlRenviaInvitacion(){
    return environment.ApiUrlV3+'api/Users/ReenviaInvitacion';
  }
  public get UrlGetConfiguracionAplicacion(){
    return environment.ApiUrlV3+'api/ConfiguracionAplicacion/GetConfiguracionAplicacion';
  }
  // Endpoints acceso datos firma migrados a API V3
  public get UrlFirmaElimina(){
    return this.montaUrl('ApiFirmaElimina');
  }
  public get UrlEliminaFirmas(){
    return this.montaUrl('ApiEliminaFirmas');
  }
  public get UrlFirmaCancela(){
    return this.montaUrl('ApiFirmaCancela');
  }
  public get UrlGetFirmas(){
    return this.montaUrl('ApiGetFirmas');
  }
  public get UrlFirmaGetEventos(){
    return this.montaUrl('ApiFirmaGetEventos');
  }
  public get UrlFirmaGetTiposDocumentos(){
    return this.montaUrl('ApiFirmaGetTiposDocumentos');
  }
  public get UrlGetModulosActivos(){
    return environment.ApiUrlV3+'api/ConfiguracionAplicacion/GetModulosActivos';
  }
  public ActBaseUrl(ruta:string){
    //if(!ruta) ruta = environment.ApiUrl;
    //localStorage.setItem("UrBlase",ruta);
  }
  private RecuperamosEdpoint(nombre:string):InfoApi{

    const endpoint = this.endpoints.Endpoints.find(x=>x.nombre==nombre);

    if(!endpoint){
      throw `El endpoint ${nombre} no esta definido`
    }else{

      return endpoint;
    }
  }
  private RecuperamosBaseUrlEndpoint(endpoint:InfoApi):string{
    if(environment.Configuracion==TiposConfiguracion.V3 && environment.LoginUniversal==false){
      if(endpoint.version=="V2"){
        return environment.ApiUrlV2;
      }else{
        return environment.ApiUrlV3;
      }
    }
    if(endpoint.nombre=='ApiGetTipoDocumento'){

    }
    if(environment.DebugMode && environment.LoginUniversal){
        if(endpoint.version=="V2"){
          if(endpoint.nombre=='ApiGetTipoDocumento'){

          }
          return environment.ApiUrlV2;
        }else{
          if(endpoint.nombre=='ApiGetTipoDocumento'){

          }
          return environment.ApiUrlV3;

        }
    }else{

      return endpoint.baseUrl
    }


    // if(endpoint.version=='V2'){
    //   return environment.ApiUrlV2;
    // }else{
    //   return environment.ApiUrlV3;
    // }

  }
  private RecuperamosDireccionEndpoint(endpoint:InfoApi):string{
    if(endpoint.version=='V2'){
      return endpoint.endpointV2;
    }else{
      return endpoint.endpointV3;
    }

  }
  private montaUrl(apiName:string):string{

    let endpointApi = this.RecuperamosEdpoint(apiName);

    let baseUrl = this.RecuperamosBaseUrlEndpoint(endpointApi);
    let endpintValue = this.RecuperamosDireccionEndpoint(endpointApi);

    return baseUrl+endpintValue;
  }
 }


