<div class="barra-flex-space-between">
  <h1>Configuración RLT</h1>
  <!-- <app-boton-accion [id]="'guardar'" [icono]="'hs-guardar'" [titulo]="'guardar'" [outline]="false" (click)="guardaConfiguracionRlt()"></app-boton-accion> -->
</div>
<div class="main">
  <form [formGroup]="datosConfiguracionAplicacionService.configuracionRltForm">
  <div class="switch" style="margin-left: -15px">
    <app-selector [textoOpcion1]="''" [textoOpcion2]="'Activado'" [active]="activo.value" [control]="'estado'" (mostrarSeleccion)="toggleActivado($event)"></app-selector>
  </div>
  <div class="margin-btm">
    <h6>Configuración del envío</h6>
    <label for="dosier">Dosier</label>
    <br>
    <!-- <select id="" formControlName="dosier">
      <option *ngFor="let envio of enviosData"
      [value]="envio.nombre"
      >{{envio.nombre}}</option>
    </select> -->
    <input type="text" formControlName="dosier" [attr.disabled]="true">
    <!-- <div #dropDownDiv class="container-drop-down">
      <input type="text" id="" formControlName="dosier" (click)="abrirMenu()" readonly>
      <span class="icon-arrow" (click)="abrirMenu()">
        <svg><use xlink:href="#icon-arrow"></use></svg>
      </span>
      <div *ngIf="mostrarLista" class="dropDown" >
        <div class="buscador">
          <app-input-buscador (buscador)="filtrarDosier($event)" [inputWidth]="'180'"></app-input-buscador>
        </div>
        <ul class="innerDropDown">
          <li *ngFor="let envio of enviosData" [value]="envio.nombre" (click)="getEnvioSeleccionado(envio.nombre)">{{envio.nombre}}</li>
        </ul>
      </div>
    </div> -->
    <span class="texto-validacion" *ngIf="validacionesFormularioRltService.isCampoValidoDosier()">
      {{validacionesFormularioRltService.mensajeErrorDosier}}
    </span>
  </div>

    <div>
      <h6>Planificación del envío</h6>
      <div class="margin-btm">
        <label for="periodicidad">Periodicidad</label>
        <br>
        <select id="" formControlName="periodicidad">
          <option value="semanal"
                >Semanal</option>
          <option value=""
                ></option>
      </select>
      <br>
      <span class="texto-validacion" *ngIf="validacionesFormularioRltService.isCampoValidoPeriodicidad()">
        {{validacionesFormularioRltService.mensajeErrorPeriodicidad}}
      </span>
      </div>
      <div class="margin-btm">
        <label for="diaEnvio">Día del envío</label>
        <br>
        <select id="" formControlName="diaEnvio">
          <option *ngFor="let dia of diasSemana"
          [value]="dia"
          >{{dia}}</option>
      </select>
      <br>
      <span class="texto-validacion" *ngIf="validacionesFormularioRltService.isCampoValidoDiaEnvio()">
        {{validacionesFormularioRltService.mensajeErrorDiaEnvio}}
      </span>
      </div>
      <div class="margin-btm">
        <label for="horaEnvio">Hora del envío</label>
        <br>
        <select id="" formControlName="horaEnvio">
          <option *ngFor="let hora of times"
          [value]="hora"
          >{{hora}}</option>
        </select>
      <br>
      <span class="texto-validacion" *ngIf="validacionesFormularioRltService.isCampoValidoHoraEnvio()">
        {{validacionesFormularioRltService.mensajeErrorHoraEnvio}}
      </span>
      </div>
    </div>
  </form>
</div>
<div class="guardar-btn">
  <app-boton-accion class="guarda-rlt" [id]="'guardar'" [icono]="'hs-guardar'" [titulo]="'guardar'" [outline]="false" (click)="guardaConfiguracionRlt()"></app-boton-accion>
</div>
