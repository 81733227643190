<div class="layout-menu">
  <img [src]="logo" alt="image" class="logo" (click)="goHome()">
  <button class="header-button ayuda" (click)="abrirFreshWorks()">
      <span class="header-button-text-ayuda" >Ayuda</span>
      <span class="header-button-icon">
        <svg class="icon-help">
          <use xlink:href="#hs-help"></use>
        </svg>
      </span>
  </button>
  <button class="header-button" #toggleButton>
    <div class="flex-conf" #flexConf>
      <span class="header-button-icon" #icon>
        <svg class="icon-login" #iconSvg>
          <use xlink:href="#hs-user" #iconUse></use>
        </svg>
      </span>
      <div class="header-button-text" #nameHeader>
        <span id="nameLogin" class="child" style="font-size: 13px;" #name>{{getNameLogin()}}</span>
        <br>
        <span id="nameEntorno" class="child" style="font-size: 10px;" #entorno>{{nombreEntorno}}</span>
      </div>
    </div>
    <app-usuario-activo-sqp *ngIf="configuracion" (clickFuera)="cerrarMenu($event)" [verConfigUsuario]="verConfiguracion" [tenantDataArray]="loginService.getTenantToken().tenants"></app-usuario-activo-sqp>

  </button>
</div>
