import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuariosFilterDetailsComponent } from './components/usuarios-filter-details/usuarios-filter-details.component';
import { UsuariosModalComponent } from './components/usuarios-modal/usuarios-modal.component';
import { UsuariosPageComponent } from './components/usuarios-page/usuarios-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UsuariosModalSeguridadComponent } from './components/usuarios-modal-seguridad/usuarios-modal-seguridad.component';
import { GridSeguridadEmpresasComponent } from './components/grid-seguridad-empresas/grid-seguridad-empresas.component';
import { GridSeguridadCentrosComponent } from './components/grid-seguridad-centros/grid-seguridad-centros.component';
import { GridSeguridadTiposEnvioComponent } from './components/grid-seguridad-tipos-envio/grid-seguridad-tipos-envio.component';
import { UsuarioActivoPageComponent } from './components/usuario-activo/usuario-activo-page/usuario-activo-page.component';
import { UsuarioActivoMenuComponent } from './components/usuario-activo/usuario-activo-menu/usuario-activo-menu.component';
import { UsuarioActivoConfComponent } from './components/usuario-activo/usuario-activo-conf/usuario-activo-conf.component';
import { UsuarioActivoResetPwdModalComponent } from './components/usuario-activo/usuario-activo-reset-pwd-modal/usuario-activo-reset-pwd-modal.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';



@NgModule({
  declarations: [
    UsuariosFilterDetailsComponent,
    UsuariosModalComponent,
    UsuariosPageComponent,
    UsuariosModalSeguridadComponent,
    GridSeguridadEmpresasComponent,
    GridSeguridadCentrosComponent,
    GridSeguridadTiposEnvioComponent,
    UsuarioActivoPageComponent,
    UsuarioActivoMenuComponent,
    UsuarioActivoConfComponent,
    UsuarioActivoResetPwdModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDialogModule,
    SqpComponentsModule
  ]
})
export class SeguridadModule { }
