<div class="mainPanel">
  <div class="flex-acciones-secundarias">
    <div>
      <h1 class="main-title">{{titulo}}</h1>
    </div>
    <div *ngIf="mostrarEntornoSandbox" class="acciones-secundarias"></div>
  </div>
  <div class="content">
    <div class="panelLateral">
      <app-stepper class="stepper" [elementos]="datosSubidaService.pasos" [stepActive]="datosSubidaService.stepActive" (ocultoEventEmitter)="ocultar($event)" [finalizarProceso]="finalizarProceso">
      </app-stepper>
      <div class="selector-sandbox">
        <app-selector [forumlario]="form" [control]="'entornoSandbox'" [active]="entornoSandbox.value" [textoOpcion1]="'SANDBOX'" [iconoOpcion1]="'hs-sandbox2'" (mostrarSeleccion)="selectIsEnvioEntornoSandbox($event)"></app-selector>
      </div>
    </div>
    <div class="contenido-dosier">
      <h6 *ngIf="!datosSubidaService.faseSeleccionMetodo" >Dosier a procesar</h6>
      <app-selector-tipo-subida *ngIf="datosSubidaService.faseSeleccionMetodo" (tipoSubidaSelected)="selectTipoSubida($event)"></app-selector-tipo-subida>
      <app-selector-tipo-envio *ngIf="datosSubidaService.faseSeleccionTipoEnvio" [tiposEnvioCargados]="tiposEnvioCargados" (tipoEnvioSelected)="selectTipoEnvio($event)" [tiposEnvio]="tiposEnvio" [isSandbox]="entornoSandbox.value"></app-selector-tipo-envio>
      <app-subida-archivo *ngIf="datosSubidaService.faseSubidaExcel ||datosSubidaService.faseSubidaFichero" (fileListEmitter)="uploadFiles($event)" [porcentajeSubida]="porcentajeSubida" [detallesSubida]="detallesSubida" [isSandbox]="entornoSandbox.value"></app-subida-archivo>
      <app-procesamiento *ngIf="datosSubidaService.faseProceso" [errorSubida]="errorSubida" [isSandbox]="entornoSandbox.value"></app-procesamiento>
    </div>
  </div>
</div>
<div  *ngIf="!datosSubidaService.faseSeleccionMetodo" class="footer">
  <app-boton-accion-secundaria (click)="atras()" [id]="'atrás'" [icono]="'hs-arrow-back'" [titulo]="'volver'"></app-boton-accion-secundaria>
    <div class="botonera-rigth">
        <app-boton-accion *ngIf="datosSubidaService.faseSubidaExcel" [icono]="'hs-download'" [titulo]="'Plantilla de Firmantes'" (botonClicked)="descagaFormatoExcel()"></app-boton-accion>
        <app-boton-accion [icono]="datosSubidaService.faseProceso ? '':'icon-close'" [titulo]="datosSubidaService.faseProceso ? 'Finalizar':'Cancelar'" (botonClicked)="cancelar()" [outline]="datosSubidaService.faseProceso ? false : true" [cancelar]="datosSubidaService.faseProceso ? false : true"></app-boton-accion>
    </div>
</div>
