import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fileSaver from 'file-saver';
import { DatosExcel } from '../models/datos-excel';

@Injectable({
  providedIn: 'root'
})
export class ExportadorExcelService {
  workbook: any = {};
  worksheet: any = {};
  private columns = ['A','B','C','D','E','F','G','H','I','J','K','L','M', 'N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  columnaIncio: string= "A1" ;
  columnasExcel: any[] = [];

  constructor() { }

  exportaExcel(configuracion:DatosExcel, rows: any[]){
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet(configuracion.workSheetName);
    this.columnasExcel = this.CargaColumnas(configuracion);
    this.AnchoColumna();
    this.worksheet.addTable({
      name: configuracion.workSheetName,
      ref: this.columnaIncio,
      headerRow: true,
      style: {
        // theme: 'TableStyleMedium2',
        showRowStripes: true,
      },
      columns: this.columnasExcel,
      rows: rows,
    });

    this.worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      // Set background color based on row number
      if(rowNumber===1){
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'F7C548'},
          }
          cell.font = {
            color: { argb: '070E28' }
          }
        });

      }
      if (rowNumber % 2 === 0) {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFAEF' },
          };
        });
      }
    });

    this.workbook.xlsx.writeBuffer().then((data:any) =>
    {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fileSaver.saveAs(blob, configuracion.nombreFichero);
    });
  }

  // Cargamos las columnas del excel basandonos en las del grid
  CargaColumnas(configuracion: DatosExcel):any[]{
    let columns:any[] = [];
      configuracion.columnas.forEach((x, i) => {
        x.ColumnExcel = this.columns[i];
        columns.push(x);
    });
    return columns;
  }

  // Cargar el ancho de las columnas
 AnchoColumna(){
  this.columnasExcel.forEach(x=>{
    let col = this.worksheet.getColumn(x.ColumnExcel);
    col.width = (x.width/10);
  });
}
}
