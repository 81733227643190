import { Injectable } from '@angular/core';
import { IMenuLateralService } from './ports/iMenuLateralService';
import { VentanasAbiertas } from 'src/app/pages/menuPrincipal/model/ventanas-abiertas';
import { Router } from '@angular/router';
import { FormularioDocumentoService } from 'src/app/pages/configuracion/Envios/documentos/services/formulario-documento.service';
import { MatDialog } from '@angular/material/dialog';
import { FormularioEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/formulario-envio.service';
import { TipoDocumentoClass } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { TipoEnvioClass } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';

@Injectable({
  providedIn: 'root'
})
export class MenuLateralService implements IMenuLateralService{

  gestorVentanas:VentanasAbiertas;

  constructor(private router: Router,
              private formularioDocumentoService: FormularioDocumentoService,
              private dialog: MatDialog,
              private formularioEnvioService: FormularioEnvioService) {
                this.gestorVentanas = new VentanasAbiertas(router, formularioDocumentoService, dialog, formularioEnvioService);
               }

  puedoIrMenu(ruta: string): Promise<boolean> {
    return new Promise((resolve) => {
      const ventana = this.gestorVentanas.ventanaAbierta();
      if (ventana === '') {
        resolve(true);
      } else {
        this.gestorVentanas.accionVentanaAbierta(ventana, ruta).subscribe(result => {
          if(result === 'OK'){
            if(ventana=='configuracion-documento') this.reseteaFormularioDocumento();
            if(ventana=='configuracion-dosier') this.reseteaFormularioDosier();
            resolve(true)
          }else resolve(false);
        })
      }
    })
  }

  reseteaFormularioDocumento(){
    this.formularioDocumentoService.resetFormularioDocumento();
    this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
  }

  reseteaFormularioDosier(){
    this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
  }
}
