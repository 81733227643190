<div class="barra-flex-space-between">
  <h1>Dosieres configurados</h1>
  <div class="acciones">
    <app-envios-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-envios-barra-superior>
    <app-boton-accion [id]="'nuevo dosier configurado'" [icono]="'icon-plus'" [titulo]="'nuevo dosier'" [outline]="false" (click)="configuraNuevoEnvio()"></app-boton-accion>
  </div>
</div>
<div class="main">
  <div class="barra-flex-space-between">
    <div class="flex-center-items">
      <app-input-buscador (buscador)="buscarEnvios($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
      <app-envios-filtros-nuevos></app-envios-filtros-nuevos>
    </div>
  </div>
  <app-sqp-grid-component [data]="datosEnviosService.enviosFiltrados"
                          (refresh)="getEnviosData()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'single'"
                          (abrirModalDataEmitter)="abrirDetalleDosier($event)"
                          [gridOptions]="gridOptions"></app-sqp-grid-component>
</div>
