import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubidaPrincipalComponent } from './components/subida-principal/subida-principal.component';
import { SelectorTipoEnvioComponent } from './components/selector-tipo-envio/selector-tipo-envio.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { PanelControlModule } from '../panelControl/panel-control.module';
import { MenuPrincipalModule } from '../menuPrincipal/menu-principal.module';
import { SelectorTipoSubidaComponent } from './components/selector-tipo-subida/selector-tipo-subida.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { SubidaArchivoComponent } from './components/subida-archivo/subida-archivo.component';
import { InfoDosierComponent } from './components/info-dosier/info-dosier.component';
import { InstruccionesSubidaComponent } from './components/instrucciones-subida/instrucciones-subida.component';
import { ProcesamientoComponent } from './components/procesamiento/procesamiento.component';
import { AgGridModule } from 'ag-grid-angular';
import { AvanceSubidaComponent } from './components/avance-subida/avance-subida.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';



@NgModule({
  declarations: [
    SubidaPrincipalComponent,
    SelectorTipoEnvioComponent,
    SelectorTipoSubidaComponent,
    StepperComponent,
    SubidaArchivoComponent,
    InfoDosierComponent,
    InstruccionesSubidaComponent,
    ProcesamientoComponent,
    AvanceSubidaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    PanelControlModule,
    MenuPrincipalModule,
    AgGridModule,
    SqpComponentsModule
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class SubidaPrincipalModule { }
