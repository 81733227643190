import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatosEmpleadosService } from '../../../services/mantenimiento/datos-empleados.service';
import { EmpleadoCrud } from '../../../models/empleado';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { ColDef } from 'ag-grid-community';
import { GridColDefsEmpleados } from '../../../models/grid-col-defs-empleados';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { FiltroBasico } from 'src/app/shared/models/filtros';
import { EmpleadosModalComponent } from '../empleados-modal/empleados-modal.component';
import { EmpresasModel } from 'src/app/pages/configuracion/maestros/empresas/models/empresas-model';
import { CentrosModel } from 'src/app/pages/configuracion/maestros/centros/models/centros-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';
import { DesplegableData } from 'src/app/shared/components/sqp-components/sqpDesplegable/models/desplegable-data';

@Component({
  selector: 'app-empleados-page',
  templateUrl: './empleados-page.component.html',
  styleUrls: ['./empleados-page.component.css']
})
export class EmpleadosPageComponent implements OnInit {

  empleados: EmpleadoCrud[];
  columnDefs: ColDef[] = new GridColDefsEmpleados(this.datosEmpleadosService).columnDefs;
  filtros: FiltroBasico[] = [];
  empresas: EmpresasModel[];
  centros: CentrosModel[];
  textoBuscar: string = '';
  empresasLista: DesplegableData[];
  centrosLista: DesplegableData[];
  limpiarEstados: {num:number, clear: boolean};
  textoBorrar: string;
  empleadosSeleccionados: EmpleadoCrud[] = [];
  empleadosIniciales: EmpleadoCrud[];

  constructor(private datosEmpleadosService: DatosEmpleadosService,
              private dialog: MatDialog,
              private exportadorPdfService: ExportadorPdfService,
              private exportadorExcelService: ExportadorExcelService,
              private router: Router,
              private cdRef: ChangeDetectorRef
              ) {  this.datosEmpleadosService.getEmpleados().subscribe(
                next => {
                  this.empleados = next;
                  this.empleadosIniciales = [...next];
                  this.empresasLista  = this.datosEmpleadosService.getEmpresaLista();
                  this.centrosLista  = this.datosEmpleadosService.getCentrosLista();
                },
                error => {
                  console.error();
                }
              )}
              // private activatedRoute: ActivatedRoute

  ngOnInit(): void {
    // Para acceder a los datos del resolve. Aquí no es necesario. Lo dejo como ejemplo
    // const resolvedData = this.activatedRoute.snapshot.data.data;

  }

  getEmpleados(){
    this.datosEmpleadosService.getEmpleados().subscribe(
      next => {
        this.empleados = next;
      },
      error => {
        console.error();
      }
    )
  }

  // getConfiguracionAplicacion(){
  //   this.datosEmpleadosService.getConfiguracionAplicacion().subscribe(
  //     next => {
  //       if(next.TipoIntegracion == 'Meta4Cloud') {
  //         this.datosEmpleadosService.consultaEmpleados = true;
  //       }
  //     },
  //     error => {
  //       console.error();
  //     }
  //   )
  // }

  clickBarraSuperior(id: string){
    switch (id) {
      case 'Excel':
        this.exportaExcel();
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      default:
        break;
    }
  }

  exportaPdf(){
    if(this.empleados){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: '<p>Genera un documento PDF con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        }
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK'){
          let empleadosExportar = [];
          empleadosExportar = [...this.empleados];
          empleadosExportar.map(x=>{
            x.nombreEmpresa = `${x.codigoEmpresa} - ${x.nombreEmpresa}`;
            x.nombreCentro = `${x.codigoCentro} - ${x.nombreCentro}`;
          })
          this.exportadorPdfService.exportaPdf(datos, empleadosExportar)
          //volvemos a dejar estos atributos como estaban al principio para que no se vayan duplicando cada vez que descargamos pdf o excel
          this.empleados.forEach(x=>{
            x.nombreEmpresa = x.nombreEmpresa.split(' - ')[1];
            x.nombreCentro = x.nombreCentro.split(' - ')[1];
          })
        }
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let columns: any [] = [];
    this.columnDefs.forEach( x => {
      columns.push( {header: x.headerName, dataKey: x.field} )
    })
    let fecha = new Date();
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_empleados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  exportaExcel(){
    if(this.empleados){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: '<p>Genera un documento Excel con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        }
      });

      let rows = this.GetRows(this.empleados);
      this.empleados.forEach(x=>{
        x.nombreEmpresa = x.nombreEmpresa.split(' - ')[1];
        x.nombreCentro = x.nombreCentro.split(' - ')[1];
      })
      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorExcelService.exportaExcel(datosExcel, rows);
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Empleados',
      nombreFichero:`Hire&Sign_empleados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumns()
    }
    return datosExcel
  }

  //Generamos las columnas basandonos en las del grid
  GetColumns(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach(x => {
      if(x.headerName!==''){
        let element:any = {};
        element.name = x.headerName;
        element.filterButton = true;
        element.width = x.width;
        columns.push(element);
      }

    });
    return columns;
  }

  // Mapeamos los datos de las empresas a las filas del excel
  GetRows(data:EmpleadoCrud[]):any[]{
    let rows:any[] = [];
    data.forEach(x=>{
      let row:any[] =
      [
        x.codigo,
        x.nombre,
        x.apellido1,
        x.apellido2,
        x.nif,
        x.nombreEmpresa = `${x.codigoEmpresa} - ${x.nombreEmpresa}`,
        x.nombreCentro = `${x.codigoCentro} - ${x.nombreCentro}`
      ];
      rows.push(row);
    });
    return rows
  }

  creaNuevoEmpleado(){
    let info: any = {};
    info.empleado={} as EmpleadoCrud;
    info.nuevo = true;
    // info.empleados = this.empleados;
    info.empleados = this.empleadosIniciales;
    const dialogRef = this.dialog.open(EmpleadosModalComponent, {
      data: info,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();

      }
    })
  }

  refresh(){
    this.textoBuscar = '';
    this.cdRef.detectChanges();
    this.getEmpleados();
  }

  goToImportEmpleados(){
    this.router.navigate(['/panel/empleados']);
  }

  filtrarEmpleados(event: boolean){
    if(this.empleados && event){
      this.empleados = this.datosEmpleadosService.empleadosFiltrados;

    }
  }

  buscarEmpleados(textoBuscar: string){
    if(this.empleados){
      this.textoBuscar = textoBuscar;
      this.cdRef.detectChanges(); //detectamos el cambio de la búsqueda aquí para que no nos salga el error "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked." al mostrar los empleados filtrados.
    }
  }

  limpiarTodosEstados(event:{num:number, clear: boolean}){
    this.limpiarEstados = event;
  }

  deleteEmpleado(){
    let textoEliminar = this.empleadosSeleccionados.length>1? `${this.empleadosSeleccionados.length} empleados`:`${this.empleadosSeleccionados.length} empleado`
    const dialogRefEliminar = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
        icono: 'icon-delete',
        titulo: this.empleadosSeleccionados.length>1?'¿Eliminar empleados?':'¿Eliminar empleado?',
        // preguntaConfirmacion: '¿Eliminar centro?',
        textoConfirmacion: this.empleadosSeleccionados.length>1?'Eliminar los empleados seleccionados':'Eliminar el empleado seleccionado',
        textoBoton: 'Eliminar',
        iconoConfirmacion: 'icon-delete'
      }
    });
    dialogRefEliminar .afterClosed().subscribe(result => {
      if(result === 'OK'){
      const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
      let count: number = 0;
      this.empleadosSeleccionados.forEach(x=>{
        this.datosEmpleadosService.deleteEmpleado(x.codigo).subscribe(
          ok => {
            count++
            if(this.empleadosSeleccionados.length == count){
              dialogRefSpinner.close();
              const dialogRef = this.dialog.open(ModalMessageComponent, {
                data: {
                  mensaje: this.empleadosSeleccionados.length==1 ? 'Empleado eliminado correctamente':'Empleados eliminados correctamente',
                }
              });
              this.datosEmpleadosService.refreshEmpleados = true;
              this.refresh();
            }
          },
          error => {
            dialogRefSpinner.close();
            console.error("error", error);
            let textoError = this.empleadosSeleccionados.length>1? `${this.empleadosSeleccionados.length} empleados`:`${this.empleadosSeleccionados.length} empleado`
            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: {
                mensaje: this.empleadosSeleccionados.length==1 ? 'Error. El empleado no se ha eliminado':'Error. Los empleados no se han eliminado',
                alert: true
              }
            });
          }
        );
      })
      }
    })

  }

  seleccionEmpleados(event: EmpleadoCrud[]){
    this.empleadosSeleccionados = event;
    if(this.empleadosSeleccionados.length > 1) this.textoBorrar = `${event.length} empleados seleccionados`;
    else this.textoBorrar = `${event.length} empleado seleccionado`;
  }

  deseleccionar: {count: number, uncheck: boolean}
  num: number = 1;
  deseleccionarAll(){
    this.deseleccionar = {count: this.num++, uncheck: true}
  }

  abreModal(empleado: any){
    let info: any = {};
    info.empleado = empleado.data;
    // info.empleados = this.empleados;
    info.empleados = this.empleadosIniciales;
    info.nuevo = false;
    const dialogRef = this.dialog.open(EmpleadosModalComponent, {
      data: info,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();
      }
    })

  }
}
