// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import { TiposConfiguracion } from "./Configuration/TiposConfiguracion";


export const environment = {
  production: false,
  ApiUrlV2: 'https://sqpfirmapreapiv2.azurewebsites.net/',
  ApiUrlV3:'https://hysv3api.hireandsign.com/',
  // ApiUrl: 'https://hysv3temp-development.azurewebsites.net/',
  ApiFicherosUrl: 'https://hysv3api.hireandsign.com/',
  //OL Refactor: Se Crea una variable para indicar si tabajamos con mocks o no
  Configuracion: TiposConfiguracion.V3,
  LoginUniversal:true,
  //OL Refactor: Cambiamos el nombre de todas las variables a APIXXXMOCK CABMAIARLO EN TODOS LOS ENVIROMENTS
  ApiEnviosMock:'../assets/mocks/envios-configurados-data.json',
  ApiEmpresasMock:'../assets/mocks/empresas-data.json',
  ApiCentrosMock:'../assets/mocks/centros-data.json',
  ApiDocumentosMock:'../assets/mocks/docs-configurados-data.json',
  ApiFirmanteMock:'../assets/mocks/tipo-firmante-data.json',
  ApiDestinatarioMock:'../assets/mocks/tipo-destinatario-data.json',
  ApiBusquedaMock:'../assets/mocks/tipo-busqueda-data.json',
  ApiMailMock:'../assets/mocks/tipo-mail-data.json',
  ApiEmpleadosExcelMock:'../assets/mocks/empleados-excel-data.json',
  ApiFirmasMock:'../assets/mocks/firmas-data.json',
  ApiInfoDocsMock:'../assets/mocks/info-docs-data.json',
  ApiInfoEventosMock:'../assets/mocks/info-eventos-data.json',
  ApiInfoTipoDocsMock:'../assets/mocks/info-tipo-docs-data.json',
  ApiTiposContenidoMock:'../assets/mocks/tipos-contenido-data.json',
  ApiUsuariosMock:'../assets/mocks/usuarios.json',
  ApiUsuariosEstadoMock:'../assets/mocks/usuarios-estado.json',
  ApiUsuariosTipoMock:'../assets/mocks/usuarios-tipo.json',
  ApiUsuariosSeguridadMock:'../assets/mocks/usuarios-seguridad.json',
  ApiUsuariosPermisosMock:'../assets/mocks/usuarios-permisos.json',
  ApiConfiguracionRlt:'../assets/mocks/configuracion-rlt.json',
  ApiEmpleadosDataMock:'../assets/mocks/empleados-data.json',
  PaquetesDescargaMasiva:10,
  versionDesarrollo: true,
  DebugMode:false

  //OL CVamos separando las Apis para el traspaso progresivo a NetCore
  // ApiLogin: new InfoApi({
  //   baseUrl : '',
  //    endpoint:'api/Authentication/Login'
  //   //endpoint:'api/v2/Auth/Login'

  // }),
  // ApiRefreshToken: new InfoApi({
  //   baseUrl : '',
  //   // endpoint:'api/Authentication/Login'
  //   endpoint:'api/v2/Auth/RefreshToken'

  // }),
  // ApiLogOut: new InfoApi({
  //   baseUrl : '',
  //   // endpoint:'api/Authentication/Login'
  //   endpoint:'api/v2/Auth/LogOut'

  // }),
  // ApiGetFirmas: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetFirmas/{todos}'
  //  }),
  //  ApiEliminaFirmas: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/EliminaFirmas'
  //  }),
  //  ApiDescargarFirmas: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/DownloadSignature/{id}'
  //  }),
  //  ApiFirmaGetDocumentos: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetDocumentos'
  //  }),
  //  ApiFirmaGetEventos: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetEventos'
  //  }),
  //  ApiFirmaGetTiposDocumentos: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetTiposDocumentoFirma'
  //  }),
  //  ApiFirmaDownloadDocument: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/DownloadDocument/{idFirma}/{idDocumento}'
  //  }),
  //  ApiFirmaElimina: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/EliminaFirma/{idFirma}'
  //  }),
  //  ApiFirmaCancela: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/CancelaFirma/{idFirma}'
  //  }),
  //  ApiFirmaDescargaMasiva: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GeneraDescargaMasiva'
  //  }),
  //  ApiSubidaSubirFichero: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/UploadAjax'
  //  }),
  //  ApiSubidaProcesaSubida: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/ProcesaSubida'
  //  }),
  //  ApiSubidaCancelaSubida: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/CancelaSubida/{id}'
  //  }),
  //  ApiSubidaGetLog: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetLogSubidaFicheros/{id}'
  //  }),
  //  ApiSubidaCargaExcelMasivo: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/CargaExcelMasivo'
  //  }),
  //  ApiSubidaCreaSubida: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/CreaSubida'
  //  }),
  //  ApiSubidaAddFichero: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/AddFicheroSubida'
  //  }),
  //  ApiSubidaGetIdActiva: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetIdActiva'
  //  }),
  //  ApiCancelaSubidaAuto: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/CancelaSubidaAuto/{id}'
  //  }),
  //  ApiSubidaGetCabeceraExcelConfigurada: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Signature/GetCabecerasExcelConfiguradas'
  //  }),
  //  ApiGetTipoEnvio: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TipoDosier/GetAllTipoDosier'
  //   }),
  //  ApiGetTipoDocumento: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/Documents/GetAllTipoDocumento'
  //  }),
  //  ApiGetTipoDestinatarios: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TiposEnvioDesplegables/GetTipoDestinatarios'
  //  }),
  //  ApiGetTipoBusquedas: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TiposEnvioDesplegables/GetTipoBusquedas'
  //  }),
  //  ApiGetTipoFirmantes: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TiposEnvioDesplegables/GetTipoFirmantes'
  //  }),
  //  ApiGetTipoDirecciones: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TiposEnvioDesplegables/TiposDirecciones'
  //  }),
  //  ApiAltaTipoEnvio: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TipoDosier/AltaTipoDosier'
  //  }),
  //  ApiGetTipoEnvioId: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/TipoDosier/GetTipoDosier/'
  //  }),
  //  ApiAltaTipoDocumento: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/Documents/AltaTipoDocumento'
  //  }),

  // // ApiGetTipoEnvio: new InfoApi({
  // //   baseUrl:'',
  // //   endpoint:'api/v2/TiposEnvios/GetTiposEnvios'
  // //  }),
  // ApiEmpleadosGetExcelConfiguration: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'/api/v2/Empleados/GetExcelConfiguration'
  // }),
  // ApiEmpleadoDeleteEmpleados: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'/api/v2/Empleados/DeleteEmpleados'
  // }),
  // ApiEmpleadoAddEmpleados: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'/api/v2/Empleados/AddEmpleados'
  // }),
  // ApiManuales: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/Manuales/GetManual'
  // }),
  // ApiUrlUserValidEnvioTest: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Auth/UserValidEnvioTest'
  // }),
  // GetEstadoSubidaExcel: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Empleados/GetEstadoSubida/{id}'
  // }),
  // GetSubidasPendientesExcel: new InfoApi({
  //   baseUrl:'',
  //   endpoint:'api/v2/Empleados/GetSubidaPendinte'
  // })

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
