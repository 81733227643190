import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';

@Component({
  selector: 'app-selector-tipo-subida',
  templateUrl: './selector-tipo-subida.component.html',
  styleUrls: ['./selector-tipo-subida.component.css']
})
export class SelectorTipoSubidaComponent implements OnInit {

  @Output() tipoSubidaSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(private breadcrumbsService: BreadcrumbsService) { }

  ngOnInit(): void {
  }

  selectTipoSubida(tipoSubida: string){
    if (tipoSubida.toUpperCase() == "ESTÁNDAR") {
      this.tipoSubidaSelected.emit('normal');
      this.breadcrumbsService.addBreadcrumbs('Envío estándar');
    }
    else {
      this.tipoSubidaSelected.emit('masiva');
      this.breadcrumbsService.addBreadcrumbs('Envío universal');
    }
  }

}
