<div class="main">
  <h2>Copiar configuración</h2>
  <form [formGroup]="formularioDocumentoService.formularioDocumento">
    <div class="input-copy">
      <input id="checkboxCopiarId" type="checkbox" formControlName="copyConfiguracion" (click)="abrirModalDocumentos()">
      <span>
        <svg class="icon-copy">
          <use xlink:href="#icon-copy"></use>
        </svg>
      </span>
      <label for="copyConfiguracion">Copiar la configuración de otro documento</label>
    </div>
    <div *ngIf="copiar" class="input-copy bg-yellow" id="docCopiado">
      <span>
        <svg class="icon-copy-checked">
          <use xlink:href="#icon-copy-checked"></use>
        </svg>
      </span>
      <span style="font-size: 14px">{{nombreDocCopiar}}</span>
      <span (click)="deleteCopy()" class="icon-delete">
        <svg >
          <use xlink:href="#hs-bin"></use>
        </svg>
      </span>
    </div>
  </form>
</div>


