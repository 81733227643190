import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoBusqueda } from 'src/app/pages/configuracion/Envios/envios/models/tipo-busqueda';
import { TipoDestinatario } from 'src/app/pages/configuracion/Envios/envios/models/tipo-destinatario';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { TipoFirmante } from 'src/app/pages/configuracion/Envios/envios/models/tipo-firmante';
import { TipoMail } from 'src/app/pages/configuracion/Envios/envios/models/tipo-mail';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { TiposConfiguracion } from 'src/environments/Configuration/TiposConfiguracion';
import { environment } from 'src/environments/environment';
import { IGestionarTiposEnvioService } from './ports/igestionar-tipos-envio-service';


@Injectable({
  providedIn: 'root'
})
//OL Refactor: Este servicio se encarga de recuperar los datos de tipos de envio de la api.
export class RecuperaTipoEnvioApiService implements IGestionarTiposEnvioService {

  private readonly URL_GET_TIPOS_ENVIO: string = this.endpointService.UrlGetTipoEnvio
  // private readonly URL_GET_TIPOS_ENVIO: string = `${environment.ApiUrl}api/TipoDosier/GetAllTipoDosier`;
  // private readonly URL_GET_TIPOS_ENVIO: string = `${environment.ApiUrl}api/v2/TiposEnvios/GetTiposEnvios`;
  private readonly URL_GET_TIPO_FIRMANTES: string = this.endpointService.UrlGetTipoFirmantes;
  // private readonly URL_GET_TIPO_FIRMANTES: string = `${environment.ApiUrl}api/TiposEnvioDesplegables/GetTipoFirmantes`;
  private readonly URL_GET_TIPO_BUSQUEDAS: string = this.endpointService.UrlGetTipoBusquedas;
  // private readonly URL_GET_TIPO_BUSQUEDAS: string = `${environment.ApiUrl}api/TiposEnvioDesplegables/GetTipoBusquedas`;
  private readonly URL_GET_TIPO_DESTINATARIOS: string = this.endpointService.UrlGetTipoDestinatarios
  // private readonly URL_GET_TIPO_DESTINATARIOS: string = `${environment.ApiUrl}api/TiposEnvioDesplegables/GetTipoDestinatarios`;
  private readonly URL_GET_TIPO_MAILS: string = this.endpointService.UrlGetTipoDirecciones;
  // private readonly URL_GET_TIPO_MAILS: string = `${environment.ApiUrl}api/TiposEnvioDesplegables/TiposDirecciones`;
  private URL_ALTA_TIPO_ENVIO: string = this.endpointService.UrlAltaTipoEnvio;
  private readonly URL_GET_TIPO_ENVIO_ID: string = this.endpointService.UrlGetTipoEnvioId;
  constructor(private client: HttpClient,
              private loginService: LoginService,
              private endpointService: EndpointService,
              private conversionDatosApiService: ConversionDatosApiService) { }

  envios: any[];
  envioObject: TipoEnvio;
  // enviosMap: any[];
  mails: TipoMail[];
  firmantes: TipoFirmante[];
  busquedas: TipoBusqueda[];
  destinatarios: TipoDestinatario[];

   //lo guardaremos en la variable this.tiposEnvo
   async getTipoEnvios(refresh?: boolean):Promise<void>{
    if(this.envios && !refresh){
      return
    } else{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    if(this.URL_GET_TIPOS_ENVIO.includes('api/v2/TiposEnvios/GetTiposEnvios')){
      await this.client.get(this.URL_GET_TIPOS_ENVIO, options).pipe(
        map((response: any) =>{
          if(response){
            this.envios = JSON.parse(response.Data)
          }else throw new Error(response.Message);
        })
      ).toPromise()
    }else{
      await this.client.get(this.URL_GET_TIPOS_ENVIO, options).pipe(
        map((response: any) =>{
          if(response){
            this.envios = this.conversionDatosApiService.mapEnviosEntrada(response);
            this.envios = this.conversionDatosApiService.convertir0ANull(this.envios);

            // this.envios.map(x => x.firmantes = this.conversionDatosApiService.mapFirmantesEntrada(x.firmantes)); //el map de firmantes se hace directamente en el servicio de mappings al llamar mapEnviosEntrada

            this.envios.forEach(x => {
              if(x.tiposDocumento.length == 0) x.activo = false;

              // x.tiposDocumento = this.conversionDatosApiService.mapDocumentosEntrada(x.tiposDocumento); //el map de documentos se hace directamente en el servicio de mappings al llamar mapEnviosEntrada
              x.mailPrincipal = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.mailPrincipal);
              x.ordenMail = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.ordenMail);
              x.ordenTelefonoSMS = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.ordenTelefonoSMS);
              x.ordenTelefonoOTP = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.ordenTelefonoOTP);
              // x.ordenMail = x.ordenMail ? this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.ordenMail): {codigo:'', desc:''};
              // x.ordenTelefonoSMS = x.ordenTelefonoSMS ? this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.ordenTelefonoSMS): {codigo:'', desc:''};
              // x.ordenTelefonoOTP = x.ordenTelefonoOTP ? this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(x.ordenTelefonoOTP): {codigo:'', desc:''};
              x.firmantes.map((y:any) => {
                y.tipoBusqueda = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(y.tipoBusqueda)
                y.tipoDestinatario = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(y.tipoDestinatario)
                y.tipoFirmante = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntradaSingle(y.tipoFirmante)
              })
              //le damos el valor de mailPrincipal a ordenMail para salvar los dosieres angiguos que no tienen esta opción. Esto pasa en los creados antes de añadir el envío por SMS.

              if(x.mailPrincipal!==null && x.ordenMail==null) x.ordenMail=x.mailPrincipal;
            })
          } else throw new Error(response.Message);
        })
      ).toPromise()
    }
    }
   }

  guardaEnvio(data:any): Observable<void> {
    let firmantes = this.conversionDatosApiService.mapFirmantesSalida(data.firmantes);
    let dataEnvio = this.conversionDatosApiService.mapEnviosSalida(data);
    dataEnvio.Firmantes = firmantes
    dataEnvio.Firmantes.map((x:any) => {
      x.TipoBusqueda = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(x.TipoBusqueda)
      x.TipoDestinatario = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(x.TipoDestinatario)
      x.TipoFirmante = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(x.TipoFirmante)
    })
    dataEnvio.OrdenMail = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(dataEnvio.OrdenMail)
    dataEnvio.OrdenTelefonoSms = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(dataEnvio.OrdenTelefonoSms)
    dataEnvio.OrdenTelefonoOTP = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(dataEnvio.OrdenTelefonoOTP)
    // dataEnvio.MailPrincipal = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(dataEnvio.MailPrincipal)

    dataEnvio.TiposDocumento = dataEnvio.TiposDocumento.map(doc=>{
      doc = this.conversionDatosApiService.mapDocumentosSalida(doc);
      if(doc.FirmantesNif?.length>0){
        doc.FirmantesNif = this.conversionDatosApiService.mapFirmantesNifSalida(doc.FirmantesNif);
      //   doc.FirmantesNif.map((x:any) => {
      //     x.TipoFirmante = this.conversionDatosApiService.mapTiposEnvioDesplegablesSalida(x.TipoFirmante);
      //     x.CamposEditables = this.conversionDatosApiService.mapCoordenadasSimpleSalida(x.CamposEditables);
      //     x.Formularios = this.conversionDatosApiService.mapFormulariosSalida(x.Formularios);
      //     x.Firmas = this.conversionDatosApiService.mapFirmasSalida(x.Firmas);
      //     x.Firmas.PosicionFirmas = this.conversionDatosApiService.mapCoordenadasSimpleSalida(x.Firmas.PosicionFirmas);
      //   })
      }
      // doc.TiposContenido = this.conversionDatosApiService.mapTiposContenidoSalida(doc.TiposContenido);
      return doc
    })
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post<void>(this.URL_ALTA_TIPO_ENVIO, dataEnvio, options);
  }

  getTipoFirmante():Observable<TipoFirmante[]> {
    // if(this.firmantes){
    //   return of(this.firmantes)
    // }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_TIPO_FIRMANTES, options).pipe(
        map((response: any) =>{
          if (response) {
            let firmantes: TipoFirmante[]  = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntrada(response)
            this.firmantes = firmantes;
            return firmantes;
          }
          else throw new Error(response.Message);
        })
      );
    // }


  }

  getTipoBusqueda(): Observable<TipoBusqueda[]> {
    if(this.busquedas){
      return of(this.busquedas)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_TIPO_BUSQUEDAS, options).pipe(
        map((response: any) =>{
          if (response) {
            let busquedas: TipoBusqueda[] = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntrada(response)
            busquedas.unshift({codigo:'', desc:''});
            this.busquedas = busquedas;
            return busquedas;
          }
          else throw new Error(response.Message);
        })
      );
    }

  }

  getTipoDestinatario(): Observable<TipoDestinatario[]> {
    if(this.destinatarios){
      return of(this.destinatarios)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_TIPO_DESTINATARIOS, options).pipe(
        map((response: any) =>{
          if (response) {
            let destinatarios: TipoDestinatario[] = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntrada(response)
            this.destinatarios = destinatarios;
            return destinatarios;
          }
          else throw new Error(response.Message);
        })
      );
    }

  }

  getTipoMail(refresh?:boolean): Observable<TipoMail[]> {
    if(this.mails && !refresh){
      return of(this.mails)
    }else{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get(this.URL_GET_TIPO_MAILS, options).pipe(
      map((response: any) =>{
        if (response) {
          this.mails = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntrada(response)
          return this.mails;
        }
        else throw new Error(response.Message);
      })
    );
    }
  }


  async getTipoEnvio(idTipoEnvio:string): Promise<TipoEnvio> {
    await this.getTipoEnvios()
    this.envioObject = this.envios.find(x=> x.idTipoEnvio == idTipoEnvio)
    return this.envioObject
  }

  convertirAString(datos:any[]):any[]{
    datos.map(x => x.codigo = x.codigo.toString())
    return datos
  }
}
