import { Injectable } from '@angular/core';
import { ResumenFirmasIcono } from '../models/resumen-firmas-icono';

@Injectable({
  providedIn: 'root'
})
export class EstadosFirmaService {
  readonly ESTADOS_FIRMAS: ResumenFirmasIcono[] = [
    { estado: 'TODOS', icono: 'icon-check-square', param: 'all',etiqueta:'Todos'},
    { estado: 'BORRADOR', icono: 'icon-draft', param: 'borrador',etiqueta:'Borradores'},
    { estado: 'FIRMADO', icono: 'icon-double-check', param: 'firmado',etiqueta:'Firmados'},
    { estado: 'PARCIALMENTE FIRMADO', icono: 'icon-check', param: 'parcialmentefirmado',etiqueta:'Parcialmente firmados'},
    { estado: 'RECHAZADO', icono: 'icon-rejected', param: 'rechazado',etiqueta:'Rechazados'},
    { estado: 'NO FIRMADO', icono: 'icon-pending', param: 'nofirmado',etiqueta:'No firmados'},
    { estado: 'CADUCADO', icono: 'icon-clock', param: 'caducado',etiqueta:'Caducados'},
    { estado: 'ERROR', icono: 'icon-alert', param: 'error',etiqueta:'Errores'},
    { estado: 'CANCELADO',icono:'icon-close',param:'cancelado',etiqueta:'Cancelados'}
  ];

  verEstado: string='';
  estadoSeleccionado: string='';

  constructor() { }

  public GetIcono(estado:string):string{
    const element = this.ESTADOS_FIRMAS.find(x=>x.estado==estado?.toUpperCase());
    if(element){
      return element.icono;
    }else{
      return '';
    }
  }

  clickEstadoEnvio(estadoFirma: string){
    this.verEstado = estadoFirma;
    this.estadoSeleccionado = estadoFirma;

  }
}
