import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent {
  @Input()textoFondo ='';
  @Input()accept='';
  @Input()multiple: boolean = false;
  @Output()fileListEmitter: EventEmitter<FileList> = new EventEmitter<FileList>();
  @ViewChild('InputFile') inputFile!:ElementRef

  constructor(private _snackBar: MatSnackBar) { }

  uploadFiles(data: FileList | any){
    if(!data ||data.lenth==0){
      return;
    }

    if(!(data instanceof FileList)){
      data = data.target?.files;
    }
    if (!data) {
      return;
    }
    try {
      this.validateFilesFormat(data);
      this.fileListEmitter.emit(data);
      this.inputFile.nativeElement.value="";

    } catch (error) {
      console.error('Error:', error);
      this._snackBar.open(`${error}`, 'Cerrar', { duration: 3000 });
    }
  }

  validateFilesFormat(data: FileList) {
    if(!this.multiple && data.length > 1) throw new Error(`No se permiten subir más de 1 documento`);
    let formats = this.accept.split(',').map(x => x.trim());
      for (let i = 0; i < data.length; i++) {
      const element = data.item(i);
      if(!formats.includes(element?.type ?? '')) throw new Error(`El formato del documento ${element?.name} no es correcto`);
    }
  }

}
