import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroSqp } from '../../../sqpFiltroSuperior/models/filtro-sqp';
import { TipoFiltro } from '../../../sqpFiltroSuperior/models/tipo-filtro';

@Component({
  selector: 'app-input-buscador',
  templateUrl: './input-buscador.component.html',
  styleUrls: ['./input-buscador.component.css']
})
export class InputBuscadorComponent implements OnInit {

  @Input() textoBuscar?: string = '';
  @Input() inputWidth: string = '150';
  @Output() buscador: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputBuscadorEmitter: EventEmitter<FiltroSqp> = new EventEmitter<FiltroSqp>();
  @Input() limpiarTodosFiltrosSqp:number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges){
    if(changes.limpiarTodosFiltrosSqp?.currentValue>0) this.limpiarTexto();
  }

  ngOnInit(): void {
  }

  buscar(){
    this.buscador.emit(this.textoBuscar);
    this.inputBuscadorEmitter.emit({
      tipoFiltro: TipoFiltro.TEXTO,
      titulo:'Buscar',
      valoresSeleccionados:{valorString:this.textoBuscar}
    })
  }

  limpiarTexto(){
    this.textoBuscar = '';
    this.buscador.emit(this.textoBuscar);
    this.inputBuscadorEmitter.emit({
      tipoFiltro: TipoFiltro.TEXTO,
      titulo:'Buscar',
      valoresSeleccionados:{valorString:this.textoBuscar}
    });
  }
}
