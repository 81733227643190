import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SqpGridComponentComponent } from './sqpGrid/sqp-grid-component/sqp-grid-component.component';
import { SharedModule } from '../../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UsuarioActivoSQPComponent } from './sqpLogin/components/usuario-activo-sqp/usuario-activo-sqp.component';
import { BarraSuperiorSqpComponent } from './sqpBarraSuperior/components/barra-superior-sqp/barra-superior-sqp.component';
import { MenuLateralSqpComponent } from './sqpMenuLateral/components/menu-lateral-sqp/menu-lateral-sqp.component';
import { ResumenEstadosSqpComponent } from './sqpResumenEstados/components/resumen-estados-sqp/resumen-estados-sqp.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IndicadorNumeroComponent } from './sqpIndicadores/components/indicador-numero/indicador-numero.component';
import { FiltroLateralSqpComponent } from './sqpFiltroLateral/components/filtro-lateral-sqp/filtro-lateral-sqp.component';
import { SelectorComponent } from './sqpSelectores/components/selector/selector.component';
import { IconoRefPipe } from '../../pipes/icono-ref.pipe';
import { IndicadorPastillaFiltroComponent } from './sqpPastillaFiltro/components/indicador-pastilla-filtro/indicador-pastilla-filtro.component';
import { DropDownMultiseleccionComponent } from './sqpDesplegable/components/drop-down-multiseleccion/drop-down-multiseleccion.component';
import { InputBuscadorComponent } from './sqpInputBuscador/components/input-buscador/input-buscador.component';
import { CalendarioRangoComponent } from './sqpFiltroCalendario/components/calendario-rango/calendario-rango.component';
import { FiltroSuperiorSqpComponent } from './sqpFiltroSuperior/components/filtro-superior-sqp/filtro-superior-sqp.component';
// import { IndicadorNumeroComponent } from '../others/indicador-numero/indicador-numero.component';

const MaterialComponents = [
  MatDialogModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatExpansionModule,
  MatStepperModule,
  MatIconModule,
  MatRadioModule,
  MatTooltipModule
];

@NgModule({
  declarations: [
    SqpGridComponentComponent,
    UsuarioActivoSQPComponent,
    BarraSuperiorSqpComponent,
    MenuLateralSqpComponent,
    ResumenEstadosSqpComponent,
    IndicadorNumeroComponent,
    FiltroLateralSqpComponent,
    SelectorComponent,
    IconoRefPipe,
    IndicadorPastillaFiltroComponent,
    DropDownMultiseleccionComponent,
    InputBuscadorComponent,
    CalendarioRangoComponent,
    FiltroSuperiorSqpComponent,
  ],
  imports: [
    CommonModule,
    // SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    BrowserModule,
    MaterialComponents,
    FormsModule
],
  exports: [
    SqpGridComponentComponent,
    UsuarioActivoSQPComponent,
    BarraSuperiorSqpComponent,
    MenuLateralSqpComponent,
    ResumenEstadosSqpComponent,
    MaterialComponents,
    IndicadorNumeroComponent,
    SelectorComponent,
    IconoRefPipe,
    FiltroLateralSqpComponent,
    IndicadorPastillaFiltroComponent,
    DropDownMultiseleccionComponent,
    InputBuscadorComponent,
    CalendarioRangoComponent,
    FiltroSuperiorSqpComponent,
  ]
})

export class SqpComponentsModule { }
