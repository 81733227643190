import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../../app-routing.module';
import { PanelDeControlComponent } from './components/panel-de-control/panel-de-control.component';
import { PanelFiltroLateralComponent } from './components/panel-filtro-lateral/panel-filtro-lateral.component';
import { DetalleFirmaCabeceraComponent } from './components/detalle-firma-cabecera/detalle-firma-cabecera.component';
import { DetalleFirmaFirmantesComponent } from './components/detalle-firma-firmantes/detalle-firma-firmantes.component';
import { DetalleFirmaDocumentosComponent } from './components/detalle-firma-documentos/detalle-firma-documentos.component';
import { DetalleFirmasEventosComponent } from './components/detalle-firmas-eventos/detalle-firmas-eventos.component';
import { DetalleFirmasErroresComponent } from './components/detalle-firmas-errores/detalle-firmas-errores.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { PanelEliminarFirmasComponent } from './components/panel-eliminar-firmas/panel-eliminar-firmas.component';
import { DetalleFirmaComponent } from './components/detalle-firma/detalle-firma.component';
import { DescargaMasivaModalComponent } from './components/descarga-masiva-modal/descarga-masiva-modal.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DetalleFirmaRemitenteComponent } from './components/detalle-firma-remitente/detalle-firma-remitente.component';
import { DetalleFirmaCaducidadComponent } from './components/detalle-firma-caducidad/detalle-firma-caducidad.component';
import { BarraDescargasComponent } from './components/barra-descargas/barra-descargas.component';
import { FirmasFiltrosNuevosComponent } from './components/firmas-filtros-nuevos/firmas-filtros-nuevos.component';
import { DetalleFirmaRltComponent } from './components/detalle-firma-rlt/detalle-firma-rlt.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';




@NgModule({
  declarations: [
    DetalleFirmaCabeceraComponent,
    DetalleFirmaFirmantesComponent,
    DetalleFirmaDocumentosComponent,
    DetalleFirmasEventosComponent,
    DetalleFirmasErroresComponent,
    PanelDeControlComponent,
    PanelFiltroLateralComponent,
    PanelEliminarFirmasComponent,
    DetalleFirmaComponent,
    DescargaMasivaModalComponent,
    DetalleFirmaRemitenteComponent,
    DetalleFirmaCaducidadComponent,
    BarraDescargasComponent,
    FirmasFiltrosNuevosComponent,
    DetalleFirmaRltComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    AgGridModule,
    MatProgressBarModule,
    SqpComponentsModule
  ],
  exports:[
    PanelFiltroLateralComponent
  ]
})
export class PanelControlModule { }
