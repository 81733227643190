import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ErroresForm } from '../../documentos/models/firmante-nif';
import { ErrorEnvio } from '../models/error-envio';
import { FormularioEnvioService } from './formulario-envio.service';

@Injectable({
  providedIn: 'root'
})
export class FormularioValidacionesService {

  mensajeErrorNombre: string = '';
  mensajeErrorMail: string = '';
  mensajeErrorSubject: string = '';
  mensajeErrorBody: string = '';
  mensajeErrorAdjuntos: string = '';
  mensajeErrorAvisosFirma: string = '';
  mensajeErrorProgramado: string = '';
  mensajeErrorDocumentos: string = '';
  mensajeErrorOrdenTelefonoSMS: string = '';
  mensajeErrorOrdenTelefonoOTP: string = '';

  constructor(public formularioEnvioService: FormularioEnvioService) {}

  get nombre(){
    return this.formularioEnvioService.formularioEnvio.controls['nombre']
  }

  get tiposDocumento(){
    return this.formularioEnvioService.formularioEnvio.controls['tiposDocumento']
  }

  get formularioEnvio(){return this.formularioEnvioService.formularioEnvio as FormGroup}

  get reminders_String(){ return this.formularioEnvioService.formularioEnvio.controls['reminders_String']}

  get recordatorio(){ return this.formularioEnvioService.formularioEnvio.controls['recordatorio']}

  get body(){ return this.formularioEnvioService.formularioEnvio.controls['body']}

  //comprobamos los errores antes de enviar el formulario y creamos el mensaje de error.
  isCampoValido(campo:string){
    switch(campo){
      case 'nombre':
        if(this.nombre.errors){
          if(this.nombre.errors.nombreVacio)
          this.mensajeErrorNombre = 'Requerido.'
          if(this.nombre.errors.nombreExiste)
          this.mensajeErrorNombre = 'El nombre del dosier ya existe.'
          if(this.nombre.errors.pattern)
          this.mensajeErrorNombre = 'Lo siguientes caracteres no están permitidos y se deben eliminar antes de guardar el dosier. Caracteres: ", \', /, \\'
          if(this.nombre.errors.maxlength)
          this.mensajeErrorNombre = 'El nombre es demasiado largo, puede contener 250 caracteres como máximo.'
        }
        break;
      case 'ordenMail':
        this.mensajeErrorMail = 'Requerido cuando el envío es por correo electrónico.'
        break;
      case 'ordenTelefonoSMS':
        this.mensajeErrorOrdenTelefonoSMS = 'Requerido cuando el envío es por SMS.'
        break;
      case 'ordenTelefonoOTP':
        this.mensajeErrorOrdenTelefonoOTP = 'Requerido si se ha activado OTP.'
        break;
      // case 'subject':
      //   this.mensajeErrorSubject = 'El asunto es requerido si el campo por defecto no está seleccionado.'
      //   break;
      case 'body':
        this.mensajeErrorBody = 'El mensaje de SMS debe contener 110 carácteres como máximo.'
        // if(this.body.errors?.necesitaUrl) this.mensajeErrorBody = 'Cuando se activa el "SMS", el mensaje debe incluir el campo "{{url}}" indicando el lungar donde debe aparecer el link para la firma.'
        // if(this.body.errors?.noNecesitaUrl) this.mensajeErrorBody = 'Cuando "SMS" NO está activado, el mensaje no debe contener el campo "{{url}}".'
        // this.mensajeErrorBody = 'El contenido del e-mail es requerido si el campo por defecto no está seleccionado.'
        break;
      // case 'numeroAdjuntos':
      //   this.mensajeErrorAdjuntos = 'La cantidad de archivos es requerida si se han solicitado archivos adjuntos, tiene que ser mayor que 0 y como máximo 15.'
      //   break;
      // case 'reminders_String':
      //   if(this.reminders_String.errors){
      //     if(this.reminders_String.errors.validadoresRecordatorioNum) this.mensajeErrorRecordatorios = 'El valor mínimo de cada aviso es 1 y el máximo 60.'
      //     if(this.reminders_String.errors.validadoresRecordatorio) this.mensajeErrorRecordatorios = 'El número de días de aviso es requerido si se ha seleccionado "Recordatorios programados".'
      //   }
      //   break;
      // case 'documentosEnvioProgramado':
      //   this.mensajeErrorProgramado = 'La cantidad de envíos es requerida si se ha seleccionado "Envío programado" y tiene que ser mayor que 0.'
      //   break;
      case 'tiposDocumento':
        this.mensajeErrorDocumentos = 'Deben de haber como mínimo 2 documentos fusionados.'
        break;
    }
    if(this.formularioEnvioService.formularioEnvio.controls[campo]){
      return this.formularioEnvioService.formularioEnvio.controls[campo].errors && this.formularioEnvioService.formularioEnvio.controls[campo].touched;
    }else{
      return true;
    }
  }

  //comprobamos los errores del firmantesArrayForm antes de enviar el fomulario
  isCampoValidoArray(campo: string, index: number){
    if(this.formularioEnvioService.firmantes.controls[index]){
      return this.formularioEnvioService.firmantes.controls[index].get(campo)?.errors && this.formularioEnvioService.firmantes.controls[index].get(campo)?.touched;
    }else{
      return true;
    }
  }

  //añadimos los validadores en cada uno de los atributos del firmantesArrayForm
  addValidatorsFirmantesArrayForm(){
    this.formularioEnvioService.firmantes.controls.forEach((x:any, index) => {

      let objectKeys = Object.keys(x['controls']);

      objectKeys.forEach(key => {
        this.formularioEnvioService.firmantes.controls[index].get(`${key}.codigo`)?.setValidators(Validators.required);

        this.formularioEnvioService.firmantes.controls[index].get(`${key}.codigo`)?.updateValueAndValidity();
      })
    })
  }

  // validacionDocumentos(){
  //   const fusionableArray = this.tiposDocumento.value.filter((x:any) => x.fusionable == true)
  //   if(fusionableArray.length == 1){
  //     this.mensajeErrorDocumentos = 'Si hay documentos fusionados, deben de haber como mínimo 2.'
  //     return true
  //   }
  //   return false
  // }

  //añadimos/quitamos validadores cuando los los campos de ajustes están o no clicados y hacemos el campo readonly cuando no están clicados.
  getCheckboxClick(checkbox: string){
    let condicional: string = '';
    switch (checkbox){
      // case 'solicitarAdjunto':
      //   condicional = 'numeroAdjuntos'
      //   this.formularioEnvioService.disableAdjuntos = true;
      //   break;
      case 'recordatorio':
        condicional = 'reminders_String'
        this.formularioEnvioService.disableRecordatorio = true;
        break;
      // case 'caduca':
      //   condicional = 'expire_time'
      //     this.formularioEnvioService.disableCaducidad = true;
      //   break;
      // case 'programado':
      //   condicional = 'documentosEnvioProgramado'
      //   this.formularioEnvioService.disableProgramado = true;
      //   break;
    }

    this.formularioEnvioService.formularioEnvio.get(checkbox)?.valueChanges.subscribe(value => {
      if(value) {
        // if(checkbox == 'solicitarAdjunto'){
        //   this.formularioEnvioService.formularioEnvio.get(condicional).setValidators([Validators.required, Validators.min(1), Validators.max(15)]);
        //   this.formularioEnvioService.disableAdjuntos = false;
        // }else
        if(checkbox == 'recordatorio'){
          this.formularioEnvioService.formularioEnvio.get(condicional).setValidators([Validators.required, Validators.pattern('^[0-9,]+$')])
          this.formularioEnvioService.disableRecordatorio = false;
        }
        // else{
        //   this.formularioEnvioService.formularioEnvio.get(condicional).setValidators([Validators.required, Validators.min(1)])
        //   // if(checkbox == 'caduca') this.formularioEnvioService.disableCaducidad = false;
        //   if(checkbox == 'programado') this.formularioEnvioService.disableProgramado = false;
        // }
      }
      // else {
      //   this.formularioEnvioService.formularioEnvio.get(condicional).clearValidators();
      //   this.formularioEnvioService.formularioEnvio.controls[condicional].setValue(null);
      // }
      this.formularioEnvioService.formularioEnvio.controls[condicional].updateValueAndValidity();
    })
  }


  //quitamos/añadimos required cuando el campo por defecto está clicado o no. Hacemos el campo readonly cuando está clicado defecto.
  // modificarDefectoValidators(campo: string){
  //   let condicionalDefecto: string;
  //   switch(campo){
  //     case 'defectoSubject':
  //       condicionalDefecto = 'subject'
  //       this.formularioEnvioService.disableSubject = !this.formularioEnvioService.disableSubject
  //       break;
  //     case 'defectoBody':
  //       condicionalDefecto = 'body'
  //       this.formularioEnvioService.disableBody = !this.formularioEnvioService.disableBody
  //   }
  //   this.formularioEnvioService.formularioEnvio.get(campo)?.valueChanges.subscribe(value => {
  //     if(value) {
  //       this.formularioEnvioService.formularioEnvio.get(condicionalDefecto).clearValidators();
  //       this.formularioEnvioService.formularioEnvio.controls[condicionalDefecto].setValue('');
  //     } else {
  //       this.formularioEnvioService.formularioEnvio.get(condicionalDefecto).setValidators(Validators.required);
  //     }
  //     this.formularioEnvioService.formularioEnvio.controls[condicionalDefecto].updateValueAndValidity();
  //   })
  // }


  get firmantes(){return this.formularioEnvioService.formularioEnvio.get('firmantes') as FormArray}

  getFormValidationErrors(): ErrorEnvio[]{
    let errorAvisos = this.validacionAvisos();
    let erroresForm: ErrorEnvio[] = [];
    let objetoError = {} as ErrorEnvio;
    if(errorAvisos == true){
      objetoError = {
        key: 'Recordatorios programados',
        tipoError: 'errores'
      }
      erroresForm.push(objetoError)
    }
    for (let field in this.formularioEnvio.controls) {
      const control = this.formularioEnvio.get(field);

      if(control.errors){
        let errorKey = Object.keys(control.errors)

        switch (field){
          case 'nombre':
            field = 'Nombre del dosier';
            break;
          case 'ordenMail':
            field = 'Tipo de dirección de e-mail preferida';
            break;
          case 'ordenTelefonoSMS':
            field = 'Teléfono preferido para envío SMS';
            break;
          case 'ordenTelefonoOTP':
            field = 'Teléfono preferido para envío OTP';
            break;
          case 'tiposDocumento':
            field = 'Documentos fusionados';
            break;
          case 'body':
            field = 'Mensaje';
            break
          // case 'reminders_String':
          //   field = 'Recordatorios programados'
          //   break;
        }
        switch (errorKey[0]){
          case 'nombreVacio':
            errorKey[0] = 'requerido';
            break;
          case 'nombreExiste':
            errorKey[0] = 'duplicado';
            break;
          case 'required':
            errorKey[0] = 'requerido';
            break;
          case 'docsFusionables':
            errorKey[0] = 'deben de haber como mínimo 2 documentos fusionados'
            break
          case 'maxlength':
            errorKey[0] = 'el sms debe contener como máximo 110 carácteres'
          // case 'necesitaUrl':
          //   errorKey[0] = 'Cuando se activa el "SMS", el mensaje debe incluir el campo "{{url}}" indicando el lungar donde debe aparecer el link para la firma.';
          //   break
          // case 'noNecesitaUrl':
          //   errorKey[0] = 'Cuando "SMS" NO está activado, el mensaje no debe contener el campo "{{url}}".';
            // break
        }
        objetoError = {
          key: field,
          tipoError: errorKey[0]
        }
        if(objetoError.key !== 'reminders_String') erroresForm.push(objetoError)
      }
      if(field=='firmantes'){
        this.firmantes.controls.forEach((x,index)=>{
          let firmante = x.get('tipoFirmante').value
          if(x.status !== 'VALID'){
            objetoError = {
              key: firmante.desc,
              tipoError: 'detalles requeridos'
            }
            erroresForm.push(objetoError)
          }
        })
      }
    }
    if(erroresForm[0]?.key == 'Avisos firma'){
      let errorAvisoCambio = erroresForm.shift();
      erroresForm.push(errorAvisoCambio)
    }
    return erroresForm;
  }


  validacionAvisos(){
    let returnValidacion: boolean[] = []
    if(this.reminders_String.value !== null && this.recordatorio.value == true){
      const arrayValues = this.reminders_String.value.split(',')
      arrayValues.forEach((x:any, i:number)=> {
        if(x ==''|| x == null){
          this.mensajeErrorAvisosFirma = 'Los avísos son requeridos si se ha marcado la opción "Avisos de firma"'
          returnValidacion.push(true)
        }
        else if(Number(x) < 1 || Number(x) > 60){
          this.mensajeErrorAvisosFirma = 'Los avisos tiene que ser de 1 día como mínimo y 60 como máximo.'
          returnValidacion.push(true)
        }
        else if(parseInt(arrayValues[i]) <= parseInt(arrayValues[i-1])){
          this.mensajeErrorAvisosFirma = 'El aviso tiene que ser mayor que el anterior.'
          returnValidacion.push(true)
        }
      })
    }else{
      if(this.recordatorio.value == true) {
        this.mensajeErrorAvisosFirma = 'Los avísos son requeridos si se ha marcado la opción "Avisos de firma"'
        returnValidacion.push(true)
      }
    }
    if(returnValidacion.includes(true)) return true;
    else return false;
  }
}
