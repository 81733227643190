<div class="main">
  <h2>Identificación del contenido</h2>
  <h5>CIF de la empresa</h5>
  <form [formGroup]="formularioDocumentoService.formularioDocumento">
    <div class="flex">
      <input type="checkbox" id="contieneCifId" formControlName="contieneCif" (click)="getClickCheckbox()">
      <span>
        <svg class="icon-building">
          <use xlink:href="#icon-building"></use>
        </svg>
      </span>
      <label for="contieneCifId" style="font-size: 14px;">El documento contiene un CIF de empresa</label>
    </div>
    <div *ngIf="mostrarCif">
      <app-configuracion-documento-icon-info [nombre]="'CIF'"></app-configuracion-documento-icon-info>
      <p>Selecciona el CIF en el documento</p>
      <app-configuracion-documento-datos-cif [idPdf]="idPdf" (cargandoEmitter)="isCargando($event)"></app-configuracion-documento-datos-cif>
    </div>
  </form>
</div>
