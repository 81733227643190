import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GridColDefsData } from 'src/app/pages/panelControl/models/grid-col-defs-data';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { EstadosFirmaService } from 'src/app/pages/panelControl/services/estados-firma.service';
import { ResumenFirmasSqp } from 'src/app/shared/components/sqp-components/sqpResumenEstados/models/resumen-firmas-sqp';

@Component({
  selector: 'app-boton-resumen-firmas',
  templateUrl: './boton-resumen-firmas.component.html',
  styleUrls: ['./boton-resumen-firmas.component.css']
})
export class BotonResumenFirmasComponent implements OnInit {

  resumenFirmasSqp: ResumenFirmasSqp[] = [];
  @Input() resumenFirmas: ResumenFirmas[] = [];

  constructor(private router: Router,
              private domSanitizer: DomSanitizer,
              public estadosFirmaService: EstadosFirmaService) {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.resumenFirmas.currentValue){
      this.resumenFirmas = changes.resumenFirmas.currentValue;
      this.resumenFirmas.forEach(x=>{
        let firma = {} as ResumenFirmasSqp;
        firma.estado = x.estado;
        firma.etiqueta = x.etiqueta;
        firma.hayNovedades = x.hayNovedades;
        firma.icono = x.icono;
        firma.numeroElementos = x.firmas.length;
        firma.numerosNuevos = this.NumeroNuevas(x).toString();
        firma.tooltipMessage = '';

        this.resumenFirmasSqp.push(firma)
      })
    }
  }

  ngOnInit() {
  }

  public NumeroNuevas(item:ResumenFirmas):number{
    let novedades = item.firmas.filter(x=>x.esNueva).length;
    return novedades;
  }

  // getIcono(_resumenFirmas: ResumenFirmasSqp){
  //   return this.domSanitizer.bypassSecurityTrustHtml(new GridColDefsData().GetColor(_resumenFirmas.estado, true));
  // }

  goToFirmas(){
    this.router.navigate(['panel/envios'])
  }

}
