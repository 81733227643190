import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { FormularioDocumentoService } from "../../configuracion/Envios/documentos/services/formulario-documento.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationMessageComponent } from "src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component";
import { TipoMensajeConfirmacion } from "src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data";
import { FormularioEnvioService } from "../../configuracion/Envios/envios/services/formulario-envio.service";

export class VentanasAbiertas{
    private ventanasAnalizar:string[] = ['configuracion-documento','configuracion-dosier'];
    public logoutDirecto:boolean = true;

    constructor(private router: Router,
                private formularioDocumentoService: FormularioDocumentoService,
                private dialog: MatDialog,
                private formularioEnvioService: FormularioEnvioService){
    }

    ventanaAbierta():string{
        // let encontrada:boolean = false;
        let opcionAbierta:string = '';
        var BreakException = {};
        try{
            this.ventanasAnalizar.forEach(x =>{
                    if(this.router.url.includes(x)){
                        // encontrada = true;
                        opcionAbierta = x;
                        throw BreakException
                    }
                }
            )
        }catch(e){
            if(e==BreakException){
                return opcionAbierta;
            }
        }
        this.logoutDirecto = true;
        return "";
    }

    accionVentanaAbierta(ventana:string, destino:string):Observable<any>{
        switch(ventana){
            case 'configuracion-documento':
                if(!this.formularioDocumentoService.hayCambios()){
                    this.logoutDirecto = true;
                    return of("OK");
                  }else{
                    this.logoutDirecto = false;
                    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
                        data: this.montarMensajeConfirmacion('documento', destino)
                    });
                    return dialogRef.afterClosed();
                  }
            case 'configuracion-dosier':
              if(!this.formularioEnvioService.hayCambiosDosier()){
                this.logoutDirecto = true;
                return of("OK");
              }else{
                this.logoutDirecto = false;
                const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
                  data: this.montarMensajeConfirmacion('dosier', destino)
                });
                return dialogRef.afterClosed();
              }
        }
        this.logoutDirecto = true;
        return of("OK");
    }

  montarMensajeConfirmacion(tipoConfiguracion: string, destino:string):any{
    return {
        tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
        icono: 'icon-save2',
        titulo: destino!=='logout' ? '¿Deseas salir sin guardar?':'¿Deseas salir de Hire & Sign sin guardar?',
        mensaje:`Se perderán todos los cambios realizados en en la configuración del ${tipoConfiguracion}.`,
        textoConfirmacion: 'Salir sin guardar',
        iconoConfirmacion: 'icon-unsaved',
        textoCancelar: 'cerrar'
      }
  }

}
