<h3>Estado de los envíos</h3>
<div class="square">
  <div *ngIf="resumenFirmasSqp.length == 0" class="spinner">
      <mat-spinner diameter="35" strokeWidth="2"></mat-spinner>
  </div>
  <app-resumen-estados-sqp *ngIf="resumenFirmasSqp.length > 0"
                           [resumenFirmas]="resumenFirmasSqp"
                           (clickEstado)="estadosFirmaService.clickEstadoEnvio($event); goToFirmas()"
                           [estadoSeleccionado]="estadosFirmaService.estadoSeleccionado"
                           [verNuevos]="true"
                           ></app-resumen-estados-sqp>
</div>
