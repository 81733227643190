import { Injectable } from '@angular/core';
import { IRecuperaConfiguracionAplicacionService } from './ports/i-recupera-configuracion-aplicacion-service';
import { ConfiguracionRlt } from '../models/configuracion-rlt';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecuperaConfiguracionAplicacionApiService implements IRecuperaConfiguracionAplicacionService{

  public readonly URL_GUARDA_CONFIGURACION_RLT: string = this.endPointService.UrlGuardaConfiguracionRlt;
  public readonly URL_GET_CONFIGURACION_RLT: string = this.endPointService.UrlGetConfiguracionRlt;
  public readonly URL_IS_MODULO_RLT_ACTIVO: string = this.endPointService.UrlIsModuloRltActivo;
  public readonly URL_MODULOS_ACTIVOS: string = this.endPointService.UrlGetModulosActivos;

  constructor(private conversionDatosApiService: ConversionDatosApiService,
              private loginService: LoginService,
              private client: HttpClient,
              private endPointService: EndpointService) { }

  getConfiguracionRlt(): Observable<ConfiguracionRlt> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get(this.URL_GET_CONFIGURACION_RLT, options).pipe(
      map((response: any) =>{
        if (response) {

          let configuracionRlt = this.conversionDatosApiService.mapConfiguracionRltEntrada(response);
          return configuracionRlt;
        }
        else throw new Error(response.Message);
      })
    );
  }

  guardaConfiguracionRlt(data:any): Observable<void> {
    let dataEnvio = this.conversionDatosApiService.mapConfiguracionRltSalida(data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };

    return this.client.post<void>(this.URL_GUARDA_CONFIGURACION_RLT, dataEnvio, options);
  }

  // isModuloRltActivo(): Observable<boolean> {
  //   const options = {
  //     headers: new HttpHeaders({
  //       'Content-Type':'application/json',
  //       'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
  //     })
  //   };
  //   return this.client.get<boolean>(this.URL_IS_MODULO_RLT_ACTIVO, options);
  // }

  getModulosActivos(): Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get<any>(this.URL_MODULOS_ACTIVOS, options);
  }
}
