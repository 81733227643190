import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltroEnvio } from 'src/app/shared/models/filtros';
import { IGestionarTiposEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/ports/igestionar-tipos-envio-service';
import { TipoBusqueda } from '../models/tipo-busqueda';
import { TipoDestinatario } from '../models/tipo-destinatario';
import { EstadosTipoEnvio, EstadosTipoEnvioApi, TipoEnvio } from '../models/tipo-envio';
import { TipoFirmante } from '../models/tipo-firmante';
import { TipoMail } from '../models/tipo-mail';
import { Firmantes } from '../models/firmantes';
import { RecuperaConfiguracionAplicacionApiService } from '../../../configuracion-rlt/services/recupera-configuracion-aplicacion-api.service';
import { ConfiguracionRlt } from '../../../configuracion-rlt/models/configuracion-rlt';
import { DesplegableData } from 'src/app/shared/components/sqp-components/sqpDesplegable/models/desplegable-data';


@Injectable({
  providedIn: 'root'
})
export class DatosEnviosService {

  // public tiposEnvio:TipoEnvio[] = [];
  // envios: TipoEnvio[] = [];
  enviosFiltrados: TipoEnvio[] = [];
  filtros: FiltroEnvio[] = [];
  columnDef: ColDef[] = [];
  firmantes: TipoFirmante[] = [];
  destinatarios: TipoDestinatario[] = [];
  busquedas: TipoBusqueda[] = [];
  mails: TipoMail[] = [];
  refresh: boolean;
  enviosIniciales: TipoEnvio[] = [];

//OL Refactor: Se inyecta el servicio que trae datos (solo trae datos)
  constructor(
              @Inject('RecuperaTipoEnvio') private datosEnvio: IGestionarTiposEnvioService,
              public recuperaConfiguracionAplicacionService: RecuperaConfiguracionAplicacionApiService) { }
  //OL Refactor: Se traen los datos del servicio inyectado mock o api
  // getTipoEnvios(): Observable<TipoEnvio[]>{

  //     return this.datosEnvio.getTipoEnvios()
  //       .pipe(
  //         map(resp => {
  //             this.envios = resp;
  //             this.envios.forEach(x=> x.estado = this.mostrarEstadoGrid(x.estado))
  //             return this.envios;
  //           }
  //         )
  //     );
  // }

  async getTipoEnvios(){
    if(this.enviosFiltrados && this.enviosFiltrados.length>0 && !this.refresh){
      return this.enviosFiltrados;
    }else{
      this.enviosFiltrados = null; //null para que el ag-grid de envíos muestre overlayLoadingTemplate mientras se está cargando los datos. Si fuera [] salta overlayNoRowsTemplate.
      // let moduloRltActivo = await this.isModuloRltActivo();
      let moduloRltActivo = await this.getModulosActivos();
      await this.datosEnvio.getTipoEnvios(this.refresh);
      let configRlt = await this.getConfiguracionRlt();
      // Si el módulo no está habilitado recuperamos el listado completo porque no hace falta comprobar si está activo el rlt o no
      // if(moduloRltActivo) { //MR - Dejamos que siempre se vea el dosier de RLT, esté la configuración activada o no.

      //   // Comprobamos si el proceso rlt está activo. Si no lo está ocultamos ese tipo de dosier configurado
      //   this.datosEnvio.envios = (!configRlt?.activo) ? this.datosEnvio.envios.filter(x => x.idTipoEnvio != configRlt?.idTipoDosier.toString()) : this.datosEnvio.envios;
      // }
      this.enviosFiltrados = this.datosEnvio.envios
      this.enviosFiltrados.forEach(x=> {
        x.estado = this.mostrarEstadoGrid(x.activo)
        // x.estado = this.mostrarEstadoGrid(x.estado)
        // if(x.estado == EstadosTipoEnvio.incompleto) x.activo = false;
      })
      this.refresh = false;

      this.enviosIniciales = [...this.enviosFiltrados]
      return this.enviosFiltrados;
    }

  }
  // async getTipoEnvios(){
  //   await this.datosEnvio.getTipoEnvios()
  //   this.envios = this.datosEnvio.envios
  //   this.envios.forEach(x=> x.estado = this.mostrarEstadoGrid(x.estado))
  //   return this.envios;
  // }

  mostrarEstadoGrid(estadoEnvio: boolean): string{
    let estadoEnvioGrid: string = '';
    switch (estadoEnvio){
      case true:
        estadoEnvioGrid = EstadosTipoEnvio.completo
        break;
      case false:
        estadoEnvioGrid = EstadosTipoEnvio.incompleto
        break;
    }
    return estadoEnvioGrid
  }
  // mostrarEstadoGrid(estadoEnvio: string): string{
  //   let estadoEnvioGrid: string = '';
  //   switch (estadoEnvio){
  //     case EstadosTipoEnvioApi.completo:
  //       estadoEnvioGrid = EstadosTipoEnvio.completo
  //       break;
  //     case EstadosTipoEnvioApi.incompleto:
  //       estadoEnvioGrid = EstadosTipoEnvio.incompleto
  //       break;
  //   }
  //   return estadoEnvioGrid
  // }

  SetFilter(_filtros: FiltroEnvio[]){
    this.filtros = _filtros;
  }
  // Aplicamos los filtros cargados al listado completo de envios y guardamos el resultado
  Filter(){

    if(this.filtros.length == 0 || this.filtros.every(x => x.value == '')){
      this.enviosFiltrados = this.datosEnvio.envios
    }else{
      this.enviosFiltrados = this.datosEnvio.envios;
      for( const filter of this.filtros){
        // this.enviosFiltrados = this.datosEnvio.envios;
        if(filter.textoBuscar.trim() != ""){
          this.enviosFiltrados =  this.enviosFiltrados.filter(x =>
            x.nombre.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.estado.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.tiposDocumento.length.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.firmantes.length.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.firmantes.filter((x: Firmantes) => x.tipoDestinatario.desc == 'Validador').length.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())
        )
        }else{
          // if(filter.idTipoEnvio != "" && filter.idTipoEnvio?.toString().trim().toUpperCase() != "TODOS") {
            if(filter.value != "" && filter.value?.toString().trim().toUpperCase() != "TODOS" && filter.value!='Listo para envío' && filter.value!='Incompleto') {
              this.enviosFiltrados = this.enviosFiltrados.filter(x => x.nombre == filter.value);
            }
            if(filter.estado?.trim() !="" && filter.estado?.trim().toUpperCase() != "TODOS"){
              this.enviosFiltrados = this.enviosFiltrados.filter(x => x.estado == filter.estado);
            }
        }

      }
      // this.enviosFiltrados.forEach(x => {
      //   if(! this.enviosFiltrados.includes(x))  this.enviosFiltrados.push(x);
      // })
    }

  }

  deleteFilter(filter:FiltroEnvio){
    this.filtros = this.filtros.filter(x=>x.text!=filter.text && x.value!=filter.value);
  }

  // Obtenemos el listado de estados de laos envíos
  GetEstadosLista(): DesplegableData[]{
    let estados: DesplegableData[] = [];
    estados.push({id:'TODOS',desc:'Todos los estados'});
    estados.push({
                    id: EstadosTipoEnvio.incompleto,
                    desc: EstadosTipoEnvio.incompleto
                  },
                  {
                    id: EstadosTipoEnvio.completo,
                    desc: EstadosTipoEnvio.completo
                  }
                )
    return estados;
  }

  // Obtenemos el listado de tipos de envio
  GetTipoDosierLista(): DesplegableData[]{
    let tiposDosier: DesplegableData[] = [];
    tiposDosier.push({id:'TODOS',desc:'Nombre dosier'});
    this.enviosFiltrados.forEach(x => {
      if(!tiposDosier.find(y => y.id == x.idTipoEnvio.toString())){
        tiposDosier.push({id: x.idTipoEnvio.toString(), desc: x.nombre})
      }
    })
    return tiposDosier;
  }

  getTipoFirmante(): Observable<TipoFirmante[]> {
    return this.datosEnvio.getTipoFirmante()
      .pipe(
        map(resp => {
            this.firmantes = resp;
            return this.firmantes;
          }
        )
    )
  }

  getTipoDestinatario(): Observable<TipoDestinatario[]> {
    return this.datosEnvio.getTipoDestinatario()
      .pipe(
        map(resp => {
          this.destinatarios = resp;
          return this.destinatarios
          }
        )
    )
  }

  getTipoBusqueda(): Observable<TipoBusqueda[]> {
    return this.datosEnvio.getTipoBusqueda()
      .pipe(
        map(resp => {
          this.busquedas = resp;
          return this.busquedas
          }
        )
    )
  }

  getTipoMail(): Observable<TipoMail[]> {
    return this.datosEnvio.getTipoMail()
      .pipe(
        map(resp => {
          this.mails = resp;
          return this.mails
          }
        )
    )
  }

  guardaEnvio(objetoEnvio: TipoEnvio): Observable<void>{
    return this.datosEnvio.guardaEnvio(objetoEnvio)
  }

  guardaError(objetoEnvio: TipoEnvio): Observable<TipoEnvio>{
    throw new Error('no se ha guardado')
  }

  async getTipoEnvio(idTipoEnvio: string) {
    await this.datosEnvio.getTipoEnvio(idTipoEnvio)
      let envio = this.datosEnvio.envioObject
      return envio
  }


  // private async isModuloRltActivo(): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     this.recuperaConfiguracionAplicacionService.isModuloRltActivo().subscribe(
  //       ok => {
  //         resolve(ok);
  //       },
  //       error => {
  //         console.log(error);
  //         resolve(false);
  //       }
  //     );
  //   });
  // }

  private async getModulosActivos(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.recuperaConfiguracionAplicacionService.getModulosActivos().subscribe(
        ok => {
          resolve(ok.ModuloRltActivo);
        },
        error => {
          console.log(error);
          resolve(false);
        }
      );
    });
  }

  private async getConfiguracionRlt(): Promise<ConfiguracionRlt>{
    return new Promise<ConfiguracionRlt>((resolve, reject) => {
      this.recuperaConfiguracionAplicacionService.getConfiguracionRlt().subscribe(
      next => {
        resolve(next);
      },
      error => {
        resolve(null);
      }
      );
    });
  }

}
