import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { TenantData } from 'src/app/shared/components/sqp-components/sqpLogin/models/TenantData';

@Component({
  selector: 'app-login-azure-ad',
  templateUrl: './login-azure-ad.component.html',
  styleUrls: ['./login-azure-ad.component.css']
})
export class LoginAzureAdComponent implements OnInit {

  constructor(private route:ActivatedRoute,
              private snack:MatSnackBar,
              private loginService:LoginService,
              private datosFirmasService: DatosFirmaService,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
       params=>{
        params.queryParams
        if(params.error){
            this.snack.open(`Se ha producido un error ${params.error}`);
        }

        let tokenINfo = this.loginService.DecodeTokenLogin(params.token);

        let tenants:TenantData[] = JSON.parse(tokenINfo.Tenant);

        if(tenants.length==1){
            this.loginService.LoginAzureAd(tenants[0],params.token,tenants).subscribe(
              ok => {

                this.datosFirmasService.GetFirmas().subscribe(
                  ok => {

                    // Redirigir al menu principal

                    // this.datosFirmasService.Filter();
                    this.datosFirmasService.filtrarNuevo(this.datosFirmasService.filtrosConEstado);

                  },
                  error => {
                    console.error(error);
                  });

                  //MIentras recuperamos las firmas vamos al menu principal.
                  this.router.navigate(["/panel/main"]);
              },
              error =>{
                // Mostrar mensaje de error
                console.error(error);
                let errorMsg = (error.status == 401) ? 'Usuario y/o contraseña no válidos' : error.message;

                this.snack.open(errorMsg, 'Cerrar', {duration: 3000});
              });
        }
        if(tenants.length>1){
          this.router.navigate([`selectTenant/${params.token}`]);
        }
      }
      );
  }

}
