import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BotonAccionData } from 'src/app/shared/models/boton-accion-data';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { PanelEliminarFirmasComponent } from '../panel-eliminar-firmas/panel-eliminar-firmas.component';
import { FiltroFirma, Filtros } from 'src/app/shared/models/filtros';
import { DescargaMasivaModalComponent } from '../descarga-masiva-modal/descarga-masiva-modal.component';
import { GridColDefsData } from 'src/app/pages/panelControl/models/grid-col-defs-data';
import { ColDef } from 'ag-grid-community';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';
import { EstadosFirmaService } from '../../services/estados-firma.service';
import { DetalleFirmaComponent } from '../detalle-firma/detalle-firma.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';
import { FiltrosSelectorResumen } from 'src/app/shared/components/sqp-components/sqpSelectores/models/filtros-selector-resumen';
import { DesplegableData } from 'src/app/shared/components/sqp-components/sqpDesplegable/models/desplegable-data';
import { FiltroSqp, VisualizacionDestacada } from 'src/app/shared/components/sqp-components/sqpFiltroSuperior/models/filtro-sqp';
import { TipoFiltro } from 'src/app/shared/components/sqp-components/sqpFiltroSuperior/models/tipo-filtro';

@Component({
  selector: 'app-panel-de-control',
  templateUrl: './panel-de-control.component.html',
  styleUrls: ['./panel-de-control.component.css']
})
export class PanelDeControlComponent implements OnInit {

  firmasResumen: ResumenFirmas[] = [];
  firmas: any;// Variable definida como any para que inicialmente se muestre el label de "Cargando" del grid. Posteriormente se cargará la lista de firmas
  columnDefs: ColDef[] =  new GridColDefsData().columDefs;
  columnasaExcluir:string[] =['esNueva','descargar'];
  columnasExclusivasExports: string[] = ['Documentos subidos', 'Documentos pendientes'];
  refrescando = false;
  tiposDosier: DesplegableData[];
  empresas: DesplegableData[];
  centros: DesplegableData[];
  filtrosFirma: FiltroFirma[]=[];
  gridOptions:any ={
    getRowStyle: (params: any) => {
      if(params.data.esNueva) return { fontWeight: "bold", backgroundColor: "var(--hs-yellow3)" };
      else return {};
    }
  }
  filtrosSelectorResumen: FiltrosSelectorResumen[]=[
    {
      textoOpcion1:'',
      textoOpcion2:'Sandbox',
      active: false,
      control:'Sandbox'
    },
    {
      textoOpcion1:'',
      textoOpcion2:'Ver sólo nuevos',
      active: false,
      control:'Nuevos'
    }
  ]
  filtrosSqp: FiltroSqp[]=[];

  constructor(public datosFirmaService: DatosFirmaService,
               private router: Router, private route: ActivatedRoute,
               public dialog: MatDialog,
               private exportadorPdfService: ExportadorPdfService,
               private exportadorExcelService: ExportadorExcelService,
               public estadosFirmaService: EstadosFirmaService,
               private cdr: ChangeDetectorRef) {
    const param = this.route.snapshot.paramMap.get('nuevos');
    if(param=='nuevos') this.filtrosSelectorResumen[1].active=true; //this.verNuevasParam=true;
    this.getFirmas().then((ok)=>{
      this.tiposDosier  = this.datosFirmaService.GetTipoDosierLista();
      this.empresas  = this.datosFirmaService.GetEmpresaLista();
      this.centros  = this.datosFirmaService.GetCentrosLista();
      this.montarListaFiltrosSqp();
      this.datosFirmaService.filtrarNuevo(this.traducirFiltrosSqpAFiltrosFirmas());
      this.filtrarFirmasNuevoFiltro(true);
    });
  }

  ngOnInit(): void {

  }

  async getFirmas(){
    this.firmasResumen = await this.datosFirmaService.GetResumen(this.refrescando);
    this.firmas = (this.datosFirmaService.firmasFiltradas.length > 0) ? this.datosFirmaService.firmasFiltradas : this.datosFirmaService.firmas;
  }

//#region Acciones barra superior

  clickBarraSuperior(id: string){
    switch (id) {
      case "Descargar":
        this.descargarFirmasGrid();
        break;
      case 'Excel':
        this.exportaExcel();
        break;
      case "Eliminar":
        this.eliminarFirmas();
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      default:
        break;
    }
  }

  eliminarFirmas(){
    if(this.firmas)
    {
      let firmasError = this.firmas.filter((x:Firmas) => x.estadoString == "Error");
      const dialogRef = this.dialog.open(PanelEliminarFirmasComponent, {
        data: firmasError,
        position: { right: '0'},
        panelClass: 'modal-class'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(typeof result == 'number'){
          this.refresh();
        }
      });
    }
  }

  descargarFirmasGrid(){
    if(this.firmas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'hs-download',
          titulo: 'Descargar documentos',
          mensaje: '<p>Descarga los certificados de firma y los documentos firmados de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Descargar',
          iconoConfirmacion: 'hs-download',
          textoCancelar: 'cancelar'
        },
        autoFocus: 'dialog'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {

          let firmasDescargar = this.datosFirmaService.firmasFiltradas.length > 0 ? this.datosFirmaService.firmasFiltradas : this.firmas;
          let idsFirmas = firmasDescargar.filter((x: Firmas) => x.estadoString === "Firmado" || x.estadoString === "Parcialmente firmado").map((x: Firmas) => x.idFirma);
          this.dialog.open(DescargaMasivaModalComponent,{
            data:{
              ids:idsFirmas
            }
          });
          //this.datosFirmaService.GetAllDocs(idsFirmas);
        }
      });
    }
  }

  exportaExcel(){
    if(this.firmas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: '<p>Genera un documento Excel con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        },
        autoFocus: 'dialog'
      });

      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {
          // Filtramos firmas a exportar
          let firmasExportar = this.datosFirmaService.firmasFiltradas.length > 0 ? this.datosFirmaService.firmasFiltradas : this.firmas;
          // Cargamos los datos de los documentos de las firmas y exportamos el excel
          const dialogRefExport = this.dialog.open(SpinnerModalComponent);
          let abort :AbortController = new AbortController();
          this.datosFirmaService.GetDatosExtraFirmas(abort.signal).then(() => {
            let rows = this.GetRowsExportExcel(firmasExportar, datosExcel.columnas);
            this.exportadorExcelService.exportaExcel(datosExcel, rows);
            dialogRefExport.close();
          }).catch((error:any) => {
            console.error("Error exportando a excel", error);
            dialogRefExport.close();
          });
        }
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Firmas',
      nombreFichero:`Hire&Sign_envios_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumnsExportExcel()
    }
    return datosExcel
  }

  //Generamos las columnas basandonos en las del grid
  GetColumnsExportExcel(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach((x, i)=>{
      //Solo generamos las columnas con nombre (internas no)
      if(!x.hide) {
        if(!this.columnasaExcluir.includes(x.field??'')){
          let element:any = {};
          element.name = x.headerName;
          element.filterButton = true;
          element.width = x.width;
          columns.push(element);
        }
      }
    });
    return columns;
  }

  GetRowsExportExcel(data: Firmas[], columnas: any[]):any[]{
    let rows:any[] = [];
    data.forEach(x=>{
      let fechaEnvio = x.fechaFirma ?? x.fechaEnvio ?? x.fechaUltimaActualizacion;
      // let estadoRlt = x.estadoRlt ? 'Enviado':'Pendiente';
      let estadoRlt;
      if(x.estadoRlt == null) estadoRlt = '';
      else if(x.estadoRlt) estadoRlt='Enviado';
      else if(!x.estadoRlt) estadoRlt='Pendiente';
      let row:any[] = [];
      columnas.forEach((y: any) => {
        switch (y.name) {
          case 'Estado': row.push(x.estadoString); break;
          case 'Firmante principal': row.push(x.empleado); break;
          case 'Fecha creación': row.push(x.fechaCreacion); break;
          case 'Fecha envío/firma': row.push(fechaEnvio); break;
          case 'Tipo de dosier': row.push(x.tipoEnvio); break;
          case 'Centro': row.push(`${x.codigoCentro} - ${x.nombreCentro}`); break;
          case 'Empresa': row.push(`${x.codigoEmpresa} - ${x.nombreEmpresa}`); break;
          case 'Docs': row.push(x.numeroDocumentos); break;
          case 'RLT': row.push(estadoRlt); break;
          case 'Fecha contrato': row.push(x.fechaContrato); break;
          default: row.push(''); break;
        }
      });
      rows.push(row);
    });
    return rows
  }

  exportaPdf(){
    if(this.firmas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: '<p>Genera un documento PDF con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        },
        autoFocus: 'dialog'
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf()

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {
          // Filtramos firmas a exportar
          let firmasExportar = this.datosFirmaService.firmasFiltradas.length > 0 ? this.datosFirmaService.firmasFiltradas : this.firmas;
          // Cargamos los datos de los documentos de las firmas y exportamos el excel
          const dialogRefExport = this.dialog.open(SpinnerModalComponent);
          let abort :AbortController = new AbortController();
          this.datosFirmaService.GetDatosExtraFirmas(abort.signal).then(() => {
          //escogemos la fecha a mostrar y la convertimos a formato correcto
          firmasExportar.forEach((x: any) => {
            let fecha =  x.fechaCreacion ?? x.fechaUltimaActualizacion;
            x._fechaCreacion = new Date(fecha).toLocaleDateString("en-GB");
            fecha = x.fechaFirma ?? x.fechaEnvio ?? x.fechaUltimaActualizacion;
            x._fechaFirma = new Date(fecha).toLocaleDateString("en-GB");
            if(x.estadoRlt ==null) x.estadoRlt = '';
            else if(x.estadoRlt) x.estadoRlt='Enviado';
            else if(!x.estadoRlt) x.estadoRlt='Pendiente';
            // x.estadoRlt = x.estadoRlt ? 'Enviado':'Pendiente';
            x.nombreCentro = `${x.codigoCentro} - ${x.nombreCentro}`;
            x.nombreEmpresa = `${x.codigoEmpresa} - ${x.nombreEmpresa}`;
            x._fechaContrato = x.fechaContrato!=null ? new Date(x.fechaContrato).toLocaleDateString("en-GB") : '';
          });

          this.exportadorPdfService.exportaPdf(datos, firmasExportar);
          dialogRefExport.close();
          }).catch((error:any) => {
            console.error("Error exportando a excel", error);
            dialogRefExport.close();
          });
        }
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let fecha = new Date();
    let columns: any[] = [];
    new GridColDefsData().columDefs.forEach(x => {
      if(!x.hide) {
        if(x.field?.toLocaleLowerCase().includes("fecha")) columns.push({header: x.headerName, dataKey: `_${x.field}`});
        else if(!this.columnasaExcluir.includes(x.field ?? '')) columns.push({header: x.headerName, dataKey: x.field});

      }
    });
    // columns.push({header: 'Documentos subidos', dataKey: 'documentosSubidosString'})
    // columns.push({header: 'Documentos pendientes', dataKey: 'documentosPendientesString'})
    //montamos el objeto a pasar al servicio
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_envios_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  refresh(){
    this.datosFirmaService.refreshFirmas = true;
    this.datosFirmaService.GetFirmas().subscribe(ok=>{
      this.getFirmas().then(resp=>{
        this.firmasResumen = this.datosFirmaService.filtrarNuevo(this.datosFirmaService.filtrosConEstado)
        this.firmas = this.datosFirmaService.firmasFiltradas;
        this.datosFirmaService.refreshFirmas = false;
      });
    })
  }

  irASubida(){
    this.router.navigate(['/panel/subida']);
  }


  dialogRefSpinnerAbrirModal:any;

  selectRowChanged(event: any){

    //SI ES UN ELMENTO QUE SE PUEDE CLICKAR NO HACEMOS NADA
    if(event.event.target.classList.contains('clickable')) return;

  	//  let firma:Firmas = this.api.getSelectedNodes().map((x: any) => x.data)[0];
    let firma: Firmas = event.data;

    if(!firma.cargaCompleatada) {
      const i = this.datosFirmaService.firmas.findIndex(x=>x.idFirma == firma.idFirma);
      this.dialogRefSpinnerAbrirModal = this.dialog.open(SpinnerModalComponent);
      this.datosFirmaService.GetDatosExtraFirmaCall(i).then(resp=>{
        this.abreModal(this.datosFirmaService.firmas[i]);
      })
    }else{
      this.abreModal(firma);
    }

  }
  private abreModal(firma:Firmas){
    this.dialogRefSpinnerAbrirModal.close();
    const dialogRef = this.dialog.open(DetalleFirmaComponent, {
      data: firma,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.refresh){
        this.refresh();
      }
    })
  }

//FILTROS SQP (LATERAL Y SUPERIOR)

  filtrarFirmasNuevoFiltro(event: boolean){
    if(this.firmas && event){
      this.firmas = this.datosFirmaService.firmasFiltradas;
      this.datosFirmaService.GetResumen(false).then(x => { this.firmasResumen = x});
    }
  }

  montarListaFiltrosSqp(){
    this.filtrosSqp = [];
    this.filtrosSqp=[
      {
        tipoFiltro: TipoFiltro.TEXTO,
        titulo: 'Buscar',
        valoresSeleccionados:{valorString:''}
      },
      {
        tipoFiltro: TipoFiltro.PASTILLA,
        titulo: 'Nuevos',
        valoresSeleccionados:{valorBool:this.filtrosSelectorResumen[1].active}
      },
      {
        tipoFiltro: TipoFiltro.PASTILLA,
        titulo: 'Sandbox',
        valoresSeleccionados:{valorBool:false},
        visualizacionDestacada: VisualizacionDestacada.TEST
      },
      {
        tipoFiltro: TipoFiltro.DESPLEGABLE,
        titulo: 'Tipo dosier',
        valoresDesplegable: this.tiposDosier
      },
      {
        tipoFiltro: TipoFiltro.DESPLEGABLE,
        titulo: 'Empresa',
        valoresDesplegable: this.empresas
      },
      {
        tipoFiltro: TipoFiltro.DESPLEGABLE,
        titulo: 'Centro',
        valoresDesplegable: this.centros
      },
      {
        tipoFiltro: TipoFiltro.TEXTO,
        titulo: 'Estado',
        valoresSeleccionados:{valorString:''}
      },
      {
        tipoFiltro: TipoFiltro.FECHA,
        titulo: 'Fecha de envío',
        valoresSeleccionados:{
          valorFechaDesde: null,
          valorFechaHasta: null
        }
      },
      {
        tipoFiltro: TipoFiltro.FECHA,
        titulo: 'Fecha contrato',
        valoresSeleccionados:{
          valorFechaDesde: null,
          valorFechaHasta: null
        }
      }
    ];
  }

  actualizarFiltrosDesdeFiltroSuperior(event:FiltroSqp){
    if(event){ //el evento puede venir undefined cuando se hace limpiar todo
      let index = this.filtrosSqp.findIndex(x=>x.titulo==event.titulo)
      if(index == -1) this.filtrosSqp.push(event);
      else this.filtrosSqp[index] = event;

      if(event.titulo=='Nuevos' || event.titulo=='Sandbox'){
        this.filtrosSelectorResumen.find(x=>event.titulo==x.control).active = event.valoresSeleccionados.valorBool;
      }

      this.datosFirmaService.filtrarNuevo(this.traducirFiltrosSqpAFiltrosFirmas());
      this.filtrarFirmasNuevoFiltro(true);
    }
  }

  borrarFiltros(){
    this.filtrosSelectorResumen.forEach(x=> x.active=false);
    this.estadosFirmaService.estadoSeleccionado = 'TODOS';
  }

  clickDesdeFiltroLateralSqp(seleccion: FiltrosSelectorResumen){
    this.filtrosSelectorResumen.find(x=>seleccion.control==x.control).active = seleccion.active;
    this.filtrosSqp.find(x=>x.titulo==seleccion.control).valoresSeleccionados.valorBool = seleccion.active;
    this.datosFirmaService.filtrarNuevo(this.traducirFiltrosSqpAFiltrosFirmas());
    this.filtrarFirmasNuevoFiltro(true);
  }

  actualizarFiltrosDesdeFiltroLateralSqpEstado(estado:string){
    this.filtrosSqp.find(x=>x.titulo=='Estado').valoresSeleccionados.valorString = this.estadosFirmaService.estadoSeleccionado;
    this.datosFirmaService.filtrarNuevo(this.traducirFiltrosSqpAFiltrosFirmas());
    this.filtrarFirmasNuevoFiltro(true);
  }

  traducirFiltrosSqpAFiltrosFirmas():FiltroFirma[]{
    this.filtrosFirma = [];
    this.filtrosSqp.forEach(x=>{
      let filtro: FiltroFirma;
      switch(x.titulo){
        case 'Nuevos':
          filtro = {
            text: x.titulo,
            nuevos: x.valoresSeleccionados.valorBool
          }
          break
        case 'Sandbox':
          filtro = {
            text: 'Sandbox',
            isEntornoSandbox: x.valoresSeleccionados.valorBool
          }
          break
        case 'Tipo dosier':
          filtro = {
            text: 'Tipo dosier',
            listaValores: x.valoresSeleccionados?.listaValores?x.valoresSeleccionados?.listaValores:[]
          }
          break
        case 'Empresa':
          filtro = {
            text: 'Empresa',
            listaValores: x.valoresSeleccionados?.listaValores?x.valoresSeleccionados?.listaValores:[]
          }
          break
        case 'Centro':
          filtro = {
            text: 'Centro',
            listaValores: x.valoresSeleccionados?.listaValores?x.valoresSeleccionados?.listaValores:[]
          }
          break
        case 'Estado':
          filtro = {
            text: 'Estado',
            estado: x.valoresSeleccionados.valorString
          }
          break
        case 'Buscar':
          filtro = {
            text: 'Buscar',
            buscar:x.valoresSeleccionados.valorString
          }
          break
        case 'Fecha de envío':
          filtro = {
            text: 'Fecha de envío',
            fechaDesde: x.valoresSeleccionados?.valorFechaDesde,
            fechaHasta: x.valoresSeleccionados?.valorFechaHasta
          }
          break
        case 'Fecha contrato':
          filtro = {
            text: 'Fecha contrato',
            fechaDesde: x.valoresSeleccionados?.valorFechaDesde,
            fechaHasta: x.valoresSeleccionados?.valorFechaHasta
          }
          break

      }
      this.filtrosFirma.push(filtro)
    })
    return this.filtrosFirma
  }

}
