import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroSqp, ListaDropDown, VisualizacionDestacada } from '../../models/filtro-sqp';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { DesplegableData, SeleccionItems } from '../../../sqpDesplegable/models/desplegable-data';
import { BehaviorSubject } from 'rxjs';
import { TipoFiltro } from '../../models/tipo-filtro';

@Component({
  selector: 'app-filtro-superior-sqp',
  templateUrl: './filtro-superior-sqp.component.html',
  styleUrls: ['./filtro-superior-sqp.component.css']
})

export class FiltroSuperiorSqpComponent implements OnInit {

  @Input() filtrosSqp: FiltroSqp[] = [];
  @Output() filtroSqpModificadoEmitter: EventEmitter<FiltroSqp> = new EventEmitter<FiltroSqp>();
  mostrarLimpiarFiltrosSqp: boolean = false;
  listasDropDown: ListaDropDown[]=[];
  @Output() borrarTodosFiltrosEmitter: EventEmitter<[]> = new EventEmitter<[]>();
  titulosFecha: string[]=[];
  limpiarTodosFiltrosSqp:number=0;

  constructor(public datosFirmaService: DatosFirmaService,
              private cdr: ChangeDetectorRef
  ) {
  }

  //creamos unas lista nuevas de dropdowns para no pasarle las que hay dentro de los filtros, ya que cada vez que hay un cambio en los filtro se vuelven a pasar y eso hace que se inicialize constantemente el DropDown component y no se pueda filtrar bien.
  ngOnChanges(changes: SimpleChanges){
    if(changes){
      this.filtrosSqp.forEach(x=>{
        let lista: ListaDropDown;
        if(x.valoresDesplegable){
          if(!lista?.listaDesplegable || lista?.listaDesplegable?.length==0){
            lista = {
              titulo: x.titulo,
              listaDesplegable: [...x.valoresDesplegable]
            }
          }
          this.listasDropDown.push(lista)
        }
        if(x.tipoFiltro==TipoFiltro.FECHA){
          this.titulosFecha.push(x.titulo)
        }
      })
    }
  }

  ngOnInit(): void {
  }

  getSelectedItems(event: SeleccionItems | FiltroSqp){
    let filtroModificado: FiltroSqp;
    if('tipo' in event && (event.tipo == 'Tipo dosier' || event.tipo=='Empresa' || event.tipo=='Centro')){
      let valoresSeleccionados: string[] = [];
      event.items.forEach(x=> valoresSeleccionados.push(x.id))
      filtroModificado = {
        tipoFiltro: TipoFiltro.DESPLEGABLE,
        titulo: event.tipo,
        valoresDesplegable: this.filtrosSqp.find(x=>x.titulo==event.tipo).valoresDesplegable,
        valoresSeleccionados:{listaValores:valoresSeleccionados}
      }
      this.filtroSqpModificadoEmitter.emit(filtroModificado)
    }else if('titulo' in event) this.filtroSqpModificadoEmitter.emit(event)
  }

  buscarStringInputBuscador():string{
    let palabra;
    palabra = this.filtrosSqp.find(x=>x.titulo=='Buscar')?.valoresSeleccionados.valorString
    return palabra
  }

  limpiarFiltros(){
    this.borrarTodosFiltrosEmitter.emit([]);
    this.limpiarTodosFiltrosSqp++;
    this.cdr.detectChanges();
  }

  isFiltrosLimpios(): number {
    let contador:number = 0;
    this.filtrosSqp.forEach(x=>{
      if(x.tipoFiltro==1 && x.valoresSeleccionados?.listaValores?.length>0) contador++;
      if(x.tipoFiltro==0 && x.valoresSeleccionados?.valorBool) contador++;
      if(x.tipoFiltro==2 && (x.valoresSeleccionados?.valorFechaDesde !== null || x.valoresSeleccionados?.valorFechaHasta !== null)) contador++;
      if(x.titulo=='Buscar' && x.valoresSeleccionados?.valorString!=='') contador++;
    })
    return contador
  }

}
