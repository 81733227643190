<h1>Selección de Entorno</h1>
<div id="container">
  <app-input-buscador class="buscador" (buscador)="filtrarTenant($event)" [inputWidth]="'300'"></app-input-buscador>
  <div class="list">
    <div *ngFor="let item of tenants" class="option" (click)="Ingresar(item)">
      <input type="radio" [value]="item" name="tenant">{{item.Name}}
    </div>
  </div>
</div>

