<div class="container">
  <div id="configDiv" *ngIf="verConfigUsuario" class="flex" (click)="clickConfigUsuario()">
    <svg class="icon">
      <use xlink:href="#icon-conf-perfil"></use>
    </svg>
    <span>Configurar perfil</span>
  </div>
  <div id="tenantDiv" *ngIf="verCambioTenant && tenantDataArray.length>1" class="flex" (click)="clickCambioTenant()">
    <svg class="icon">
      <use xlink:href="#icon-switch"></use>
    </svg>
    <span>Cambiar de entorno</span>
  </div>
  <div class="flex" (click)="clickLogOut()">
    <svg class="icon">
      <use xlink:href="#icon-cerrar-sesion"></use>
    </svg>
    <span>Cerrar sesión</span>
  </div>
</div>

