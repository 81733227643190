import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationMessageData, TipoMensajeConfirmacion } from '../../models/confirmation-message-data';

@Component({
  selector: 'app-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrls: ['./confirmation-message.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ConfirmationMessageComponent implements OnInit {

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  error: string = '';
  mostrarBotonGuardarDesactivado: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: ConfirmationMessageData,
              public dialogRef: MatDialogRef<any>) { }

   ngOnInit(): void {
     this.inicializaFormulario();
     if(this.data.hasOwnProperty('extraInfo') && this.data.extraInfo.hasOwnProperty('boton')) this.mostrarBotonGuardarDesactivado = true;
     else this.mostrarBotonGuardarDesactivado = false;

   }

  inicializaFormulario(){
    this.formGroup = new UntypedFormGroup({
      confirmar: new UntypedFormControl(false,[Validators.requiredTrue])
    });
  }

  confirmarOk(): boolean{
    return this.formGroup.controls['confirmar'].valid;
  }

  confirmarError(){
    let errors = this.formGroup.controls['confirmar'].errors;
    if(errors && errors.required) return this.error;
    else return '';
  }

  getTitulo(){
    if(this.data.tipo == TipoMensajeConfirmacion.ConfirmacionSimple || this.data.tipo == TipoMensajeConfirmacion.ConfirmacionCompleja) return this.data.textoBoton;
    else if(this.data.tipo == TipoMensajeConfirmacion.Informacion) return 'Aceptar';
    else return 'Continuar';
  }

  getIcono(){
    if(this.data.tipo == TipoMensajeConfirmacion.ConfirmacionSimple || this.data.tipo == TipoMensajeConfirmacion.ConfirmacionCompleja) return this.data.iconoConfirmacion;
    else return '';
  }

  // Al procesar la accion devolvemos un string con el id del boton
  procesarAccion(event: any){

    if (this.data.tipo == TipoMensajeConfirmacion.ConfirmacionCompleja) {
      if(this.formGroup.valid) this.dialogRef.close('OK');
      else this.error = `Debe marcar ${this.data.textoBoton} si desea eliminar el registro seleccionado`;
    }
    // if (this.data.tipo == TipoMensajeConfirmacion.ConfirmacionSimple){
    //   if(this.formGroup.valid) this.dialogRef.close('OK');
    //   else this.error = `Debe marcar el icono ${this.data.textoConfirmacion} si desea continuar.`;
    // }
    else {
      this.dialogRef.close(event);
      // this.dialogRef.close('OK');
    }
  }

}
