import { Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { ErroresForm } from '../models/firmante-nif';
import { FormularioDocumentoService } from './formulario-documento.service';

@Injectable({
  providedIn: 'root'
})
export class FormularioValidacionesDocsService {

  mensajeErrorNombre: string = '';
  mensajeErrorCifPage: string = '';
  mensajeErrorCifOrden: string = '';
  mensajeErrorCifFormulario: string = '';
  mensajeErrorCifPosicion: string = '';
  mensajeErrorNifPage: string = '';
  mensajeErrorNifOrden: string = '';
  mensajeErrorNifFormulario: string = '';
  mensajeErrorTipoFirma: string = '';
  mensajeErrorTieneTag: string = '';
  mensajeErrorNombreTag: string = '';
  // mensajeErrorNombreCertificado: string = '';
  mensajeErrorPosicionFirmas: string = '';
  mensajeErrorNombreFormulario: string = '';
  mensajeErrorGenerico: string = '';
  mensajeErrorNombreArchivoFirmado: string = '';
  mensajeErrorNombreArchivoAudit: string = '';
  mensajeErrorSplit: string = '';
  mensajeErrorCopiaBasica: string = '';
  mensajeErrorTextoFormulario: string = '';
  mensajeErrorTextoAExcluirFormulario: string = '';

  constructor(private formularioDocumentoService: FormularioDocumentoService) { }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre']}
  get cifPage(){ return this.formularioDocumentoService.formularioDocumento.controls['cifPage']}
  get cifOrden(){ return this.formularioDocumentoService.formularioDocumento.controls['cifOrden']}
  get cifFormulario(){ return this.formularioDocumentoService.formularioDocumento.controls['cifFormulario']}
  get posicionCif(){ return this.formularioDocumentoService.formularioDocumento.controls['posicionCif']}
  get tipoFirma(){return this.formularioDocumentoService.formularioFirma.controls['tipoFirma']}
  get nombreCertificado(){return this.formularioDocumentoService.formularioFirma.get('nombreCertificado')}
  get tieneTag(){return this.formularioDocumentoService.formularioFirma.controls['tieneTag']}
  get nombreTag(){return this.formularioDocumentoService.formularioFirma.get('nombreTag')}
  get posicionFirmas(){return this.formularioDocumentoService.formularioFirma.get('posicionFirmas') as FormArray}
  get firmantesNif(){return this.formularioDocumentoService.formularioDocumento.get('firmantesNif') as FormArray}
  get generico(){return this.formularioDocumentoService.formularioDocumento.controls['generico']}
  get formatoNombreArchivoFirmado(){return this.formularioDocumentoService.formularioDocumento.controls['formatoNombreArchivoFirmado']}
  get formatoNombreArchivoAuditTrail(){return this.formularioDocumentoService.formularioDocumento.controls['formatoNombreArchivoAuditTrail']}
  get split(){return this.formularioDocumentoService.formularioDocumento.controls['split']}
  get esCopiaBasica(){return this.formularioDocumentoService.formularioDocumento.controls['esCopiaBasica']}
  get textoFormulario(){return this.formularioDocumentoService.formularioDocumento.controls['textoFormulario']}
  get textoAExcluirFormulario(){return this.formularioDocumentoService.formularioDocumento.controls['textoAExcluirFormulario']}


  //miramos si hay controles en el formulario
  // hayControles():boolean{
  //   let formControls = this.formularioDocumentoService.formularioDocumento.controls;
  //   let controls = Object.keys(formControls)
  //   if(controls.length == 0) return false
  //   else return true
  // }

  //comprobamos los errores antes de enviar el formulario y montamos el mensaje de error.
  isCampoValido(campo:string){
    // this.formularioDocumentoService.refrescaDocumento();
      switch(campo){
        case 'nombre':
            if(this.nombre.errors?.nombreVacio)
            this.mensajeErrorNombre = 'El nombre del documento es requerido.'
            if(this.nombre.errors?.nombreExiste)
            this.mensajeErrorNombre = 'El nombre del documento ya existe.'
            if(this.nombre.errors?.pattern)
            this.mensajeErrorNombre = 'El apóstrofo es un carácter inválido, se debe eliminar antes de continuar.'
            if(this.nombre.errors?.maxlength)
            this.mensajeErrorNombre = 'El nombre es demasiado largo, puede contener 250 caracteres como máximo.'
          break;
        case 'cifPage':
          if(this.cifPage.errors?.validadoresCifRequired){
            this.mensajeErrorCifPage = `El número de página es requerido.`
          }
          if(this.cifPage.errors?.validadoresCifTotalPages){
            this.mensajeErrorCifPage = `El número de página tiene que ser mayor que 0 y menor o igual a ${this.formularioDocumentoService.totalPages}.`
          }
          break;
        case 'cifOrden':
          if(this.cifOrden.errors?.validadoresCifRequired){
            this.mensajeErrorCifOrden = `El número de orden es requerido.`
          }
          if(this.cifOrden.errors?.validadoresCifOrdenMin){
            this.mensajeErrorCifOrden = `El número de orden tiene que ser mayor que 0.`
          }
          break;
        case 'cifFormulario':
          if(this.cifFormulario.errors?.validadoresCifFormulario){
            this.mensajeErrorCifFormulario = 'El nombre del formulario es requerido.'
          }
          break;
        case 'posicionCif':
          if(this.posicionCif.errors?.validadoresCifPosicion){
            this.mensajeErrorCifPosicion = 'Debe seleccionar el cif por página/orden o por formulario.'
          }
          break;
        case 'generico':
          if(this.generico.errors?.validadorGenerico){
            this.mensajeErrorGenerico = 'Requerido. Seleccione una opción.'
          }
          break;
        case 'formatoNombreArchivoFirmado':
          if(this.formatoNombreArchivoFirmado.errors?.validatorsArchivoFirmado){
            this.mensajeErrorNombreArchivoFirmado = 'La información específica incluida en el nombre del documento debe seguir el formato siguiente y debe acabar en ".pdf". Valores: {CODIGO_EMPRESA} {CODIGO_CENTRO} {CODIGO_EMPLEADO} {NOMBRE_EMPLEADO} {APELLIDO1_EMPLEADO} {APELLIDO2_EMPLEADO} {NIF_EMPLEADO} {EMAIL_EMPLEADO} {TELEFONO_EMPLEADO} {NOMBRE_DOCUMENTO} {FECHA_CONTRATO}'
          }
          if(this.formatoNombreArchivoFirmado.errors?.validatorsArchivoDuplicado){
            this.mensajeErrorNombreArchivoFirmado = 'El documento debe tener un nombre diferente al del audit.'
          }

          break;
        case 'formatoNombreArchivoAuditTrail':
          if(this.formatoNombreArchivoAuditTrail.errors?.validatorsArchivoAudit){
            this.mensajeErrorNombreArchivoAudit = 'La información específica incluida en el nombre del audit debe seguir el formato siguiente y debe acabar en ".pdf". Valores: {CODIGO_EMPRESA} {CODIGO_CENTRO} {CODIGO_EMPLEADO} {NOMBRE_EMPLEADO} {APELLIDO1_EMPLEADO} {APELLIDO2_EMPLEADO} {NIF_EMPLEADO} {EMAIL_EMPLEADO} {TELEFONO_EMPLEADO} {NOMBRE_DOCUMENTO} {FECHA_CONTRATO}';
          }
          if(this.formatoNombreArchivoAuditTrail.errors?.validatorsArchivoDuplicado){
            this.mensajeErrorNombreArchivoAudit = 'El audit debe tener un nombre diferente al del documento.'
          }
          break;
        case 'split':
          if(this.split.errors?.validatorsSplit){
            this.mensajeErrorSplit = `Indique separado por comas (",") los valores a partir de los que desea hacer el desglose (2,...,${this.formularioDocumentoService.totalPages})`
          }
          if(this.split.errors?.maxlength)
            this.mensajeErrorSplit = 'Cadena de texto demasiado larga, puede contener 50 caracteres como máximo.'
          break;
        case 'esCopiaBasica':
          if(this.esCopiaBasica.errors?.validatorsCopiaBasica){
            this.mensajeErrorCopiaBasica = 'Un documento genérico no puede ser copia básica, por favor tire para atrás y cambie la identificación del firmante principal o deschequee copia básica'
          }
          break;
        case 'textoFormulario':
          if(this.textoFormulario.errors?.maxlength){
            this.mensajeErrorTextoFormulario = 'Texto demasiado largo, puede contener 200 caracteres como máximo.'
          }
          break;
        case 'textoAExcluirFormulario':
          if(this.textoAExcluirFormulario.errors?.maxlength){
            this.mensajeErrorTextoAExcluirFormulario = 'Texto demasiado largo, puede contener 200 caracteres como máximo.'
          }
          break;
      }
      if(this.formularioDocumentoService.formularioDocumento.controls[campo]){
        return this.formularioDocumentoService.formularioDocumento.controls[campo].errors && this.formularioDocumentoService.formularioDocumento.controls[campo].touched;
      }else{
        return true;
      }
  }

  isCampoValidoFirma(campo:string){
    switch(campo){
      case 'tipoFirma':
          if(this.tipoFirma.errors?.validadoresTipoFirmaRequired){
            this.mensajeErrorTipoFirma = `Tipo de firma es requerido.`
          }
          break;
      }
      if(this.formularioDocumentoService.formularioFirma.controls[campo]){
        return this.formularioDocumentoService.formularioFirma.controls[campo].errors && this.formularioDocumentoService.formularioFirma.controls[campo].touched;
      }else{
        return true;
      }
  }

  //comprobamos los errores del firmanteArrayForm antes de enviar el fomulario y montamos los mensajes de error
  isCampoValidoArray(campo: string, index: number){
    if(this.formularioDocumentoService.firmantesNif.controls[index]){
      let nifPage = this.formularioDocumentoService.firmantesNif.controls[index].get('nifPage');
      let nifOrden = this.formularioDocumentoService.firmantesNif.controls[index].get('nifOrden');
      let nifFormulario = this.formularioDocumentoService.firmantesNif.controls[index].get('nifFormulario');
      let firmas = this.formularioDocumentoService.firmantesNif.controls[index].get('firmas');
      switch(campo){
        case 'nifPage':
            if(nifPage.errors?.validadoresNifRequired){
              this.mensajeErrorNifPage = 'Los dos valores son requeridos.';
              return true;
            }
            if(nifPage.errors?.validadoresNifTotalPages){
              this.mensajeErrorNifPage = `El número de página tiene que ser mayor que 0 y menor o igual a ${this.formularioDocumentoService.totalPages}.`;
              return true;
            }
            if(nifPage.errors?.validadoresNifduplicado){
              this.mensajeErrorNifPage = `No puede haber dos firmantes con el mismo Nif.`;
              return true;
            }
          break;
        case 'nifOrden':
          if(nifOrden.errors?.validadoresNifRequired){
            this.mensajeErrorNifOrden = `Los dos valores son requeridos.`;
            return true;
          }
          if(nifOrden.errors?.validadoresNifMin){
            this.mensajeErrorNifOrden = `El número de orden tiene que ser mayor que 0.`;
            return true;
          }
          if(nifOrden.errors?.validadoresNifduplicado){
            this.mensajeErrorNifOrden = '';
            return true;
          }
          break;
        case 'nifFormulario':
          if(nifFormulario.errors?.validadoresNifFormulario){
            this.mensajeErrorNifFormulario = `El nombre del formulario es requerido.`;
            return true;
          }
          if(nifFormulario.errors?.validadoresFormularioNifduplicado){
            this.mensajeErrorNifFormulario = `No puede haber dos firmantes con el mismo Formulario.`;
            return true;
          }
          break;
        case 'firmas':
          if(firmas.errors?.validadoresTipoFirmaRequired)
            this.mensajeErrorTipoFirma = `Tipo de firma es requerido.`;
          if(firmas.errors?.validadoresRLTSinFirma)
            this.mensajeErrorTipoFirma = 'No se puede seleccionar "Sin Firma" en un documemnto RLT.';
          if(firmas.errors?.validadoresTagRequired)
            this.mensajeErrorTieneTag = `Campo requerido.`;
          if(firmas.errors?.validadoresNombreTagRequired)
            this.mensajeErrorNombreTag = `Nombre del tag requerido.`;
          // if(firmas.errors?.validadoresNombreCertificadoRequired)
          //   this.mensajeErrorNombreCertificado = `Nombre del certificado requerido.`;
          if(firmas.errors?.validadoresPosicionFirmasRequired)
            this.mensajeErrorPosicionFirmas = `Se necesita añadir como mínimo una firma.`;
          if(firmas.errors?.validadoresNombreTagTieneEspacios)
            this.mensajeErrorNombreTag = `El nombre del TAG no puede contener espacios. Revisa también que no haya espacios al principio o final del nombre.`;
          if(firmas.errors?.validadoresNombreTagLargo)
            this.mensajeErrorNombreTag = `El nombre del TAG no puede contener más de 200 caracteres.`;
          break;
      }
      return this.formularioDocumentoService.firmantesNif.controls[index].get(campo)?.errors && this.formularioDocumentoService.firmantesNif.controls[index].get(campo)?.touched;
    }else{
      return true
    }
  }

  getFormValidationErrors(): ErroresForm[]{
    let erroresForm: ErroresForm[] = [];
    let objetoError: ErroresForm;
    this.firmantesNif.controls.forEach((x, index) => {
      let controlKey = this.firmantesNif.controls[index] as FormGroup;
      Object.keys(controlKey.controls).forEach((key:string) => {
        const controlErrors: ValidationErrors = controlKey.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {

          objetoError = {firmante: x.value.tipoFirmante, error: keyError}
          erroresForm.push(objetoError);
          });
        }
      });
      if(controlKey.controls['formularios'].value.length > 0){
        let formularioKey = controlKey.controls['formularios'] as FormArray;
        Object.keys(formularioKey.controls).forEach((keyF:string)=>{
          const controlFormularioErrors: ValidationErrors = formularioKey.get(keyF).errors;
          if (controlFormularioErrors != null) {
            Object.keys(controlFormularioErrors).forEach(keyError => {
            objetoError = {firmante: x.value.tipoFirmante, error: keyError}
            erroresForm.push(objetoError);
            });
          }
        })
      }
    })
    return erroresForm;
  }

  isCampoValidoArrayFormularios(indexMostrar: number, indexNif: number){
    let firmanteNif = this.formularioDocumentoService.firmantesNif.at(indexNif) as FormGroup;
    let formularios = firmanteNif?.controls['formularios'] as FormArray;
    if(formularios.at(indexMostrar)){
      if(formularios.at(indexMostrar).errors?.nombreFormularioVacio)
        this.mensajeErrorNombreFormulario = 'El nombre del formulario es requerido.';
      if(formularios.at(indexMostrar).errors?.nombreFormularioExiste)
        this.mensajeErrorNombreFormulario = 'El nombre del formulario ya existe.';
      if(formularios.at(indexMostrar).errors?.faltanCasillas)
        this.mensajeErrorNombreFormulario = 'Se tiene que añadir como mínimo un campo en el documento por cada formulario.';
      return formularios.at(indexMostrar)?.errors && formularios.at(indexMostrar)?.touched;
    }else return true;
  }

}
