import { Component, OnInit } from '@angular/core';
import { OpcionesMenuLateral } from '../../sqp-components/sqpMenuLateral/models/opcionesMenuLateral';

@Component({
  selector: 'app-standar-layout',
  templateUrl: './standar-layout.component.html',
  styleUrls: ['./standar-layout.component.css']
})
export class StandarLayoutComponent implements OnInit {

  menuVisible: boolean = true;
  itemsMenu: OpcionesMenuLateral[] = [
    {icono:'#hs-home', ruta: '/panel/main'},
    {icono:'#hs-list', ruta: '/panel/envios'},
    {icono:'#hs-send', ruta: '/panel/subida'},
    {icono:'#hs-employees', ruta: '/panel/configuracion/empleados'},
    {icono:'#hs-tools', ruta: '/panel/opciones-configuracion'}
  ]

  constructor() { }

  ngOnInit(): void {
    //QUitamos el pie
    document.body.classList.add('no-footer');
  }

  plegarMenu(event: boolean){
    this.menuVisible = event;
  }

}
