import { Type } from "@angular/core";
import { DocumentoPdfConfiguracionApiService } from "src/app/pages/configuracion/Envios/documentos/services/documento-pdf-configuracion-api.service";
import { DocumentoPdfConfiguracionMockService } from "src/app/pages/configuracion/Envios/documentos/services/documento-pdf-configuracion-mock.service";
import { RecuperaTipoDocumentoApiService } from "src/app/pages/configuracion/Envios/documentos/services/recupera-tipo-documento-api.service";
import { RecuperaTipoDocumentoMockService } from "src/app/pages/configuracion/Envios/documentos/services/recupera-tipo-documento-mock.service";
import { RecuperaTiposContenidoApiService } from "src/app/pages/configuracion/Envios/documentos/services/recupera-tipos-contenido-api.service";
import { RecuperaTiposContenidoMockService } from "src/app/pages/configuracion/Envios/documentos/services/recupera-tipos-contenido-mock.service";
import { IGestionarTiposEnvioService } from "src/app/pages/configuracion/Envios/envios/services/ports/igestionar-tipos-envio-service";
import { RecuperaTipoEnvioApiService } from "src/app/pages/configuracion/Envios/envios/services/recupera-tipo-envio-api.service";
import { RecuperaTipoEnvioMockService } from "src/app/pages/configuracion/Envios/envios/services/recupera-tipo-envio-mock.service";
import { RecuperaCentrosApiService } from "src/app/pages/configuracion/maestros/centros/services/recupera-centros-api.service";
import { RecuperaCentrosMockService } from "src/app/pages/configuracion/maestros/centros/services/recupera-centros-mock.service";
import { RecuperaEmpresasApiService } from "src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-api.service";
import { RecuperaEmpresasMockService } from "src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-mock.service";
import { GestionaEmpleadosApiService } from "src/app/pages/empleados/services/gestiona-empleados-api.service";
import { GestionaEmpleadosMockService } from "src/app/pages/empleados/services/gestiona-empleados-mock.service";
import { GestionaFirmasApiService } from "src/app/pages/panelControl/services/gestiona-firmas-api.service";
import { GestionaFirmasMockService } from "src/app/pages/panelControl/services/gestiona-firmas-mock.service";
import { RecuperaUsuariosApiService } from "src/app/pages/seguridad/services/recupera-usuarios-api.service";
import { RecuperaUsuariosMockService } from "src/app/pages/seguridad/services/recupera-usuarios-mock.service";
import { GestionaDatosSubidaApiService } from "src/app/pages/subidaPrincipal/services/gestiona-datos-subida-api.service";
import { GestionaDatosSubidaMockService } from "src/app/pages/subidaPrincipal/services/gestiona-datos-subida-mock.service";
import { TiposConfiguracion } from "src/environments/Configuration/TiposConfiguracion";
import { environment } from "src/environments/environment";
import { ListaEndpointsV2Service } from "./lista-endpoints-v2.service";
import { ListaEndpointsV3Service } from "./lista-endpoints-v3.service";
import { RecuperaConfiguracionAplicacionMockService } from "src/app/pages/configuracion/configuracion-rlt/services/recupera-configuracion-aplicacion-mock.service";
import { RecuperaConfiguracionAplicacionApiService } from "src/app/pages/configuracion/configuracion-rlt/services/recupera-configuracion-aplicacion-api.service";
import { RecuperaEmpleadosMockService } from "src/app/pages/empleados/services/mantenimiento/recupera-empleados-mock.service";
import { RecuperaEmpleadosApiService } from "src/app/pages/empleados/services/mantenimiento/recupera-empleados-api.service";
import { BarraSuperiorService } from "./barra-superior.service";
import { MenuLateralService } from "../components/sqp-components/sqpMenuLateral/services/menu-lateral.service";

export class InyeccionDependenciasFactory{

    static get GetRecuperaMenuLateral():Type<any>{
      return MenuLateralService;
    }

    static get GetRecuperaBarraSuperior():Type<any>{
      return BarraSuperiorService;
    }

    static get GetRecuperaTipoEnvio():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaTipoEnvioMockService;
        }else{
            return RecuperaTipoEnvioApiService;
        }
    }

    static get GetRecuperaTipoDocumento():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaTipoDocumentoMockService;
        }else{
            return RecuperaTipoDocumentoApiService;
        }
    }
     static get GetRecuperaCentros():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaCentrosMockService;
        }else{
            return RecuperaCentrosApiService;
        }
    }

    static get GetRecuperaEmpresas():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaEmpresasMockService;
        }else{
            return RecuperaEmpresasApiService;
        }
    }
    static get GestionaEmpleados():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return GestionaEmpleadosMockService;
        }else{
            return GestionaEmpleadosApiService;
        }
    }
    static get GetGestionaFirmas():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return GestionaFirmasMockService;
        }else{
            return GestionaFirmasApiService;
        }
    }
    static get GetGestionaDatosSubida():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return GestionaDatosSubidaMockService;
        }else{
            return GestionaDatosSubidaApiService;
        }
    }
    static get GetDocumentoPdfConfiguracion():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return DocumentoPdfConfiguracionMockService;
        }else{
            return DocumentoPdfConfiguracionApiService;
        }
    }

    static get GetRecuperaTiposContenido():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaTiposContenidoMockService;
        }else{
            return RecuperaTiposContenidoApiService;
        }
    }

    static get GetRecuperaUsuarios():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaUsuariosMockService;
        }else{
            return RecuperaUsuariosApiService;
        }
    }

    static get GetRecuperaConfiguracionAplicacion():Type<any>{
        if((environment.Configuracion==TiposConfiguracion.Mocks)){
            return RecuperaConfiguracionAplicacionMockService;
        }else{
            return RecuperaConfiguracionAplicacionApiService;
        }
    }

    static get GetListaEndpints():Type<any>{
        switch(environment.Configuracion){
            case TiposConfiguracion.Mocks:
            case TiposConfiguracion.V2:
                return ListaEndpointsV2Service;
            default:
                return ListaEndpointsV3Service
        }
    }

    static get GetRecuperaEmpleados():Type<any>{
      if((environment.Configuracion==TiposConfiguracion.Mocks)){
          return RecuperaEmpleadosMockService;
      }else{
          return RecuperaEmpleadosApiService;
      }
  }
}
