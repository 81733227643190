<div class="modal-msg">
  <svg class="icon-close-msg" mat-dialog-close>
    <use xlink:href="#icon-close"></use>
  </svg>
  <h1 class="titulo-msg">{{data.titulo}}</h1>
  <h2>{{data.soloMensaje}}</h2>
  <div>
    <app-alerta-accion-irreversible *ngIf="data.tipo == 1 || data.tipo == 3"></app-alerta-accion-irreversible>
    <div class="modal-text" [innerHTML]="data.mensaje"></div>
    <div *ngIf="data.tipo == 1">
      <form [formGroup]="formGroup">
        <!-- <p>{{data.preguntaConfirmacion}}</p> -->
        <div class="check-confirmacion">
          <input type="checkbox" formControlName="confirmar">
          <label for="confirmar">{{data.textoConfirmacion}}</label>
        </div>
        <mat-error *ngIf="!confirmarOk() && confirmarError()!==''">{{confirmarError()}}</mat-error>
      </form>
    </div>
  </div>
  <div class="modal-buttons-msg">
    <div *ngIf="data.tipo == 0 || data.tipo == 1">
      <app-boton-accion *ngIf="!mostrarBotonGuardarDesactivado && data.tipo !== 0" mat-dialog-close [id]="'cancelar'" [titulo]="'cancelar'" [outline]="true" [cancelar]="true"></app-boton-accion>
      <app-boton-accion *ngIf="!mostrarBotonGuardarDesactivado && data.tipo == 0" mat-dialog-close [id]="data.textoCancelar" [titulo]="data.textoCancelar" [outline]="true" [cancelar]="true"></app-boton-accion>
      <app-boton-accion
      (click)="procesarAccion('desactivar')" *ngIf="mostrarBotonGuardarDesactivado" [id]="'guardar-desactivado'" [titulo]="data.extraInfo.boton" [outline]="true" [cancelar]="true"></app-boton-accion>
    </div>
    <app-boton-accion (click)="procesarAccion('OK')" [id]="data.textoConfirmacion"   [titulo]="data.textoBoton ? data.textoBoton:data.textoConfirmacion"></app-boton-accion>
    <!-- <app-boton-accion (click)="procesarAccion($event)" [id]="data.textoConfirmacion" [icono]="data.iconoConfirmacion" [titulo]="data.textoConfirmacion"></app-boton-accion> -->
  </div>
</div>
