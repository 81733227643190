import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { TipoDocumento } from '../../../documentos/models/tipo-documento';
import { FormularioEnvioService } from '../../services/formulario-envio.service';
import { FormularioValidacionesService } from '../../services/formulario-validaciones.service';
import { TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';

@Component({
  selector: 'app-grupo-docs',
  templateUrl: './grupo-docs.component.html',
  styleUrls: ['./grupo-docs.component.css']
})
export class GrupoDocsComponent implements OnInit, OnChanges{

  @Input() totalDocumentos: TipoDocumento[] = [];
  totalDocsFusion: TipoDocumento[] = [];
  totalDocsNoFusion: TipoDocumento[] = [];
  dragging: boolean = false;
  @Output() docEliminadoEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formularioEnvioService: FormularioEnvioService,
              public formularioValidacionesService: FormularioValidacionesService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>) {

   }

  //cargamos los documentos actualizados y los dividimos entre fusionables y no fusionables
  ngOnChanges(changes: SimpleChanges): void {
    this.totalDocumentos = changes.totalDocumentos.currentValue;
    this.documentosFusionables();
  }

  ngOnInit(): void {
  }

  //eliminamos el doc del arrayForm y del totalDocumentos, los separamos otra vez, reordenamos los fusionables y los juntamos otra vez para que quede un orden final y se pueda mandar a guardar si es necesario.
  eliminarDoc(doc:TipoDocumento){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
        icono: 'icon-delete',
        titulo: '¿Eliminar documento?',
        preguntaConfirmacion: '¿Está seguro que desea eliminar el documento?',
        textoConfirmacion: 'Eliminar el documento seleccionado',
        textoBoton: 'Eliminar',
        iconoConfirmacion: 'icon-delete'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        let index = this.totalDocumentos.findIndex(x => x.idTipoDocumento == doc.idTipoDocumento)
        this.totalDocumentos = this.formularioEnvioService.deleteDocumento(index)
        this.docEliminadoEmitter.emit();
        this.documentosFusionables();
        this.juntarDocs();
      }
    });

  }

  //creamos dos arrays para documentos fusionados y no fusionados y reordenamos los fusionados
  documentosFusionables(){
    this.totalDocumentos.sort((a, b) => a.ordenFusion - b.ordenFusion);
    this.totalDocsFusion = this.totalDocumentos.filter(x => x.fusionable)
    this.totalDocsNoFusion = this.totalDocumentos.filter(x => !x.fusionable)
    //this.reordenarFusion();
  }

  // drop(event: CdkDragDrop<string[]>) {
  drop(event: CdkDragDrop<TipoDocumento[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.marcarFusionables();
    this.reordenarFusion();
    this.juntarDocs();

    this.dragging = false;
  }

  //reordenamos los documentos fusionables
  reordenarFusion(){
    this.totalDocsFusion.map(x => x.ordenFusion = this.totalDocsFusion.indexOf(x)+1);
  }

  //actualizamos como fusionables o no dependiendo de en que array se encuentren.
  marcarFusionables(){
    this.totalDocsFusion.map(x => x.fusionable = true);
    this.totalDocsNoFusion.map(x => {
      x.fusionable = false;
      x.ordenFusion = null;
    });
  }

  //juntamos los docs fusionables y los no fusionables en el totalDocumentos array y actualizamos el formularioEnvio
  juntarDocs(){
    this.totalDocumentos = this.totalDocsFusion.concat(this.totalDocsNoFusion)
    this.formularioEnvioService.updateOrdenDocsInForm(this.totalDocumentos)
  }

  // onDragStarted(doc: TipoDocumento){

  //   this.dragging = true;
  // }

  isDragging = false;

  onDragStarted(doc:any) {
    doc.isDragging = true;
  }

  onDragEnded(doc:any) {
    doc.isDragging = false;
  }
}
