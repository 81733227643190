import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoFirmante, TipoFirmanteCopiaBasica, TipoFirmanteNifEmitter } from '../../../envios/models/tipo-firmante';
import { DatosEnviosService } from '../../../envios/services/datos-envios.service';
import { FirmanteNif } from '../../models/firmante-nif';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-configuracion-documento-configurar-nif',
  templateUrl: './configuracion-documento-configurar-nif.component.html',
  styleUrls: ['./configuracion-documento-configurar-nif.component.css']
})
export class ConfiguracionDocumentoConfigurarNifComponent implements OnInit {

  tiposFirmantes: TipoFirmanteCopiaBasica[] = []; //TipoFirmante[];
  @Input() idPdf: string;
  @Output() cargaNifEmitter:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private datosEnvioService: DatosEnviosService,
              private _snackBar: MatSnackBar,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) {
                this.getTipoFirmantes()
              }

  ngOnInit(): void {
  }

  get generico(){ return this.formularioDocumentoService.formularioDocumento.controls['generico'] }

  get esCopiaBasica(){ return this.formularioDocumentoService.formularioDocumento.controls['esCopiaBasica'] }

  getTipoFirmantes(){
    this.datosEnvioService.getTipoFirmante().subscribe(
      next => {
        this.tiposFirmantes = next;
        //montamos el noExistenNifs[] con los firmantes existentes para que muestre error en los Nifs no encontrados
        this.datosConfiguracionDocumentosService.noExistenNifs = [];
        this.tiposFirmantes.forEach(x=>{
          this.datosConfiguracionDocumentosService.noExistenNifs.push(false)
          x.checkSiCopiaBasica = false;
        })
        if(this.esCopiaBasica.value){
          this.tiposFirmantes.forEach(x=>{
            if(x.codigo=='100') x.checkSiCopiaBasica = true;
            else x.checkSiCopiaBasica = false;
          })
        }
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  cargandoNifs: TipoFirmanteNifEmitter[] = [];

  isNifCargando(event:TipoFirmanteNifEmitter){
    let indexFirmante = this.cargandoNifs.findIndex(x => x.firmante==event.firmante);
    if(indexFirmante > -1){
      this.cargandoNifs[indexFirmante] = event;
    }else{
      this.cargandoNifs.push(event)
    }
    let arrayNifBoolean: boolean[] = [];
    this.cargandoNifs.forEach(x=> arrayNifBoolean.push(x.cargando))
    if(arrayNifBoolean.includes(true)) this.cargaNifEmitter.emit(true);
    else this.cargaNifEmitter.emit(false)
  }

}
