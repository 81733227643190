import { Component, Inject, Input, OnInit } from '@angular/core';
import { DatosEmpresasService } from '../../services/datos-empresas.service';
import { EmpresasModel } from '../../models/empresas-model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { RecuperaEmpresasApiService } from '../../services/recupera-empresas-api.service';
import { validacionCodigoEditar } from '../../services/custom-validators-empresas';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-empresas-modal',
  templateUrl: './empresas-modal.component.html',
  styleUrls: ['./empresas-modal.component.css']
})
export class EmpresasModalComponent implements OnInit {

  formEmpresa: FormGroup = new FormGroup({});
  isEditar: boolean = false;
  tituloModal: string ='';
  empresas: EmpresasModel[] = [];
  nuevo: boolean = false;
  empresa: EmpresasModel;

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA)
              public info: any,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private datosEmpresasService: DatosEmpresasService,
              private datosEmpresasApiService: RecuperaEmpresasApiService) {
                this.iniciarFormularioVacio();
   }

  ngOnInit(): void {
    this.empresa = this.info.empresa;
    this.nuevo = this.info.nuevo;
    this.iniciarFormularioEmpresa();
    this.tituloModal = 'Detalle de empresa';
    this.getEmpresas();
  }

  getEmpresas(){
    //llamamos directamente al servicio de la api, porque no reconoce getEmpresas() como función al pasar por la interface IRecuperarEmpresasService
    this.datosEmpresasApiService.getEmpresas().subscribe(
      next => {
        this.empresas = next;
        this.iniciarFormularioEmpresa();
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  iniciarFormularioVacio(){
    this.formEmpresa = this.fb.group({
      nombreEmpresa:[],
      nombreEmpleado:[],
      apellido1Empleado:[],
      apellido2Empleado:[],
      emailEmpleado:[],
      codigoEmpresa:[],
      cif: [],
      tipoDocumento: []
    })
  }

  iniciarFormularioEmpresa(){
    this.formEmpresa = this.fb.group({
      nombreEmpresa:[this.empresa.nombreEmpresa, Validators.required],
      nombreEmpleado:[this.empresa.nombreEmpleado],
      apellido1Empleado:[this.empresa.apellido1Empleado],
      apellido2Empleado:[this.empresa.apellido2Empleado],
      emailEmpleado:[this.empresa.emailEmpleado, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)],
      codigoEmpresa:[this.empresa.codigoEmpresa],
      cif: [this.empresa.cif, [Validators.required, validacionCodigoEditar(this.empresas, this.empresa, 'cif')]],
      tipoDocumento: [this.empresa.tipoDocumento],
    })
  }

  get nombreEmpresa(){ return this.formEmpresa.controls['nombreEmpresa']}
  get codigoEmpresa(){ return this.formEmpresa.controls['codigoEmpresa']}
  get cif(){ return this.formEmpresa.controls['cif']}
  get nombreEmpleado(){ return this.formEmpresa.controls['nombreEmpleado']}
  get apellido1Empleado(){ return this.formEmpresa.controls['apellido1Empleado']}
  get apellido2Empleado(){ return this.formEmpresa.controls['apellido2Empleado']}
  get emailEmpleado(){ return this.formEmpresa.controls['emailEmpleado']}
  get tipoDocumento(){ return this.formEmpresa.controls['tipoDocumento']}

  editarEmpresa(){
    this.isEditar = true;
    this.tituloModal = 'Edición de empresa';
  }

  guardarCambiosEmpresa(){
    if(this.formEmpresa.invalid){
      this.formEmpresa.markAllAsTouched();
      return
    }

    this.empresa = {
      codigoEmpresa:this.codigoEmpresa.value,
      tipoDocumento:this.tipoDocumento.value,
      cif:this.cif.value,
      nombreEmpresa:this.nombreEmpresa.value,
      nombreEmpleado:this.nombreEmpleado.value,
      emailEmpleado:this.emailEmpleado.value,
      apellido1Empleado:this.apellido1Empleado.value,
      apellido2Empleado:this.apellido2Empleado.value,
      logo:''
    }


    const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
    this.datosEmpresasApiService.guardaEmpresa(this.empresa).subscribe(
      next => {
        dialogRefSpinner.close();
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Empresa guardada correctamente',
          },
        });
        this.dialogRef.close('OK');
        this.isEditar = !this.isEditar;
      },
      error => {
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Error. La empresa no se ha guardado',
            alert: true
          },
        });
        this.dialogRef.close();
      });
  }

  // eliminarEmpresa(){
  //   const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
  //     data: {
  //       tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
  //       icono: 'hs-bin',
  //       titulo: '¿Eliminar empresa?',
  //       preguntaConfirmacion: '¿Eliminar empresa?',
  //       textoConfirmacion: 'Eliminar la empresa seleccionada',
  //       textoBoton: 'Eliminar',
  //       iconoConfirmacion: 'hs-bin'
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result === 'OK'){
  //       this.datosEmpresasApiService.deleteEmpresa(this.empresa).subscribe(
  //         next => {
  //           const dialogRef = this.dialog.open(ModalMessageComponent, {
  //           data: {
  //             mensaje: 'Empresa eliminada correctamente',
  //           },
  //           });
  //           this.dialogRef.close('OK');
  //         },
  //         error => {
  //           const dialogRef = this.dialog.open(ModalMessageComponent, {
  //             data: {
  //               mensaje: 'Error. La empresa no se ha eliminado',
  //               alert: true
  //             },
  //           });
  //           this.dialogRef.close();
  //         }
  //       );
  //     }
  //   });

  // }

  isCampoValido(campo:string){
    return this.formEmpresa.controls[campo].errors && this.formEmpresa.controls[campo].touched;
  }


}
