import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatosCentrosService } from '../../services/datos-centros.service';
import { CentrosModel } from '../../models/centros-model';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-centros-upload-excel-page',
  templateUrl: './centros-upload-excel-page.component.html',
  styleUrls: ['./centros-upload-excel-page.component.css']
})
export class CentrosUploadExcelPageComponent implements OnInit {

  centros: any[]=[];
  @Output() refreshEmitter: EventEmitter<any> = new EventEmitter<any>()

  constructor(private datosCentrosService: DatosCentrosService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  async uploadFiles(files: FileList){
    if(!this.datosCentrosService.validateConfiguration()) return;
    this.centros = await this.datosCentrosService.readFile(files.item(0));
    if(this.centros){
      this.guardaCentros(this.centros);
    }

  }

  guardaCentros(centros: CentrosModel[]){
    this.datosCentrosService.guardaCentros(centros).subscribe(
      ok => {
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Documento de Centros subido correctamente',
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          this.datosCentrosService.refreshCentros = true;
          this.refreshEmitter.emit();
        })

      },
      error => {
        console.error("error", error);
      },
      () => {
        // this.datosCentrosService.refreshCentros = true;
        // this.router.navigate(['/panel/configuracion/centros']);
      }
    );
  }

}
