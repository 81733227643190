import { Injectable } from '@angular/core';
import { IDatosEmpleadosService } from './i-datos-empleados-service';
import { Observable } from 'rxjs';
import { EmpleadoCrud } from '../../models/empleado';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaEmpleadosMockService implements IDatosEmpleadosService{

  empleados: EmpleadoCrud[];

  constructor(private httpClient: HttpClient,
              private conversionDatosApiService: ConversionDatosApiService) { }
  getConfiguracionAplicacion(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getEmpleados(refresh?: boolean): Observable<EmpleadoCrud[]> {
    return this.httpClient.get(`${environment.ApiEmpleadosDataMock}`).pipe(
      map( (response: any) =>{
        this.empleados = response
        return this.empleados
      })
    );
  }

  addEmpleado(data: any): Observable<void> {
    throw new Error('Method not implemented.');
  }

  updateEmpleado(data: any): Observable<void> {
    data = this.conversionDatosApiService.mapUpdateEmpleadoSalida(data);

    throw new Error('Method not implemented.');
  }
  deleteEmpleado(codigo: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
