import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TipoDocumento } from '../../../documentos/models/tipo-documento';
import { ErrorEnvio } from '../../models/error-envio';
import { FormularioValidacionesService } from '../../services/formulario-validaciones.service';
import { TipoEnvio } from '../../models/tipo-envio';
import { ConfirmationMessageData, TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';

@Component({
  selector: 'app-envios-confirmacion-firmantes-modal',
  templateUrl: './envios-confirmacion-firmantes-modal.component.html',
  styleUrls: ['./envios-confirmacion-firmantes-modal.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class EnviosConfirmacionFirmantesModalComponent implements OnInit {

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  error: string = '';

  nombreEnvio: string = '';
  numFirmantesEnvio: number;
  firmanteTexto: string = '';
  arrayDocumentos: TipoDocumento[] = [];
  numDocsAdjuntos: number;
  docsTexto: string = '';
  erroresForm: ErrorEnvio[]=[];
  sinFirmante: boolean = false;
  fusionadoText: string = '';
  isFusionado: boolean = false;
  isValidadorError: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: ConfirmationMessageData,
              public dialogRef: MatDialogRef<any>,
              private formularioValidacionesService: FormularioValidacionesService) { }

   ngOnInit(): void {
     this.inicializaFormulario();
     this.getInfoFirmantes();
     this.getInfoDocumentos();
     this.erroresForm = this.formularioValidacionesService.getFormValidationErrors();
   }

   getInfoFirmantes(){
    this.arrayDocumentos=[];
    this.nombreEnvio = this.data.extraInfo.infoEnvio.nombre;
    this.numFirmantesEnvio = this.data.extraInfo.infoEnvio.firmantes.length;
    if(this.numFirmantesEnvio == 1) this.firmanteTexto = "firmante";
    else this.firmanteTexto = "firmantes";
    this.data.extraInfo.infoEnvio.tiposDocumento.forEach((x: TipoDocumento)=>{
      this.arrayDocumentos.push(x);
      if(x.fusionable==false){
        // this.arrayDocumentos.push(x);
        this.isFusionado = false;
      }else{
        this.isFusionado = true;
      }
    })
    // this.arrayDocumentos = this.data.extraInfo.infoEnvio.tiposDocumento;
    if(this.arrayDocumentos.length==0) this.data.titulo = '¿Desea guardar el dosier sin documentos?';
    this.numDocsAdjuntos = this.arrayDocumentos.length;
    if(this.numDocsAdjuntos == 1) {
      this.docsTexto = 'documento adjunto';
      this.fusionadoText = 'fusionado';
    }else{
      this.docsTexto = 'documentos adjuntos';
      this.fusionadoText = 'fusionados';
    }

   }

   getInfoDocumentos(){
    this.data.extraInfo.infoEnvio.tiposDocumento.forEach((x:TipoDocumento) => {
      if(x.firmantesNif == null) this.sinFirmante = true;
      else this.sinFirmante = false;
    })
   }

   getErroresEnvioForm(){
    this.formularioValidacionesService.getFormValidationErrors();
   }

  inicializaFormulario(){
    this.formGroup = new UntypedFormGroup({
      confirmar: new UntypedFormControl(false,[Validators.requiredTrue])
    });
  }

  confirmarOk(): boolean{
    return this.formGroup.controls['confirmar'].valid;
  }

  confirmarError(){
    let errors = this.formGroup.controls['confirmar'].errors;
    if(errors && errors.required) return this.error;
    else return '';
  }

  getTitulo(){
    if(this.data.tipo == TipoMensajeConfirmacion.ConfirmacionSimple || this.data.tipo == TipoMensajeConfirmacion.ConfirmacionCompleja) return this.data.textoConfirmacion;
    else if(this.data.tipo == TipoMensajeConfirmacion.Informacion) return 'Aceptar';
    else return 'Continuar';
  }

  getIcono(){
    if(this.data.tipo == TipoMensajeConfirmacion.ConfirmacionSimple || this.data.tipo == TipoMensajeConfirmacion.ConfirmacionCompleja) return this.data.iconoConfirmacion;
    else return '';
  }

  // Al procesar la accion devolvemos un string con el id del boton
  procesarAccion(event: any){

    if (this.data.tipo == TipoMensajeConfirmacion.ConfirmacionCompleja) {
      if(this.formGroup.valid) this.dialogRef.close('OK');
      else this.error = `Debe marcar el icono ${this.data.textoConfirmacion} si desea eliminar el registro seleccionado`;
    }
    // if (this.data.tipo == TipoMensajeConfirmacion.ConfirmacionSimple){
    //   if(this.formGroup.valid) this.dialogRef.close('OK');
    //   else this.error = `Debe marcar el icono ${this.data.textoConfirmacion} si desea continuar.`;
    // }
    else {
      //this.dialogRef.close(event);
      this.dialogRef.close('OK');
    }
  }

  // checkSiValidadorError(tipoDoc: TipoDocumento){
  //   this.data.extraInfo.infoEnvio.firmantes.forEach(x=>{
  //     tipoDoc.firmantesNif.forEach((y:any)=>{
  //       if(x.tipoFirmante.codigo==y.tipoFirmante.codigo &&
  //          ((x.tipoDestinatario.codigo!=="validator" && y.tipoDestinatario.codigo=="validator") ||
  //           (x.tipoDestinatario.codigo=="validator" && y.tipoDestinatario.codigo!=="validator") )) {
  //         this.errorValidador=true;
  //       }
  //       else if((y.tipoDestinatario.codigo=="validator" && y.formularios.length>0) ||
  //               (y.tipoDestinatario.codigo=="validator" && y.camposEditables.length>0)) this.errorValidador=true;
  //     })
  //   })
  // }
}
