import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TenantData } from 'src/app/shared/components/sqp-components/sqpLogin/models/TenantData';

@Component({
  selector: 'app-selector-tenants',
  templateUrl: './selector-tenants.component.html',
  styleUrls: ['./selector-tenants.component.css']
})
export class SelectorTenantsComponent implements OnInit {

 public tenants:TenantData[] =[{Name:'',Tenant :''}];
 public token:string ='';
 public tenantsInicio: TenantData[] = [];

  constructor(private route: ActivatedRoute,
              private snack: MatSnackBar,
              private loginService: LoginService,
              private datosFirmasService: DatosFirmaService,
              private router: Router) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');

    let tokenINfo = this.loginService.DecodeTokenLogin(this.token);

    this.tenants = JSON.parse(tokenINfo.Tenant);

    this.tenantsInicio = [...this.tenants];
  }

  Ingresar(event:any){
    const selectTenant = event.Tenant
    const tenant = this.tenants.find(x=>x.Tenant==selectTenant);
    this.loginService.setTenantToken(event, this.token)
    this.loginService.LoginAzureAd(tenant,this.token,this.tenants).subscribe(
      ok => {

        this.datosFirmasService.GetFirmas().subscribe(
          ok => {

            // Redirigir al menu principal

            // this.datosFirmasService.Filter();
            this.datosFirmasService.filtrarNuevo(this.datosFirmasService.filtrosConEstado);
          },
          error => {
            console.error(error);
          });

          //MIentras recuperamos las firmas vamos al menu principal.
          this.router.navigate(["/panel/main"]);
      },
      error =>{
        // Mostrar mensaje de error
        console.error(error);
        let errorMsg = (error.status == 401) ? 'Usuario y/o contraseña no válidos' : error.message;

        this.snack.open(errorMsg, 'Cerrar', {duration: 3000});
      });
  }

  filtrarTenant(event:any){
    this.tenants = this.tenantsInicio.filter(x=> x.Name.toString().toUpperCase().includes(event.toString().toUpperCase()))
  }
}
