import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { FiltroFirma } from 'src/app/shared/models/filtros';
import { FiltroSqp } from '../../../sqpFiltroSuperior/models/filtro-sqp';
import { TipoFiltro } from '../../../sqpFiltroSuperior/models/tipo-filtro';

@Component({
  selector: 'app-calendario-rango',
  templateUrl: './calendario-rango.component.html',
  styleUrls: ['./calendario-rango.component.css']
})
export class CalendarioRangoComponent implements OnInit {

  isOpcionesPeriodo: boolean = false;
  isMostrarInputs: boolean = false;
  formRange: FormGroup = new FormGroup({});
  pastYear: string = '';
  currentYear: string = '';
  @Input() periodoTiempoInicial: string = '';
  periodoTiempo: string = '';
  private isInsideCalendar = false;
  disableRipple: boolean;
  @Input() limpiarTodo: {num: number, clear: boolean};
  @Output() filtroRangoSqpEmitter: EventEmitter<FiltroSqp>= new EventEmitter<FiltroSqp>();
  @Input() limpiarTodosFiltrosSqp:number;

  constructor(private eRef: ElementRef,
              private cdr: ChangeDetectorRef) {
    this.inicializaFormularioRange();
   }

  ngOnChanges(changes: SimpleChanges){
    if(changes.limpiarTodosFiltrosSqp?.currentValue>0) this.limpiarFiltro();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear().toString();
    this.pastYear = (new Date().getFullYear()-1).toString();
    this.periodoTiempo = this.periodoTiempoInicial;
  }

  abrirMenu(){
    this.isOpcionesPeriodo = !this.isOpcionesPeriodo;
  }

  inicializaFormularioRange(){
    this.formRange = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null)
    })
  }

  get start(){return this.formRange.controls['start']}
  get end(){return this.formRange.controls['end']}

  seleccionarFechas(fecha: string){
    if(fecha !== 'rango'){
      this.start.setValue(null);
      this.end.setValue(null);
    }
    let fechaDesde: Date | null;
    let fechaHasta: Date | null;
    let hoy = new Date();
    let year = hoy.getFullYear();
    switch(fecha){
      case 'hoy':
        fechaDesde = this.getMedianoche(hoy);
        fechaHasta = new Date();
        this.periodoTiempo = 'Hoy';
        this.isOpcionesPeriodo = false;
        this.isMostrarInputs = false;
        break;
      case '7':
        let fecha7 = new Date(hoy.getTime()-(7*24*60*60*1000));
        fechaDesde = this.getMedianoche(fecha7);
        fechaHasta = new Date();
        this.periodoTiempo = 'Últimos 7 días';
        this.isOpcionesPeriodo = false;
        this.isMostrarInputs = false;
        break;
      case '30':
        let fecha30 = new Date(hoy.getTime()-(30*24*60*60*1000));
        fechaDesde = this.getMedianoche(fecha30);
        fechaHasta = new Date();
        this.periodoTiempo = 'Últimos 30 días';
        this.isOpcionesPeriodo = false;
        this.isMostrarInputs = false;
        break;
      case 'current':
        fechaDesde = new Date(year, 0, 1);
        fechaHasta = new Date();
        this.periodoTiempo = 'Este año';
        this.isOpcionesPeriodo = false;
        this.isMostrarInputs = false;
        break;
      case 'past':
        let pastYear = year-1;
        fechaDesde = new Date(pastYear, 0 ,1);
        fechaHasta = new Date(pastYear, 11, 31);
        this.periodoTiempo = 'Año pasado';
        this.isOpcionesPeriodo = false;
        this.isMostrarInputs = false;
        break;
      case 'rango':
        fechaDesde = this.start.value ? (this.start.value as any).toDate() : null;
        fechaHasta = this.end.value ? (this.end.value as any).toDate() : null;
        let desde = fechaDesde?.toLocaleDateString().split('/');
        let hasta = fechaHasta?.toLocaleDateString().split('/');
        if(fechaDesde && fechaHasta){
          if(desde[2] != hasta[2]) this.periodoTiempo = `${desde[0]} ${this.traducirMes(desde[1])} ${desde[2]} - ${hasta[0]} ${this.traducirMes(hasta[1])} ${hasta[2]}`;
          else if(desde[1] == hasta[1]) this.periodoTiempo = `${desde[0]} - ${hasta[0]} ${this.traducirMes(desde[1])}`;
          else this.periodoTiempo = `${desde[0]} ${this.traducirMes(desde[1])} - ${hasta[0]} ${this.traducirMes(hasta[1])}`;
          this.isInsideCalendar = false;
          this.isOpcionesPeriodo = false;
          this.isMostrarInputs = false;
        }
        // this.programmaticClick();
        break;
    }

    if(fechaHasta) fechaHasta.setDate(fechaHasta.getDate()+1);

    let filtroSqp: FiltroSqp = {
      tipoFiltro: TipoFiltro.FECHA,
      titulo: this.periodoTiempoInicial,
      valoresSeleccionados:{
        valorFechaDesde:fechaDesde,
        valorFechaHasta:fechaHasta
      }
    }
    this.filtroRangoSqpEmitter.emit(filtroSqp)
  }

  @HostListener('document:click', ['$event']) clickout(event:any) {
    const clickedElement = event.target as HTMLElement;
    if((!this.eRef.nativeElement.contains(clickedElement) &&
       !this.isInsideCalendar) ) {
      this.isOpcionesPeriodo = false;
      if(this.periodoTiempo == 'Periodo personalizado' && (this.start.value == null || this.end.value == null)){
        this.periodoTiempo = this.periodoTiempoInicial;
      }
    }
  }

  mostrarInputCalendarios(){
    this.isMostrarInputs = !this.isMostrarInputs;
    this.periodoTiempo = 'Periodo personalizado';
    this.isInsideCalendar = true;
  }

  getMedianoche(fecha: Date): Date{
    return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 0, 0, 0);
  }

  limpiarFiltro(){
    this.start.setValue(null);
    this.end.setValue(null);

    let filtroSqp: FiltroSqp = {
      tipoFiltro: TipoFiltro.FECHA,
      titulo: this.periodoTiempoInicial,
      valoresSeleccionados:{
        valorFechaDesde:null,
        valorFechaHasta:null
      }
    }

    this.filtroRangoSqpEmitter.emit(filtroSqp)
    this.periodoTiempo = this.periodoTiempoInicial;
    this.isOpcionesPeriodo = false;
    this.isMostrarInputs = false;
    this.isInsideCalendar = false;
  }

  traducirMes(mes: string): string{
    switch(mes){
      case '1': return 'ene';
      case '2': return 'feb';
      case '3': return 'mar';
      case '4': return 'abr';
      case '5': return 'may';
      case '6': return 'jun';
      case '7': return 'jul';
      case '8': return 'ago';
      case '9': return 'sept';
      case '10': return 'oct';
      case '11': return 'nov';
      case '12': return 'dic';
    }
    return ''
  }

  // programmaticClick() {
  //   const divElement = this.eRef.nativeElement.querySelector('#clickId');
  //   divElement.click();
  // }

}
