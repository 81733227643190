import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { TipoFirmante } from '../../../envios/models/tipo-firmante';
import { ConfiguracionFirma, ErroresForm, FirmanteNif, TipoFirma } from '../../models/firmante-nif';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';
import { DatosDocumentosService } from '../../services/datos-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { FormularioEnvioService } from '../../../envios/services/formulario-envio.service';
import { TipoDocumento, TipoDocumentoClass } from '../../models/tipo-documento';
import { TipoMensajeConfirmacion } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';

@Component({
  selector: 'app-configuracion-documento-page',
  templateUrl: './configuracion-documento-page.component.html',
  styleUrls: ['./configuracion-documento-page.component.css']
})
export class ConfiguracionDocumentoPageComponent implements OnInit {

  index: number = 1;
  // idDocTemporal: string = '';
  idPdf: string ='';
  nombrePdf: string ='';
  objetoDocumento: any;
  urlDocumento: string = '';
  firmanteSeleccionado: TipoFirmante;
  erroresForm: ErroresForm[];
  tipoFirmaAnteriorConFirmanteArray: any[] = [];
  pdfCargado: boolean;
  idDocumentoEditar: number;
  cambioDoc: boolean = false;
  titulo: string = "Configuración de nuevo documento";
  docCargado:boolean = false;
  iconoDoc: string = '#hs-doc';
  documentoAEditar: TipoDocumento = new TipoDocumentoClass();
  semaforoGuardar: boolean = true;
  urlDocAntigua: string = '';
  semaforoCif: boolean = true;
  semaforoNif: boolean = true;

  constructor(public datosDocumentosService: DatosDocumentosService,
              public formularioDocumentoService: FormularioDocumentoService,
              public _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private formularioValidacionesDocsService: FormularioValidacionesDocsService,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              public router: Router,
              private formularioEnvioService: FormularioEnvioService) {
                router.events.subscribe(event => {
                  if (event instanceof NavigationEnd) {
                    if(event.id ==1) router.navigate([`/panel/nueva-configuracion-documento`])
                  }
                });
                this.getTipoFirmaAnterior();
                this.editarDocumento();
               }


  ngOnInit(): void {
    this.datosDocumentosService.configDoc = true;
    if(this.router.url.includes('editar')) this.titulo = "Editar configuración de documento";
    else this.titulo = "Configuración de nuevo documento";
  }

  ngOnDestroy(){
    this.datosDocumentosService.configDoc = false;
    this.formularioDocumentoService.resetFormularioDocumento();
  }

  finCarga(){
    this.docCargado=true;
  }
  //conseguimos el valor de tipoFirma antes de que cambie para poder compararlo con el de después.
  public getTipoFirmaAnterior(){
    this.datosConfiguracionDocumentosService.datosTipoFirma$.subscribe(value => {
      this.tipoFirmaAnteriorConFirmanteArray = value;
    })
  }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre'] }

  get tipoFirma(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('tipoFirma') }

  get tieneTag(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('tieneTag') }

  get formFirma(){ return this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup }

  get formularios(){ return (this.formularioDocumentoService.formularioDocumento.get('firmantesNif') as FormArray).at(this.obtenerIndexNifArray()).get('formularios') as FormArray}

  get camposEditables(){ return (this.formularioDocumentoService.formularioDocumento.get('firmantesNif') as FormArray).at(this.obtenerIndexNifArray()).get('camposEditables') as FormArray}

  get generico(){ return this.formularioDocumentoService.formularioDocumento.controls['generico']}

  get esCopiaBasica(){ return this.formularioDocumentoService.formularioDocumento.controls['esCopiaBasica']}

  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.get('firmantesNif') as FormArray}

  get esRLT(){ return this.formularioDocumentoService.formularioDocumento.controls['esRLT']}

  get formatoNombreArchivoFirmado(){ return this.formularioDocumentoService.formularioDocumento.controls['formatoNombreArchivoFirmado']}

  get formatoNombreArchivoAuditTrail(){ return this.formularioDocumentoService.formularioDocumento.controls['formatoNombreArchivoAuditTrail']}

  get split(){ return this.formularioDocumentoService.formularioDocumento.controls['split']}

  get copyConfiguracion(){ return this.formularioDocumentoService.formularioDocumento.controls['copyConfiguracion']}

  get idTipoDocOrigen(){ return this.formularioDocumentoService.formularioDocumento.controls['idTipoDocOrigen']}

  get textoFormulario(){return this.formularioDocumentoService.formularioDocumento.controls['textoFormulario']}

  get textoAExcluirFormulario(){return this.formularioDocumentoService.formularioDocumento.controls['textoAExcluirFormulario']}

  //si tipoFirma ha cambiado su valor a "Validador" o "Certificado" o "tieneTag a true", reseteamos todos los otros controles. Para vaciar el array de posicionFirmas lo hacemos eliminando objeto a objeto con un loop. (reset, clear o new FormArray no funciona). También enviamos el firmante seleccionado al componente upload a través de un observable en servicio para que borre las firmas en pantalla.
  resetIfTipoFirmaCambiado(){
    this.tipoFirmaAnteriorConFirmanteArray.forEach(x=>{
      if(x.tipoFirmante.codigo == this.firmanteSeleccionado.codigo && x.tipoFirma !== this.tipoFirma.value){
        if(this.tipoFirma.value == TipoFirma.Validador || this.tipoFirma.value == TipoFirma.SinFirma){ // || this.tipoFirma.value == TipoFirma.Certificado
          this.datosConfiguracionDocumentosService.transferirArrayFirmasVacio(this.firmanteSeleccionado);
          Object.keys(this.formFirma.controls).forEach(key => {
            if(key !== 'tipoFirma') {
              this.formFirma.controls[key].reset();
              // while ((this.formFirma.controls['posicionFirmas'] as FormArray).length !== 0) { //cuando no se ha clicado ninguna opción, entra en la función sin los controles de psoicionFirmas, solo con array vacio. Por eso chequeamos value para continuar
              while (this.formFirma.controls['posicionFirmas'].value && this.formFirma.controls['posicionFirmas'].value.length !== 0) {
                (this.formFirma?.controls['posicionFirmas'] as FormArray).removeAt(0)
              }
              this.formFirma.controls[key].updateValueAndValidity();
            }
          });
        }
        if(this.tipoFirma.value == TipoFirma.Validador){
          while(this.formularios.value && this.formularios.value.length>0){
            this.formularios.removeAt(0);
          }
          while(this.camposEditables.value && this.camposEditables.value.length>0){
            this.camposEditables.removeAt(0);
          }
          this.formularios.updateValueAndValidity();
          this.datosConfiguracionDocumentosService.transferirArrayFormulariosVacio(this.firmanteSeleccionado);
          this.camposEditables.updateValueAndValidity();
          this.datosConfiguracionDocumentosService.transferirArrayCamposEditablesVacio(this.firmanteSeleccionado);
        }
      }
      if(x.tipoFirmante.codigo == this.firmanteSeleccionado.codigo && this.tieneTag.value == true ){
        this.datosConfiguracionDocumentosService.transferirArrayFirmasVacio(this.firmanteSeleccionado);
          Object.keys(this.formFirma.controls).forEach(key => {
              while ((this.formFirma.controls['posicionFirmas'] as FormArray).length !== 0) {
                (this.formFirma.controls['posicionFirmas'] as FormArray).removeAt(0)
              }
          });
      }
    })
  }

  irDireccion(e:string){
    this.formularioDocumentoService.refrescaDocumento();
    if(!this.docCargado) return;
    if(e=='Siguiente') this.validaSiguiente(e);
    else if(e=='atras') this.validaAtras(e);
    else if(e=='Finalizar') this.validaFinalizar();
    this.guardar();
  }

  validaSiguiente(direccion: string){
    switch(this.index){
      case 2:
        this.valida_2();
        break
      case 4:
        this.valida_4();
        break
      case 5:
        this.valida_5(direccion);
        break
      case 6:
        this.valida_6();
        break
      case 7:
        this.valida_7();
        break
      case 8:
        this.valida_8(direccion);
        break
      case 9:
        this.valida_9(direccion);
        break
      case 10:
        this.valida_10(direccion);
        break
      default:
        this.avanzar();
        break
    }
  }

  validaAtras(direccion:string){
    switch(this.index){
      case 5:
        this.valida_5(direccion);
        break
      case 8:
        this.valida_8(direccion);
        break
      case 9:
        this.valida_9(direccion);
        break
      case 10:
        this.valida_10(direccion);
        break
      default:
        this.retroceder();
        break
    }
  }

  validaFinalizar(){
    if(this.index==9 && this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'].invalid) this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
    else{
      this.index = 12;
      this.formularioDocumentoService.indexPaso = this.index;
      this.formularioDocumentoService.updateObjetoPredicate();
    }
  }

  valida_2(){
    if(this.nombre.invalid) this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
    else {
      if(this.esRLT.value) this.irAIndex(5);
      else this.avanzar();
    }
  }

  valida_4(){
    if(this.generico.invalid) this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
    else this.avanzar();
  }

  valida_5(direccion:string){
    switch(direccion){
      case 'Siguiente':
        if(this.esRLT.value) this.irAIndex(9);
        else this.avanzar();
        break
      case 'atras':
        if(this.esRLT.value) this.irAIndex(2);
        else this.retroceder();
    }
  }

  valida_6(){
    if(this.formatoNombreArchivoFirmado.invalid ||
    this.formatoNombreArchivoAuditTrail.invalid ||
    this.split.invalid ||
    this.esCopiaBasica.invalid ||
    this.textoFormulario.invalid ||
    this.textoAExcluirFormulario.invalid) this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
    else{
      // if(this.generico.value || this.esCopiaBasica.value) this.irAIndex(8);
      if(this.generico.value) this.irAIndex(8);
      //comentado en 120324
      // if(this.generico.value==true) {
      //   console.log('form valida_6: ', this.formularioDocumentoService.formularioDocumento)
      //   if(this.firmantesNif.value.length>0 && this.firmantesNif.at(0).get('nifOrden').value==0) this.irAIndex(10); //this.index=9
      //   else this.irAIndex(8);
      // }else{
        else this.avanzar();
      // }
    }
  }

  valida_7(){
    if(!this.semaforoCif) this._snackBar.open(`Debe esperar a que se cargue el CIF para poder avanzar`, 'Cerrar', { duration: 3000 });
    else if(this.formularioDocumentoService.formularioDocumento.controls['posicionCif'].invalid ||
       this.formularioDocumentoService.formularioDocumento.controls['cifOrden'].invalid ||
       this.formularioDocumentoService.formularioDocumento.controls['cifPage'].invalid ||
       this.formularioDocumentoService.formularioDocumento.controls['cifFormulario'].invalid ||
       this.datosConfiguracionDocumentosService.nohayCif === true) this._snackBar.open(`Hay errores`, 'Cerrar', { duration: 3000 });
    else this.avanzar();
  }

  valida_8(direccion:string){
    switch(direccion){
      case 'Siguiente':
        if(this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'].value.length === 0) {
          this._snackBar.open('Debe seleccionar como mínimo un firmate', 'cerrar', {duration: 4000})
          return
        }else if(!this.semaforoNif){
          this._snackBar.open(`Debe esperar a que se carguen los NIF para poder avanzar`, 'Cerrar', { duration: 3000 });
          return
        }

        // if(this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'].value.length === 0) this.abrirModalConfirmacion();
        // if(this.firmantesNif.value.length === 0 || this.firmantesNif?.value[0].nifPage==null) this.abrirModalConfirmacion();
        // else
        if(this.validacionesGlobales_8()) {
          this._snackBar.open(`Hay errores en el orden, pagina o formulario`,'Cerrar', {duration: 3000});
          return;
        // }else if(!this.esCopiaBasica.value && !this.generico.value && this.datosConfiguracionDocumentosService.noExistenNifs?.includes(true)) this._snackBar.open(`Hay errores en el orden, pagina o formulario`,'Cerrar', {duration: 3000});
        }else if(!this.generico.value && this.datosConfiguracionDocumentosService.noExistenNifs?.includes(true)) this._snackBar.open(`Hay errores en el orden, pagina o formulario`,'Cerrar', {duration: 3000});
        // let errores8:boolean[] = [];
        // (this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray).controls?.forEach(x=>{
        //   let firmanteNif = x as FormGroup;
        //   if(firmanteNif.controls['nifPage'].invalid ||
        //     firmanteNif.controls['nifOrden'].invalid ||
        //     firmanteNif.controls['nifFormulario'].invalid ){
        //       errores8.push(true)
        //     }
        // })
        // if(this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'].value.length === 0) this.abrirModalConfirmacion();
        // else if(this.datosConfiguracionDocumentosService.noExistenNifs?.includes(true) ||
        //         errores8.includes(true)) this._snackBar.open(`Hay errores en el orden, pagina o formulario`,'Cerrar', {duration: 3000});
        else this.avanzar();
        break
      case 'atras':
        // if(this.generico.value || this.esCopiaBasica.value) this.irAIndex(6);
        if(this.generico.value) this.irAIndex(6);
        else
        this.retroceder();
        break
    }
  }

  valida_9(direccion: string){
    if(this.firmanteSeleccionado){
      this.resetIfTipoFirmaCambiado();
      this.formularioDocumentoService.addFirmaEnFirmanteNif(this.firmanteSeleccionado);
      this.firmanteSeleccionado = {} as TipoFirmante; // reseteamos firmanteSeleccionado para que no se muestre ninguna firma en pantalla al ir al siguiente paso
      this.formularioDocumentoService.formularioDocumento.updateValueAndValidity();
      this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'].updateValueAndValidity();
    }
    switch(direccion){
      case 'Siguiente':
        if(this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'].invalid) this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
        else this.avanzar();
        break
      case 'atras':
        if(this.esRLT.value) this.irAIndex(5);
        else if(this.firmantesNif.at(0).get('nifPage').value==0) this.irAIndex(6);
        else this.retroceder();
        break
    }
  }

  valida_10(direccion: string){
    if(this.firmanteSeleccionado){
      this.firmanteSeleccionado = {} as TipoFirmante;
    }
    if(direccion=='Siguiente') this.avanzar();
    if(direccion=='atras'){
      if(this.firmantesNif.at(0).get('nifPage').value==0) this.irAIndex(6);
      else this.retroceder();
    }
  }

  retroceder(){
    this.index--;
    this.formularioDocumentoService.indexPaso = this.index;
    this.formularioDocumentoService.updateObjetoPredicate();
    if(this.index == 2){
      this.copyConfiguracion.setValue(false);
      this.idTipoDocOrigen.setValue(null);
    }
    if(this.index == 3 && this.copyConfiguracion.value == false){
      this.generico.reset();
      }
  }

  avanzar(){
    this.index++;
    this.formularioDocumentoService.indexPaso = this.index;
    this.formularioDocumentoService.updateObjetoPredicate();
  }

  irAIndex(indice:number){
    this.index = indice;
    this.formularioDocumentoService.indexPaso = this.index;
    this.formularioDocumentoService.updateObjetoPredicate();
  }

  uploadPdf(e: any){
   this.index == 1;
   this.putDocumentoTemporal(e);
  }

  putDocumentoTemporal(data: FileList | any){
    this.datosDocumentosService.putDocumentoTemporal(data).subscribe(
      response => {
        this.idPdf = response;
        this.getDocumentoTemporal(this.idPdf);
        this.nombrePdf = data[0]?.name;
      },
      error => {
        console.error(error);
      }
    )
  }

  getDocumentoTemporal(idPdf:string){
    this.datosDocumentosService.getDocumentoTemporal(idPdf).subscribe(
      response => {
        this.urlDocumento = response;
        this.nombrePdf = this.urlDocumento.substring((this.urlDocumento.lastIndexOf('/')+1),this.urlDocumento.indexOf('?'));
        this.index ++;
      },
      error => {
        console.error(error);
      }
    );
  }


  guardar(){
    if(!this.semaforoGuardar) return
    this.formularioDocumentoService.formularioDocumento.markAllAsTouched();
    this.formularioDocumentoService.formularioFirma.markAllAsTouched();
    this.refreshForm();

    if(this.formularioDocumentoService.formularioDocumento.invalid){
      this.erroresForm = this.formularioValidacionesDocsService.getFormValidationErrors();
      return
    }
    // if(this.urlDocumento !== ''){
    //   this.formularioDocumentoService.formularioDocumento.controls['urlDocTemp'].setValue(this.urlDocumento)
    // }

    this.objetoDocumento = this.formularioDocumentoService.montarObjetoDocumentoGuardar();
    if(this.index == 12  && this.semaforoGuardar) {
      this.semaforoGuardar = false;
      if(this.objetoDocumento.generico) this.objetoDocumento.nombreDocumentoGenerico = this.nombrePdf;
      this.objetoDocumento.fusionable = this.fusionable;
      this.objetoDocumento.ordenFusion = this.ordenFusion;
      this.datosDocumentosService.guardaDocumento(this.objetoDocumento).subscribe(
        next => {
          const isEmpty = Object.keys(this.formularioEnvioService.formEnvioTemporal.value).length;
          if(isEmpty > 0){
            let existingDocsArray = this.formularioEnvioService.formularioEnvio.controls['tiposDocumento'].value
            let indexDoc = existingDocsArray.findIndex((x: TipoDocumento) => x.idTipoDocumento==this.objetoDocumento.idTipoDocumento);
            if(indexDoc > -1){
              existingDocsArray[indexDoc] = this.objetoDocumento;
            }else{
              existingDocsArray.push(this.objetoDocumento)
            }
            this.formularioEnvioService.addDocumentosEnForm(existingDocsArray);
            this.formularioEnvioService.formEnvioTemporal = this.formularioEnvioService.formularioEnvio;
          }
          this.formularioDocumentoService.resetFormularioDocumento() //reseteamos formulario y dibujos en pantalla

          this.confirmacionGuardadoOk();

        },
        error => {
          console.error(error);
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: {
              mensaje: 'Ha habido un error en guardar la configuración de documento.',
              alert: true
            },
          });
        },
        ()=>{
          // this.datosDocumentosService.refreshDocs = true;
          // history.back();
          this.semaforoGuardar = true;
        }
      );
    }
  }

  confirmacionGuardadoOk(){
    const dialogRef = this.dialog.open(ModalMessageComponent, {
      data: {
        mensaje: 'Configuración de documento guardada correctamente',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.datosDocumentosService.refreshDocs = true;
      history.back();
      // this.router.navigate(['/panel/documentos-configurados']);
    });
  }

  //forzamos un refresh de las validaciones para recuperar las validaciones reales
  refreshForm(){

    for (const field in this.formularioDocumentoService.formularioDocumento.controls) { // 'field' is a string
      const control = this.formularioDocumentoService.formularioDocumento.get(field); // 'control' is a FormControl

      control.updateValueAndValidity();

    }
  }

  //revisar situacion varios firmantes, modificando CIF/NIF
  // validacionesGlobales_7():boolean{
  //   let error = false;
  //   const orden = this.formularioDocumentoService.formularioDocumento.controls['cifOrden'].value;
  //   const page = this.formularioDocumentoService.formularioDocumento.controls['cifPage'].value;
  //   const formulario = this.formularioDocumentoService.formularioDocumento.controls['cifFormulario'].value;
  //   const contieneCif = this.formularioDocumentoService.formularioDocumento.controls['contieneCif'].value;
  //   if(!contieneCif){
  //     this.formularioDocumentoService.formularioDocumento.controls['cifOrden'].setErrors(null);
  //     this.formularioDocumentoService.formularioDocumento.controls['cifPage'].setErrors(null);
  //   }else{
  //     if(!formulario && contieneCif==true){
  //       if(!orden || orden==0) {
  //         this.formularioDocumentoService.formularioDocumento.controls['cifOrden'].setErrors({validadoresCifRequired:true});
  //         error =true
  //       }
  //       if(!page ||page==0) {
  //         this.formularioDocumentoService.formularioDocumento.controls['cifPage'].setErrors({validadoresCifRequired:true});
  //         error=true;
  //       }
  //       if(error) this._snackBar.open('Debe seleccionar el cif por página/orden o por formulario', 'cerrar', {duration: 4000})
  //     }
  //   }
  //   return error;
  // }

  abrirModalConfirmacion(){
    if(this.formularioDocumentoService.RequiereNif()){
      // this._snackBar.open('Debe seleccionar como mínimo un firmate', 'cerrar', {duration: 4000})
      this._snackBar.open('Debe seleccionar como mínimo un firmate con Página/Orden o Formulario', 'cerrar', {duration: 4000})
    }else{
      let text;
      if(this.generico.value) text = 'un documento genérico y';
      if(this.esCopiaBasica.value) text = 'una copia básica y';
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          // icono: 'icon-tick',
          // titulo: '¿Estás seguro que quieres continuar sin haber indicado ningún firmante?',
          soloMensaje: `Al ser ${text} no haber indicado ningún firmante, la configuración de documento finalizará. ¿Estás seguro que quieres finalizar?`,
          // soloMensaje: '¿Estás seguro que quieres continuar sin haber indicado ningún firmante?',
          textoConfirmacion: 'finalizar',
          iconoConfirmacion: 'icon-tick',
          textoCancelar: 'cancelar'
        },
      });

      dialogRef?.afterClosed().subscribe(result => {
        if(result === 'OK'){
          this.addFirmanteNifVacio();
          // this.index=9;
          // this.irAIndex(10);
          this.irAIndex(12);
          this.guardar();
        }
      })
    }
  }
  addFirmanteNifVacio(){
    let valoresFirmanteNif: FirmanteNif;
    valoresFirmanteNif = {
      tipoFirmante: {
        codigo: '100',
        desc: 'Firmante principal'
      },
      nifPage: 0,
      nifOrden: 0,
      firmas: {} as ConfiguracionFirma,
      formularios: [],
      camposEditables: [],
      nifFormulario:'',
      posicionNif: null
    }
    this.formularioDocumentoService.addFirmanteNifInFormArray(valoresFirmanteNif)
  }
  //recogemos el firmante seleccionado desde configuracion-documento-configurar-firma para después pasárselo al hijo configuracion-documento-upload y muestre solo las firmas del firmante seleccionado
  getFirmanteSeleccionado(event: TipoFirmante){
    this.firmanteSeleccionado = event;
  }

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmanteSeleccionado.codigo)
  }

  getIndex(event: any){
    this.index = event;
  }

  fusionable: boolean = false;
  ordenFusion: number = 0;
  editarDocumento(){
    this.datosConfiguracionDocumentosService.datosDocId$.subscribe((value:any) => {
    const isEmpty = Object.keys(value).length;
      if(isEmpty > 0){
        if(value.idPdf == null){
          this.index = 1;
          this._snackBar.open('El documento asociado está subido en una versión anterior. Vuelva a subir el documento por favor.', 'cerrar', {duration: 4000})
        }else{
          this.getDocumentoTemporal(value.idPdf);
          this.idPdf = value.idPdf;
          this.pdfCargado = true;
        }
        this.idDocumentoEditar = value.idTipoDocumento;
        this.fusionable = value.fusionable == true;
        this.ordenFusion = isNaN(value.ordenFusion) ? 0 : value.ordenFusion;
      }
    })
  }

  //esta función se utiliza cuando cargamos el doc antiguo otra vez, ya que al querer cambiar de documento, el nuevo tenía menos páginas que el anterior y se borrarían todos los dibujos.
  abrirDocAntiguo(event:TipoDocumento){
    this.getDocumentoTemporal(event.idPdf);
    this.idPdf = event.idPdf;
    this.pdfCargado = true;
    this.idDocumentoEditar = event.idTipoDocumento;
    this.fusionable = event.fusionable == true;
    this.ordenFusion = isNaN(event.ordenFusion) ? 0 : event.ordenFusion;
  }

  cambiarDoc(event:any){
    this.urlDocAntigua = this.urlDocumento;
    this.urlDocumento ='';
    this.index = 1;
    this.pdfCargado = false;
    this.cambioDoc = true;
    this.titulo = "Editar configuración de documento"
  }

  back(){
    if(!this.formularioDocumentoService.hayCambios()){
      this.formularioDocumentoService.resetFormularioDocumento();
      history.back();
      this.formularioDocumentoService.totalPages = null;
    }else{
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-save2',
          titulo: 'Deseas salir sin guardar?',
          mensaje:'Se perderán todos los cambios realizados en la configuración del documento',
          // preguntaConfirmacion: '¿Está seguro que desea salir de la configuración de documento sin guardar?',
          textoConfirmacion: 'Salir sin guardar',
          iconoConfirmacion: 'icon-unsaved',
          textoCancelar: 'cerrar'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK'){
          this.formularioDocumentoService.resetFormularioDocumento();
          history.back();
          this.formularioDocumentoService.totalPages = null;
        }
      });
    }
    this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
  }

  validacionesGlobales_8():boolean{
    let errorArray = [];
    let permiteSinNif = (this.generico.value || this.esCopiaBasica.value)
    this.formularioDocumentoService.firmantesNif.controls.map((x: any, indexX:number)=>{
      let error = false;
      let nifPage = x.controls['nifPage'];
      let nifOrden = x.controls['nifOrden'];
      let nifFormulario = x.controls['nifFormulario'];
      let posicionNif = x.controls['posicionNif'];
      nifPage.setErrors(null);
      nifOrden.setErrors(null);
      nifFormulario.setErrors(null);
      if(posicionNif.value=='pagina'){
        if(nifPage.value==null){
          nifPage.setErrors({validadoresNifRequired: true})
        }
        if(nifOrden.value==null){
          nifOrden.setErrors({validadoresNifRequired: true})
          error=true
        // }else if((nifPage.value<1 || nifPage.value > this.formularioDocumentoService.totalPages) && !permiteSinNif ){
        }else if(nifPage.value<1 || nifPage.value > this.formularioDocumentoService.totalPages ){
          nifPage.setErrors({validadoresNifTotalPages: true})
          error=true
        }else if(nifOrden.value<1 && !permiteSinNif){
        // }else if(nifOrden.value<1){
          nifOrden.setErrors({validadoresNifMin: true})
          error=true
        }
      }else if(posicionNif.value=='formulario' && nifFormulario.value==null){
        nifFormulario.setErrors({validadoresNifFormulario: true})
        error=true
      }
      // if(!this.generico.value && !this.esCopiaBasica.value){
      if(!this.generico.value){
        this.formularioDocumentoService.firmantesNif.controls.forEach((y: any, indexY:number)=>{
          let nifPageY = y.controls['nifPage'];
          let nifOrdenY = y.controls['nifOrden'];
          let nifFormularioY = y.controls['nifFormulario'];
          if(indexX!==indexY &&
            nifPage.value==nifPageY.value &&
            nifOrden.value==nifOrdenY.value &&
            nifOrden.value!==null &&
            nifPage.value!==null){
            nifPage.setErrors({validadoresNifduplicado: true})
            nifOrden.setErrors({validadoresNifduplicado: true})
            error=true
          }else if(indexX!==indexY &&
                   nifFormulario.value==nifFormularioY.value &&
                   nifFormulario.value!==null){
                   nifFormulario.setErrors({validadoresFormularioNifduplicado: true})
           }
        })
      }
      errorArray.push(error)
    })
    this.formularioDocumentoService.formularioDocumento.controls['firmantesNif']=this.formularioDocumentoService.firmantesNif
    return errorArray.includes(true)
  }


  spinnerCifIsCargando(event: boolean){
    this.semaforoCif = !event;
  }

  spinnerNifIsCargando(event: boolean){
    this.semaforoNif = !event;
  }
}
