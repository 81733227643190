import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import moment from 'moment';

@Component({
  selector: 'app-selector-fecha',
  templateUrl: './selector-fecha.component.html',
  styleUrls: ['./selector-fecha.component.css']
})
export class SelectorFechaComponent implements OnInit {

  formulario: FormGroup = new FormGroup({});
  @Input() campoFecha: string;
  @Output() fechaEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() errorClass: boolean = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    let fechaModificada = this.campoFecha ? new Date(this.campoFecha): null;

    this.formulario = this.fb.group({
      fecha: [fechaModificada]
    })
  }

  get fecha(){ return this.formulario.controls['fecha']}

  dateSelected(event: any){
    //montamos la fecha direcatamente desde el objeto Moment
    let date = new Date(this.fecha.value._i.year, this.fecha.value._i.month, this.fecha.value._i.date);
    //establecemos la fecha a medianoche, 00:00:00 para no tener problemas de zonas horarias
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
    //enviamos la fecha en formato ISOString, YYYY-MM-DDT00:00:00.000Z
    this.fechaEmitter.emit(date.toISOString());
  }

}
