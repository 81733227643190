<div class="flex">
  <app-input-buscador (inputBuscadorEmitter)="getSelectedItems($event)"
                      [textoBuscar]="buscarStringInputBuscador()"
                      [limpiarTodosFiltrosSqp]="limpiarTodosFiltrosSqp"
                      ></app-input-buscador>
  <div *ngFor="let filtro of filtrosSqp">
    <app-indicador-pastilla-filtro *ngIf="filtro.tipoFiltro==0 && filtro.valoresSeleccionados.valorBool"
                                   [tituloFiltro]="filtro.titulo"
                                   [num]="datosFirmaService.firmasFiltradas.length"
                                   (limpiarPastillas)="getSelectedItems($event)"
                                   [visualizacionDestacada]="filtro.visualizacionDestacada"
                                   [limpiarTodosFiltrosSqp]="limpiarTodosFiltrosSqp"
                                   ></app-indicador-pastilla-filtro>
  </div>
  <app-drop-down-multiseleccion *ngFor="let lista of listasDropDown"
                                [listaDesplegable]="lista.listaDesplegable"
                                [tituloDropDown]="lista.titulo"
                                (seleccionItemsOut)="getSelectedItems($event)"
                                [limpiarTodosFiltrosSqp]="limpiarTodosFiltrosSqp"
                                ></app-drop-down-multiseleccion>
  <app-calendario-rango *ngFor="let titulo of titulosFecha"
                        (filtroRangoSqpEmitter)="getSelectedItems($event)"
                        [periodoTiempoInicial]="titulo"
                        [limpiarTodosFiltrosSqp]="limpiarTodosFiltrosSqp"
                        ></app-calendario-rango>
  <span *ngIf="isFiltrosLimpios()>0" (click)="limpiarFiltros()" class="eliminar">
    Limpiar filtros
  </span>
</div>
