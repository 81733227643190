<app-centros-upload-excel-page *ngIf="mostrarSubidaExcel" (refreshEmitter)="refresh()"></app-centros-upload-excel-page>
<div *ngIf="!mostrarSubidaExcel">
  <div class="barra-flex-space-between">
    <h1>Configuración de centros</h1>
    <div class="acciones">
      <app-centros-barra-superior id="barra-superior" (botonClicked)="clickBarraSuperior($event)"></app-centros-barra-superior>
      <app-boton-accion [id]="'nuevo centro'" [icono]="'icon-plus'" [titulo]="'nuevo centro'" [outline]="false" (click)="creaNuevoCentro()"></app-boton-accion>
    </div>
  </div>
  <div class="main">
    <div *ngIf="centrosSeleccionados.length==0" class="flex-center-items">
      <app-input-buscador (buscador)="buscarCentros($event)"></app-input-buscador>
    </div>
    <app-pastilla-seleccion-multiple *ngIf="centrosSeleccionados.length>0"
                                      (borrarEmitter)="eliminarCentro()"
                                      (deseleccionarEmitter)="deseleccionarAll()"
                                      [textoMostrar]="textoBorrar"
                                      [tipoDato]="'centros'"></app-pastilla-seleccion-multiple>
    <app-sqp-grid-component [data]="centros"
                            (refresh)="getCentros()"
                            [columnDefs]="columnDefs"
                            [seleccion]="'multiple'"
                            (seleccionEliminarEmitter)="seleccionCentros($event)"
                            [deseleccionar]="deseleccionar"
                            (abrirModalDataEmitter)="abreModal($event)"
                            [iconoEditar]="true"></app-sqp-grid-component>
  </div>
</div>
