import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroEmpleado } from 'src/app/shared/models/filtros';
import { DatosEmpleadosService } from '../../../services/mantenimiento/datos-empleados.service';
import { DesplegableData, SeleccionItems } from 'src/app/shared/components/sqp-components/sqpDesplegable/models/desplegable-data';

@Component({
  selector: 'app-empleados-filtros',
  templateUrl: './empleados-filtros.component.html',
  styleUrls: ['./empleados-filtros.component.css']
})
export class EmpleadosFiltrosComponent implements OnInit {

  @Input() empresasList: DesplegableData[] = [];
  @Input() centrosList: DesplegableData[] = [];
  @Input() textoBuscar: string;
  limpiarTodo: {num: number, clear: boolean} = { num: 0, clear:false};
  @Output() limpiarTodoEmitter: EventEmitter<{num: number, clear: boolean}> = new EventEmitter<{num: number, clear: boolean}>();
  @Output() limpiarBuscador: EventEmitter<string> = new EventEmitter<string>();
  @Output() filtrarEmpleados: EventEmitter<boolean> = new EventEmitter<boolean>();
  filtrosEmpleados: FiltroEmpleado[] = [];

  constructor(private datosEmpleadosService: DatosEmpleadosService) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.textoBuscar && (changes.textoBuscar.currentValue || changes.textoBuscar.currentValue == '')){
      let filtro;
      filtro = {
        text: 'Buscar',
        buscar: changes.textoBuscar.currentValue
      }
      let index = this.filtrosEmpleados.findIndex(x=> x.text == filtro.text)
      if(index == -1) this.filtrosEmpleados.push(filtro);
      else this.filtrosEmpleados[index] = filtro;
    }
    this.datosEmpleadosService.filtrarNuevo(this.filtrosEmpleados);
    this.filtrarEmpleados.emit(true);
    // this.filtrosNuevosEmitter.emit(this.filtrosEmpleados);
  }
  ngOnInit(): void {

  }

  anadirFiltros(seleccion: SeleccionItems){
    let valoresSeleccionados: string[] = [];
    seleccion.items.forEach(x=> valoresSeleccionados.push(x.id))
    let filtro: FiltroEmpleado;
    filtro = {
      text: seleccion.tipo,
      listaValores: valoresSeleccionados
    }
    let index = this.filtrosEmpleados.findIndex(x=> x.text == seleccion.tipo);

    //actualizamos el tipo de filtro con nuevos valores
    if(index == -1) this.filtrosEmpleados.push(filtro);
    else this.filtrosEmpleados[index] = filtro;

    //eliminamos el filtro que le hemos quitado todas las opciones seleccionadas
    let indexEliminar = this.filtrosEmpleados.findIndex(x=> x.listaValores?.length==0)
    if(indexEliminar>-1) this.filtrosEmpleados.splice(indexEliminar,1);
    this.datosEmpleadosService.filtrarNuevo(this.filtrosEmpleados);
    this.filtrarEmpleados.emit(true);
    // this.filtrosNuevosEmitter.emit(this.filtrosEmpleados);
  }

  limpiarFiltros(){
    this.filtrosEmpleados = [];
    this.empresasList = this.datosEmpleadosService.getEmpresaLista();
    this.centrosList = this.datosEmpleadosService.getCentrosLista();
    this.datosEmpleadosService.filtrarNuevo(this.filtrosEmpleados)
    this.filtrarEmpleados.emit(true);
    this.limpiarTodo = {
      num: this.limpiarTodo.num+1,
      clear: true
    }
    this.limpiarBuscador.emit('');
    this.limpiarTodoEmitter.emit({num: this.limpiarTodo.num+1, clear: true});
  }

  isFiltrosLimpios(): number {
    let contador:number = 0;
    this.filtrosEmpleados.forEach(x=>{
      if(x.text=='Buscar' && x?.buscar!=='') contador++;
      if(x.listaValores?.length>0) contador++;
    })
    return contador;
  }
}
