<div class="barra-iconos-acciones">

    <app-icono-superior matTooltip="{{getTooltip('Descargar')}}" [matTooltipPosition]="'above'" [icono]="'hs-download'" [id]="'Descargar'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>

    <app-icono-superior matTooltip="{{getTooltip('Excel')}}" [matTooltipPosition]="'above'" [icono]="'icon-excel-empty'" [id]="'Excel'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>

    <app-icono-superior matTooltip="{{getTooltip('Pdf')}}"  [matTooltipPosition]="'above'" [icono]="'hs-doc'" [id]="'Pdf'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>

    <app-icono-superior matTooltip="{{getTooltip('Eliminar')}}" [matTooltipPosition]="'above'" [icono]="'hs-bin'" [id]="'Eliminar'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>

</div>

