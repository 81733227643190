<div class="content">
  <div class="titulo">
    <h1>{{titulo}}</h1>
    <div (click)="back()">
      <svg class="icon-close">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
  </div>
  <div class="main">
    <div class="flex-doc">
      <app-configuracion-documento-upload id="uploadDoc" (fileListEmitter)="uploadPdf($event)"  [firmanteSeleccionado]="firmanteSeleccionado" [src]="urlDocumento" [pdfCargado]="pdfCargado" [idDocumentoEditar]="idDocumentoEditar" [idPdf]="idPdf" (ficheroCargado)="finCarga()" (oldDocEmitter)="abrirDocAntiguo($event)"></app-configuracion-documento-upload>

      <div class="flex-lateral no-seleccionable">
        <div class="">
          <app-titulo-paso-conf-doc *ngIf="index>2" [icono]="iconoDoc" [titulo]="nombre.value"></app-titulo-paso-conf-doc>
          <app-configuracion-documento-progreso [indexStep]="index"></app-configuracion-documento-progreso>
          <app-configuracion-documento-ayuda-subida *ngIf="index==1"></app-configuracion-documento-ayuda-subida>
          <app-configuracion-documento-nombre *ngIf="index==2" id="cambiarDocId" (cambiarDoc)="cambiarDoc($event)"></app-configuracion-documento-nombre>
          <app-configuracion-documento-copiar-configuracion *ngIf="index==3"></app-configuracion-documento-copiar-configuracion>
          <app-configuracion-documento-firmante *ngIf="index==4"></app-configuracion-documento-firmante>
          <app-configuracion-documento-incluir-contenido *ngIf="index==5"></app-configuracion-documento-incluir-contenido>
          <app-configuracion-documento-texto-excluir *ngIf="index==6"></app-configuracion-documento-texto-excluir>
          <app-configuracion-documento-configurar-cif *ngIf="index==7" [idPdf]="idPdf" (cargaCifEmitter)="spinnerCifIsCargando($event)"></app-configuracion-documento-configurar-cif>
          <app-configuracion-documento-configurar-nif *ngIf="index==8" [idPdf]="idPdf" (cargaNifEmitter)="spinnerNifIsCargando($event)"></app-configuracion-documento-configurar-nif>
          <app-configuracion-documento-configurar-firma *ngIf="index==9" (firmanteSeleccionado)="getFirmanteSeleccionado($event)" [erroresForm]="erroresForm"></app-configuracion-documento-configurar-firma>
          <app-configuracion-documento-formularios *ngIf="index==10" (firmanteSeleccionado)="getFirmanteSeleccionado($event)"  [erroresForm]="erroresForm"></app-configuracion-documento-formularios>
          <app-campos-editables-page *ngIf="index==11" (firmanteSeleccionado)="getFirmanteSeleccionado($event)"></app-campos-editables-page>
        </div>
        <div class="">
          <app-configuracion-documento-botonera [index]="index" (botonClicked)="irDireccion($event)"></app-configuracion-documento-botonera>
        </div>
      </div>
    </div>
  </div>
</div>

