export function hideWidget(){
  FreshworksWidget('hide');
  // FreshworksWidget('hide', 'launcher');
}

export function openWidget(){
  FreshworksWidget('open');
}

export function showWidget(){
  FreshworksWidget('show');
  // FreshworksWidget('show', 'launcher');
}
