import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotonResumenFirmasComponent } from './components/boton-resumen-firmas/boton-resumen-firmas.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component'
import { SharedModule } from 'src/app/shared/shared.module';
import { PanelControlModule } from '../panelControl/panel-control.module';
import { BotonUltimasFirmasComponent } from './components/boton-ultimas-firmas/boton-ultimas-firmas.component';
import { SqpComponentsModule } from "../../shared/components/sqp-components/sqp-components.module";

@NgModule({
  declarations: [
    BotonResumenFirmasComponent,
    MainMenuComponent,
    BotonUltimasFirmasComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PanelControlModule,
    SqpComponentsModule
]
})
export class MenuPrincipalModule { }
