import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { map, tap } from 'rxjs/operators';
import { LoginToken } from '../models/login-token';
import { LoginUser } from '../models/login-user';
import { LoginData } from '../models/login-data';
import { RefreshTokenData } from '../models/refresh-token-data';
import { LoginRequestData } from '../models/login-request-data';
import { UserValid4Sandbox } from '../models/user-valid4sandbox';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { DatosFirmaService } from '../../panelControl/services/datos-firma.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariActivo, UsuarioModel } from '../../seguridad/models/usuario-model';
import { TenantData, UsuarioActivo } from 'src/app/shared/components/sqp-components/sqpLogin/models/TenantData';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // usuarioActivo: UsuarioModel = {} as UsuarioModel;
  get usuarioActivo(): UsuarioActivo{
    let dataString = localStorage.getItem('UsuarioActivo')
    return JSON.parse(dataString);
  }
  set usuarioActivo(user: UsuarioActivo){
    let dataString = JSON.stringify(user);
    localStorage.setItem('UsuarioActivo',dataString);
  }

  public get loginData():LoginData{

    return this.getUserLocalStorage();
  }

  constructor(private httpClient: HttpClient,
              private endPointServices:EndpointService,
            ) { }
            // private activatedRoute: ActivatedRoute
  // Llamamos a la API para iniciar sesión
  login(loginData: LoginRequestData){
    this.borraLocalStorage();
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json'
      })
    };
    const url_login = this.endPointServices.UrlLogin;


    return this.httpClient.post(url_login, loginData, httpOptions).pipe(
      map((response:any)=>{

        this.cargarDatosLogin(response);
      })
    );
  }
logout(){
  let httpOptions: any ={
    headers: new HttpHeaders({
      'Content-Type':'application/json',
      'authorization': 'Bearer '+this.getUserLocalStorage().Token.Token
    })
  };



  return this.httpClient.get(this.endPointServices.UrlLogOut, httpOptions);
}

  // Llamamos a la API para recargar el RefreshToken y los datos de la sesión
  refreshToken(){
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json'
      })
    };
    let refreshTokenData = this.getRefreshTokenData();
    return this.httpClient.post(this.endPointServices.UrlRefreshToken, refreshTokenData, httpOptions).pipe(map((response:any)=>{
      this.cargarDatosLogin(response);
    }));
  }

  // Obtenemos la fecha de ultima conexion, añadimos el tiempo de expiracion (minutos) y calculamos si la sesion ha caducado comparandolo con la fecha UTC actual
  tokenIsExpired(): boolean{

    if(!this.loginData.Token.Token) return true;
    if(!this.loginData.Token.ExpireData || this.loginData.Token.ExpireData==0) return true;
    if(this.loginData.FechaCreacion)
    {
      let fechaCaducidad =new Date(this.loginData.FechaCreacion);

      let fechaActual = new Date();
      fechaCaducidad.setHours(fechaCaducidad.getHours() + this.loginData.Token.ExpireData);



      return fechaActual >= fechaCaducidad;
    }
    else return false;
  }

  refreshIsExpired():boolean{
    if(!this.loginData.Token.ExpireRefresh || this.loginData.Token.ExpireRefresh==0) return true;
    if(this.loginData.FechaCreacion)
    {
      let fechaCaducidad =new Date(this.loginData.FechaCreacion);
      let fechaActual = new Date();
      fechaCaducidad.setHours(fechaCaducidad.getHours() + this.loginData.Token.ExpireRefresh);

      fechaActual = new Date();

      return fechaActual >= fechaCaducidad;
    }
    else return false;
  }

  cargarDatosLogin(data: LoginData){
    let loginUserData: LoginUser = data.User;
    let loginTokenData: LoginToken = data.Token;

    let _loginData: LoginData ={
      Token: loginTokenData,
      User: loginUserData,
      FechaUltimaConexion: data.FechaUltimaConexion,
      FechaCreacion:new Date(),
      BaseUrl:data.BaseUrl,
      Endpoints:data.Endpoints
    }

    //this.loginData = _loginData;
    localStorage.setItem('SQPFIRMA_login', JSON.stringify(_loginData));
    if(_loginData.BaseUrl){
      this.endPointServices.ActBaseUrl(_loginData.BaseUrl);
    }
  }
 private borraLocalStorage(){
  localStorage.setItem('SQPFIRMA_login', '');
 }
  getUserLocalStorage(): LoginData{
    let loginData: LoginData;
    let loginDataString = localStorage.getItem('SQPFIRMA_login');
    if(loginDataString) {
       loginData = JSON.parse(loginDataString);

    }
    else
    {
      loginData = {
        Token: {
          Token: '',
          RefreshToken: '',
          ExpireData: 0,
          ExpireRefresh:0
        },
        User: {
          Login: '',
          Apellido1: '',
          Nombre: '',
          Rol: '',
          Tenant:'',
          Multitenant:false
        },
        FechaUltimaConexion: undefined,
        FechaCreacion:undefined,
        BaseUrl:undefined,
        Endpoints:undefined
      }
    }
    return loginData;
  }

  getRefreshTokenData(): RefreshTokenData{
    let refreshTokenData: RefreshTokenData = {
      Token: this.loginData.Token.Token,
      RefreshToken: this.loginData.Token.RefreshToken
    }
    return refreshTokenData;
  }

  isValidUserEnvioTest(){
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.getUserLocalStorage().Token.Token
      })
    };

    return this.httpClient.get(this.endPointServices.UrlUserValidEnvioTest, httpOptions).pipe(
      map( (response: any) =>{
        if(response.Ok) return UserValid4Sandbox.VALID_USER;
        else if(response.Data == "Mail") return UserValid4Sandbox.MAIL_TEST_MISSING;
        else {
          return UserValid4Sandbox.INVALID_USER;
        }
      })
    );
  }

  GetUrlLogin(){
    return this.httpClient.get(this.endPointServices.UrlGetLoginAzureAdUrl).pipe(
      map((data:any)=>{

      if (data.url) return data.url;
      if (data.Url) return data.Url;
      return '';

    })
    );
  }

  userToken: string;

  LoginAzureAd(tenant:TenantData,token:string,tentants:TenantData[]){
    // this.userToken = token;
    let data = {
      Tenant: tenant.Tenant,
      Token: token
    }
    this.usuarioActivo={
      tenantToken:token,
      tenants:tentants,
      tenantActivo:tenant

    }

    return this.httpClient.post(this.endPointServices.UrlLoginAzureAd,data).pipe(
      map((data:any)=>{

        this.cargarDatosLogin(data);
      })
    );
  }

  DecodeTokenLogin(token: string): any {
    return jwt_decode(token);
  }

  ProcesaLogin(data:any){

  }

  tenantData:any;

  setTenantToken(tenantActivo:TenantData, token:string){

    let tokenINfo = this.DecodeTokenLogin(token);

    let tenants = JSON.parse(tokenINfo.Tenant);

    this.usuarioActivo ={
      tenantActivo: tenantActivo,
      tenantToken: token,
      tenants: tenants
    }

    localStorage.setItem('SQPFIRMA_tenant_token', JSON.stringify(this.usuarioActivo));
  }

  getTenantToken(){
    let tenantData: UsuarioActivo;
    let tenantDataString = localStorage.getItem('SQPFIRMA_tenant_token');
    if(tenantDataString) {
       tenantData= JSON.parse(tenantDataString);

    }else{
      tenantData= {
        tenantActivo:{
          Name:'',
          Tenant:''
        },
        tenantToken:'',
        tenants:[]
      }
    }
    return tenantData;
  }

  loginA3Api(){
    return this.httpClient.get(this.endPointServices.UrlLoginA3Api).pipe(
      map((data:any)=>{
        if(typeof(data) == 'string'){
          window.location.href = data;
        }
      return '';
    })
    );
  }

  updateUserActivoLocalStorage(userActivo: UsuariActivo){
    let _loginData = this.getUserLocalStorage();
    _loginData.User.Apellido1 = userActivo.Apellido1
    _loginData.User.Nombre = userActivo.Nombre;
    localStorage.setItem('SQPFIRMA_login', JSON.stringify(_loginData));
  }

}
