<div id="container" [ngStyle]="{'background-color':periodoTiempo!==periodoTiempoInicial?'var(--hs-yellow1)':''}">
  <div class="pastilla" (click)="abrirMenu()">
    <div [ngClass]="{'tituloLista': true, 'tituloListaSeleccion': periodoTiempo!==periodoTiempoInicial}">{{periodoTiempo}}</div>
    <span class="icon-arrow">
      <svg><use xlink:href="#icon-arrow"></use></svg>
    </span>
  </div>
  <span [ngStyle]="{'display':periodoTiempo!==periodoTiempoInicial?'block':'none'}"  class="hs-close" (click)="limpiarFiltro()">
    <svg ><use xlink:href="#icon-close"></use></svg>
  </span>
  <div *ngIf="isOpcionesPeriodo" [ngClass]="{'dropDown': true, 'dropDown-wide': isMostrarInputs}">
    <ul>
      <li [ngClass]="{'seleccionado': periodoTiempo=='Hoy'}" (click)="seleccionarFechas('hoy')">Hoy</li>
      <li [ngClass]="{'seleccionado': periodoTiempo=='Últimos 7 días'}" (click)="seleccionarFechas('7')">Últimos 7 días</li>
      <li [ngClass]="{'seleccionado': periodoTiempo=='Últimos 30 días'}" (click)="seleccionarFechas('30')">Últimos 30 días</li>
      <li [ngClass]="{'seleccionado': periodoTiempo=='Este año'}" (click)="seleccionarFechas('current')">Este año ({{currentYear}})</li>
      <li [ngClass]="{'seleccionado': periodoTiempo=='Año pasado'}" (click)="seleccionarFechas('past')">Año pasado ({{pastYear}})</li>
      <li [ngClass]="{'seleccionado': (periodoTiempo=='Periodo personalizado' || (start.value!==null && end.value!==null))}" class="flex-li" (click)="mostrarInputCalendarios()">Periodo personalizado
        <span class="icon-arrow right-arrow">
          <svg><use xlink:href="#icon-arrow"></use></svg>
        </span>
      </li>
    </ul>
    <div *ngIf="isMostrarInputs" class="input-calendars" [formGroup]="formRange">
      <div id="labelFechaStart">Desde</div>
      <mat-form-field [floatLabel]="'never'">

          <!-- <mat-label>Desde</mat-label> -->
          <input style="width: 75%;" type="text" matInput [matDatepicker]="desde" formControlName="start" (dateChange)="seleccionarFechas('rango')" readonly>
          <mat-datepicker-toggle matIconSuffix [for]="desde" class="selectorFechaRango" [disableRipple]="true">
            <mat-icon matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #desde></mat-datepicker>


      </mat-form-field>
      <div id="labelFechaEnd">Hasta</div>
      <mat-form-field [floatLabel]="'never'">

          <!-- <mat-label>Hasta</mat-label> -->
          <input style="width: 75%;" type="text" matInput [matDatepicker]="hasta" formControlName="end" (dateChange)="seleccionarFechas('rango')" readonly>
          <mat-datepicker-toggle matIconSuffix [for]="hasta" class="selectorFechaRango" [disableRipple]="true">
            <mat-icon matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #hasta></mat-datepicker>


      </mat-form-field>
    </div>
  </div>
</div>

