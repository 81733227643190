<div class="modal-msg">
  <svg class="icon-close-msg" mat-dialog-close>
    <use xlink:href="#icon-close"></use>
  </svg>
  <div class="alert">
    <svg *ngIf="data.alert" class="icon-alert">
      <use xlink:href="#hs-alert"></use>
    </svg>
    <svg *ngIf="!data.alert" class="icon-ok">
      <use xlink:href="#hs-tick-circle"></use>
    </svg>
    <h3>{{data.mensaje}}</h3>
  </div>
</div>
