import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormularioDocumentoService } from 'src/app/pages/configuracion/Envios/documentos/services/formulario-documento.service';
import { FormularioEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/formulario-envio.service';
import { DatosCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/datos-centros.service';
import { DatosDocumentosService } from 'src/app/pages/configuracion/Envios/documentos/services/datos-documentos.service';
import { DatosEnviosService } from 'src/app/pages/configuracion/Envios/envios/services/datos-envios.service';
import { MatDialog } from '@angular/material/dialog';
import { TipoDocumentoClass } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { ConfirmationMessageComponent } from 'src/app/shared/components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { TipoEnvioClass } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { TipoMensajeConfirmacion } from '../components/sqp-components/sqpMensajeConfirmacion/models/confirmation-message-data';
import { IBarraSuperiorService } from '../components/sqp-components/sqpBarraSuperior/services/ports/iBarraSuperiorService';
import { Subscription } from 'rxjs';
import { UsuariActivo } from 'src/app/pages/seguridad/models/usuario-model';
import { DatosUsuariosService } from 'src/app/pages/seguridad/services/datos-usuarios.service';
import { VentanasAbiertas } from 'src/app/pages/menuPrincipal/model/ventanas-abiertas';


@Injectable({
  providedIn: 'root'
})

export class BarraSuperiorService implements IBarraSuperiorService{

  tipoConfiguracion:string;
  configuracionAbierta:boolean;
  gestorVentanas:VentanasAbiertas;

  usuarioActivoSuscription: Subscription;
  usuarioActivo: UsuariActivo;

  constructor(public loginService: LoginService,
              private router: Router,
              private formularioDocumentoService: FormularioDocumentoService,
              private dialog: MatDialog,
              private formularioEnvioService: FormularioEnvioService,
              private datosCentrosService: DatosCentrosService,
              private datosDocumentosService: DatosDocumentosService,
              private datosEnviosService: DatosEnviosService,
              private datosUsuariosService: DatosUsuariosService,
              private _snackBar: MatSnackBar) {
                this.getUsuarioActivoEditado();
                this.gestorVentanas = new VentanasAbiertas(router, formularioDocumentoService, dialog, formularioEnvioService);
               }

  clickConfigUsuario() {
    this.avisaCerrarSinGuardar(this.gestorVentanas.ventanaAbierta(), 'configuracion');
  }

  clickCambioTenant() {
    this.avisaCerrarSinGuardar(this.gestorVentanas.ventanaAbierta(), 'entorno');
  }

  clickLogOut() {
    this.avisaCerrarSinGuardar(this.gestorVentanas.ventanaAbierta(), 'logout');
  }

  avisaCerrarSinGuardar(ventana:string, destino:string){
    if(ventana!=='') {
      this.gestorVentanas.accionVentanaAbierta(ventana, destino).subscribe(result => {
        if(result === 'OK'){
          if(ventana=='configuracion-documento') this.reseteaFormularioDocumento();
          if(ventana=='configuracion-dosier') this.reseteaFormularioDosier();
          this.irADestino(destino);
        }
      });
    }else this.irADestino(destino);

  }

  reseteaFormularioDocumento(){
    this.formularioDocumentoService.resetFormularioDocumento();
    this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
  }

  reseteaFormularioDosier(){
    this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
  }

  irADestino(destino: string){
    switch(destino){
      case 'configuracion':
        this.router.navigate(['/panel/configuracion-usuario']);
      break;
      case 'entorno':
        this.datosCentrosService.refreshCentros = true;
        this.datosDocumentosService.refreshDocs = true;
        this.datosEnviosService.refresh = true;
        this.router.navigate([`selectTenant/${this.loginService.getTenantToken().tenantToken}`])
      break;
      case 'logout':
        if(this.gestorVentanas.logoutDirecto){
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              soloMensaje:'¿Realmente deseas salir de Hire & Sign?',
              textoConfirmacion: 'Salir',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.logout();
            }
          });
        }else this.logout();

      break;
      case 'main':
        this.router.navigate(['/panel/main']);
      break;
    }
  }

  logout(){
    this.loginService.logout().subscribe(
      ok => {
        localStorage.clear();
        this.router.navigate(['/login']);
      },
      error => {
        this._snackBar.open(error.error.Message, "Cerrar", { duration: 3000 });
        this.router.navigate(['/login']);
      });
  }

  getTenant() {
    return this.loginService.getTenantToken().tenantActivo.Name;
  }

  destroyComponente() {
    this.datosUsuariosService.transferirUsuarioActivo({});
  }

  getNameLogin() {
    const isEmpty = Object.keys(this.usuarioActivo).length;
    if(isEmpty>0){
      return `${this.usuarioActivo.Apellido1}, ${this.usuarioActivo.Nombre}`
    }else{
      let userData = this.loginService.getUserLocalStorage();
      return (userData.User.Nombre && userData.User.Apellido1) ? `${userData.User.Apellido1}, ${userData.User.Nombre}` : userData.User.Login;
    }
  }

  //Observable para cambiar el nombre del usuario activo si uno mismo se lo edita en configuración de usuario
  getUsuarioActivoEditado() {
    this.usuarioActivoSuscription = this.datosUsuariosService.datosUsuarioActivo$.subscribe((value:any) => {
      this.usuarioActivo = value;
      this.getNameLogin();
    })
  }

  goHome() {
    this.avisaCerrarSinGuardar(this.gestorVentanas.ventanaAbierta(), 'main')
  }
}
