// Components
import { MenuLateralComponent } from './components/layouts/menu-lateral/menu-lateral.component';
import { StandarLayoutComponent } from './components/layouts/standar-layout/standar-layout.component';
import { IconoSuperiorComponent } from './components/selectores/icono-superior/icono-superior.component';
import { ConfirmationMessageComponent } from './components/sqp-components/sqpMensajeConfirmacion/components/confirmation-message/confirmation-message.component';
import { BotonAccionComponent } from './components/botones/boton-accion/boton-accion.component';
// import { SelectorComponent } from './components/sqp-components/sqpSelectores/components/selector/selector.component';
import { BreadCrumbsComponent } from './components/selectores/bread-crumbs/bread-crumbs.component';
import { BotonAccionSecundariaComponent } from './components/botones/boton-accion-secundaria/boton-accion-secundaria.component';
import { AlertaAccionIrreversibleComponent } from './components/modals/alerta-accion-irreversible/alerta-accion-irreversible.component';
import { ModalMessageComponent } from './components/modals/modal-message/modal-message.component';
// import { InputBuscadorComponent } from './components/sqp-components/sqpInputBuscador/components/input-buscador/input-buscador.component';
import { ProgressComponent } from './components/others/progress/progress.component';
import { UploadFileComponent } from './components/others/upload-file/upload-file.component';
import { UploadFileDirective } from './directives/upload-file.directive';
import { UploadFileProcessingComponent } from './components/others/upload-file-processing/upload-file-processing.component';
import { BarraFooterComponent } from './components/layouts/barra-footer/barra-footer.component';
import { SpinnerModalComponent } from './components/others/spinner-modal/spinner-modal.component';

// Pipes
import { EstadosFirmaIconoPipe } from './pipes/estados-firma-icono.pipe';
// import { IconoRefPipe } from './pipes/icono-ref.pipe';

// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../../app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { environment } from 'src/environments/environment';

//Services
import { InyeccionDependenciasFactory } from './services/inyeccion-dependencias-factory';
import { UploadFileDatosDocumentoComponent } from './components/others/upload-file-datos-documento/upload-file-datos-documento.component';
// import { IndicadorNumeroComponent } from './components/sqp-components/sqpIndicadores/components/indicador-numero/indicador-numero.component';
import { BotonAccesoDirectoComponent } from './components/botones/boton-acceso-directo/boton-acceso-directo.component';
import { BotonAccesoComponent } from './components/botones/boton-acceso/boton-acceso.component';
// import { DropDownMultiseleccionComponent } from './components/sqp-components/sqpDesplegable/components/drop-down-multiseleccion/drop-down-multiseleccion.component';
// import { IndicadorPastillaFiltroComponent } from './components/sqp-components/sqpPastillaFiltro/components/indicador-pastilla-filtro/indicador-pastilla-filtro.component';
// import { CalendarioRangoComponent } from './components/sqp-components/sqpFiltroCalendario/components/calendario-rango/calendario-rango.component';
import { PastillaSeleccionMultipleComponent } from './components/selectores/pastilla-seleccion-multiple/pastilla-seleccion-multiple.component';
import { SelectorFechaComponent } from './components/selectores/selector-fecha/selector-fecha.component';
import { SqpComponentsModule } from "./components/sqp-components/sqp-components.module";
import { CustomTooltipComponent } from './components/others/custom-tooltip/custom-tooltip.component';

const MaterialComponents = [
  MatDialogModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatExpansionModule,
  MatStepperModule,
  MatIconModule,
  MatRadioModule,
  MatTooltipModule
];

@NgModule({
  declarations: [
    MenuLateralComponent,
    StandarLayoutComponent,
    IconoSuperiorComponent,
    ConfirmationMessageComponent,
    BotonAccionComponent,
    BotonAccionSecundariaComponent,
    // SelectorComponent,
    BreadCrumbsComponent,
    EstadosFirmaIconoPipe,
    // IconoRefPipe,
    AlertaAccionIrreversibleComponent,
    ProgressComponent,
    ModalMessageComponent,
    // InputBuscadorComponent,
    BarraFooterComponent,
    UploadFileComponent,
    UploadFileDirective,
    UploadFileProcessingComponent,
    SpinnerModalComponent,
    UploadFileDatosDocumentoComponent,
    // IndicadorNumeroComponent,
    BotonAccesoDirectoComponent,
    BotonAccesoComponent,
    // DropDownMultiseleccionComponent,
    // IndicadorPastillaFiltroComponent,
    // CalendarioRangoComponent,
    PastillaSeleccionMultipleComponent,
    SelectorFechaComponent,
    CustomTooltipComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    PdfViewerModule,
    MaterialComponents,
    SqpComponentsModule
],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialComponents,
    IconoSuperiorComponent,
    // SelectorComponent,
    BotonAccionComponent,
    BotonAccionSecundariaComponent,
    ConfirmationMessageComponent,
    EstadosFirmaIconoPipe,
    // IconoRefPipe,
    AlertaAccionIrreversibleComponent,
    ProgressComponent,
    ModalMessageComponent,
    // InputBuscadorComponent,
    BarraFooterComponent,
    UploadFileComponent,
    UploadFileDirective,
    UploadFileProcessingComponent,
    PdfViewerModule,
    SpinnerModalComponent,
    UploadFileDatosDocumentoComponent,
    // IndicadorNumeroComponent,
    BotonAccesoDirectoComponent,
    BotonAccesoComponent,
    // DropDownMultiseleccionComponent,
    // IndicadorPastillaFiltroComponent,
    // CalendarioRangoComponent,
    PastillaSeleccionMultipleComponent,
    SelectorFechaComponent,
    CustomTooltipComponent
  ],
  providers: [
  //   // SI PONEMOS EL FORMATO dd/MM/yyyy el input manual de las fechas devuelve formato incorrecto
  //   {
  //     provide: MAT_DATE_LOCALE,
  //     useValue: 'es-ES'
  //   }
  //
    // {
    //   provide: MAT_RADIO_DEFAULT_OPTIONS,
    //   useValue: { color: 'primary' },
    // },
    {
      provide:'RecuperaTipoEnvio',
      useClass:InyeccionDependenciasFactory.GetRecuperaTipoEnvio
    },
    {
      provide: 'RecuperaTipoDocumento',
      useClass: InyeccionDependenciasFactory.GetRecuperaTipoDocumento
    },
    {
      provide: 'RecuperaCentros',
      useClass: InyeccionDependenciasFactory.GetRecuperaCentros
    },
    {
      provide: 'RecuperaEmpresas',
      useClass: InyeccionDependenciasFactory.GetRecuperaEmpresas
    },
    {
      provide: 'GestionaEmpleados',
      useClass: InyeccionDependenciasFactory.GestionaEmpleados
    },
    {
      provide: 'GestionaFirmas',
      useClass: InyeccionDependenciasFactory.GetGestionaFirmas
    },
    {
      provide: 'GestionaDatosSubida',
      useClass: InyeccionDependenciasFactory.GetGestionaDatosSubida
    },
    {
      provide: 'DocumentoPdfConfiguracion',
      useClass: InyeccionDependenciasFactory.GetDocumentoPdfConfiguracion
    },
    {
      provide: 'RecuperaTiposContenido',
      useClass: InyeccionDependenciasFactory.GetRecuperaTiposContenido
    },
    {
      provide: 'RecuperaUsuarios',
      useClass: InyeccionDependenciasFactory.GetRecuperaUsuarios
    },
    {
      provide:'ListaEndpints',
      useClass: InyeccionDependenciasFactory.GetListaEndpints
    },
    {
      provide:'RecuperaConfiguracionAplicacion',
      useClass: InyeccionDependenciasFactory.GetRecuperaConfiguracionAplicacion
    },
    {
      provide:'RecuperaEmpleados',
      useClass: InyeccionDependenciasFactory.GetRecuperaEmpleados
    },
    {
      provide:'RecuperaBarraSuperior',
      useClass: InyeccionDependenciasFactory.GetRecuperaBarraSuperior
    },
    {
      provide:'RecuperaMenuLateral',
      useClass: InyeccionDependenciasFactory.GetRecuperaMenuLateral
    }
  ]
})
export class SharedModule {
}
