<div class="container">
  <h1>Editar perfil</h1>
  <!-- <svg class="icon-login">
    <use xlink:href="#hs-user"></use>
  </svg> -->
  <form [formGroup]="datosUsuariosService.usuarioActivoForm" id="formUsuarioActivo">
    <div>
      <label for="nombre">Nombre</label>
      <br>
      <input type="text" name="nombre" id="nombre" formControlName="nombre">
    </div>
    <div>
      <label for="apellidos">Apellidos</label>
      <br>
      <input type="text" name="apellido1" id="apellido1" formControlName="apellido1">
    </div>
    <div>
      <label for="email">E-mail</label>
      <br>
      <input type="email" name="email" id="email" formControlName="email" readonly>
    </div>
    <div style="margin-left: -10px;">
      <app-boton-accion [id]="'guardar cambios usuario activo'" [icono]="'hs-guardar'" [titulo]="'guardar'" [outline]="false" (click)="guardar()"></app-boton-accion>
    </div>
  </form>


</div>
