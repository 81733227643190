<div id="container" [ngStyle]="{'background-color':itemsEnviar.items?.length>0?'var(--hs-yellow1)':''}">
  <div class="pastilla" (click)="abrirMenu()">
    <div [ngClass]="{'tituloLista': true, 'tituloListaSeleccion': itemsEnviar.items?.length>0}" matTooltip="{{getTooltip(tituloDropDown)}}" [matTooltipPosition]="'above'">{{itemsEnviar.items?.length==1?itemsEnviar.items[0].desc:tituloDropDown}}</div>
    <div *ngIf="itemsEnviar.items?.length>1" class="circulo-xs">{{itemsEnviar.items.length.toString()}}</div>
    <span class="icon-arrow">
      <svg><use xlink:href="#icon-arrow"></use></svg>
    </span>
  </div>
  <span [ngStyle]="{'display':itemsEnviar.items?.length>0?'block':'none'}"  class="hs-close" (click)="limpiarFiltro()">
    <svg ><use xlink:href="#icon-close"></use></svg>
  </span>
  <div *ngIf="mostrarLista" class="dropDown" >
    <div class="buscador">
      <app-input-buscador (buscador)="buscarItems($event)" [inputWidth]="'180'"></app-input-buscador>
    </div>
    <div class="solo-seleccion">
        <app-indicador-pastilla-filtro  *ngFor="let item of itemsEnviar.items; let i = index"
                                        [tituloFiltro]="item.desc"
                                        [color]="'var(--hs-black)'"
                                        [bgColor]="'var(--hs-yellow1)'"
                                        [colorHover]="'var(--hs-grey1)'"
                                        [paddingPastilla]="0"
                                        (limpiarPastillas)="deseleccionarItems(item.id)"
                                        ></app-indicador-pastilla-filtro>
    </div>
    <form [formGroup]="checkboxForm">
      <div formArrayName="items" class="innerDropDown" [ngStyle]="{'border-top':itemsEnviar.items?.length>0?'1px solid var(--hs-grey4)':''}">
        <div *ngFor="let item of checkboxFormItems.controls; let i = index">
          <label>
            <input type="checkbox" [formControlName]="i" (change)="onCheckboxChange(i)"/>
              <div class="inputDescripcion">
                {{ listaDesplegable[i].desc }}
              </div>
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
